import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: "PreSB";
  src: url("/assets/pretendard/PretendardSemiBold.woff") format("woff"),
}

@font-face {
  font-family: "PreR";
  src: url("/assets/pretendard/PretendardRegular.woff") format("woff"),
}


:root {
  /* font-style */
  --f-subB: "PreSB";
  --f-title: "PreSB";
  --f-tableB: "PreSB";
  --f-header: "PreSB";
  --f-captionB: "PreSB";

  --f-text: "PreR";
  --f-sub: "PreR";
  --f-table: "PreR";
  --f-caption: "PreR";

  /* font-size */

  --s-main: 18px;
  --s-text: 16px;
  --s-title: 16px;
  --s-header: 22px;

  --s-sub: 14px;
  --s-table: 13px;
  --s-caption: 12px;
  --s-TextBox: 16px;

  /* font-color / back-color */

  --c-toastBg: #333;
  --c-black-op: rgba(0, 0, 0, 0.5);

  --c-mint-50: #ebf8f9;
  --c-mint-600: #00a4ae;

  --c-tableHead: #e0e0e0;
  --c-tableDeps: #f4f4f4;

  --c-gray-50 : #f8f8f8;
  --c-gray-100 : #f5f5f5;
  --c-gray-200 : #e8e8e8;
  --c-gray-300 : #d6d6d6;
  --c-gray-500 : #969696;
  --c-gray-600 : #787878;
  --c-gray-700 : #5c5c5c;
  --c-gray-900 : #212121;


  --c-white: #fff;
  --c-black: black;
  --c-red: #ff3d3d;
  --c-blue : #2e81ff;
  --c-brown : #dd7700;
  --c-input: #eee;

  --c-redOp :#FFF0F0;
  --c-brownOp : #FFF6EB;
  --c-blueOp : #EFF5FF;

  --shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  --shadow2: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  --shadow3: rgb(0, 0, 0, 0.1) 0px -10px 10px 3px inset, rgba(255, 255, 255, 0.1) 0px -3px 20px 10px inset;
  /* font-height */

  --l-sub: 22px;
  --l-text: 24px;
  --l-table: 20px;
  --l-title: 24px;
  --l-header: 33px;
  --l-TextBox: 24px;
  --l-caption: 18px;

}

.label-text tspan {
  font-size: 12px;
  font-family: var(--f-title) !important;
}

.notfound_txt {
  text-align: center;
  padding: 30px 0;
  color: var(--c-gray-400);
}

.scroll-lock {
  height: 100%;
  overflow-y: hidden !important;
  touch-action: none !important;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: none;
}

html {
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  -ms-content-zooming: none;
  touch-action: pan-x pan-y;
  -ms-touch-action: pan-x pan-y;
}

.imgclearly {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  -ms-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  object-fit: cover;
}

html {
  overscroll-behavior: contain;
}

::-webkit-scrollbar {
  /* display: none; */
}

::-webkit-scrollbar-thumb {
  /* scroll-behavior: none; */
  background-color: var(--c-white);
  border-radius: 100px;
}
body {
  -ms-overflow-style: none;
  /* IE and Edge */
  /* scrollbar-width: 3px; */
  /* Firefox */
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  background-color: #24262b;

}

input[type="password"]::-webkit-password-view-button,
input[type="password"]::-ms-reveal {
  background-color: transparent;
  background-size: cover;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

* {
  font-family: "Pretendard", sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: var(--c-gray-900);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

li {
  list-style: none;
}

.blind {
  opacity: 0;
  height: 0;
  visibility: hidden;
}

.hidden_content {
  opacity: 0;
  height: 0;
  visibility: hidden;
}


html,
body,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
time,
figure,
article,
nav,
header,
footer,
hgroup,
video,
audio,
aside,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: inherit;
  font-style: inherit;
  vertical-align: baseline;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

ol,
ul {
  list-style: none;
}

figure,
article,
header,
footer,
nav,
hgroup,
video,
audio,
aside,
main {
  display: block;
}

input,
textarea {
  -webkit-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

html {
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}

body,
textarea:focus,
input:focus,
a:focus {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

body {
  -webkit-touch-callout: none;
}

::-moz-selection {
  background: #023586;
  background: rgba(2, 53, 134, 1);
  color: var(--c-white);
}

::selection {
  background: #023586;
  background: rgba(2, 53, 134, 1);
  color: var(--c-white);
}

label {
  cursor: pointer;
}

input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="url"],
input[type="number"],
input[type="search"],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

input[type="radio"],
input[type="checkbox"] {
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

button,
input[type="file"],
input[type="image"],
input[type="reset"],
input[type="button"],
input[type="submit"] {
  border: none;
  background: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

::-webkit-input-placeholder {
  color: #999;
}

::-moz-placeholder {
  color: #999;
}

:-ms-input-placeholder {
  color: #999;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

select {
  border: 1px solid #111;
  -webkit-appearance: none;
  /* FF */
  -moz-appearance: none;
  /* safari chrome */
  appearance: none;
}

select::-ms-expand {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 60px var(--c-white) inset;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #111;
}

.clearfix::before,
.clearfix::after {
  content: "";
  display: block;
  clear: both;
}


`;

export default GlobalStyle;
