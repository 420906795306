import styled, { css } from "styled-components";

const createFlexStyles = ({
  $gap,
  $jus,
  $mar,
  $pad,
  $flex,
  $borB,
  $bTop,
  $over,
  $overX,
  $overY,
  $white,
  $align,
  $direct,
  $width,
  $shadow,
  $cursor,
  $height,
  $radius,
  $tAlign,
  $position,
  $flexWrap,
  $minWidth,
  $maxWidth,
  $maxHeight,
  $minHeight,
  $backColor,
}) => css`
  gap: ${$gap};
  flex: ${$flex};
  align-items: ${$align};
  flex-wrap: ${$flexWrap};
  justify-content: ${$jus};
  flex-direction: ${$direct};

  width: ${$width};
  min-width: ${$minWidth};
  max-width: ${$maxWidth};

  height: ${$height};
  min-height: ${$minHeight};
  max-height: ${$maxHeight};

  margin: ${$mar};
  padding: ${$pad};

  border-top: ${$bTop};
  border-bottom: ${$borB};
  border-radius: ${$radius};
  box-shadow: ${$shadow};

  text-align: ${$tAlign};
  background-color: ${$backColor};
  position: ${$position};

  overflow: ${$over};
  overflow-x: ${$overX};
  overflow-y: ${$overY};

  cursor: ${$cursor};
  white-space: ${$white};

  display: flex;
`;

const createLineSection = ({ $pad, $borT, $borL, $borR, $borB, $width, $height }) => css`
  padding: ${$pad};
  border-top: ${$borT};
  border-left: ${$borL};
  border-right: ${$borR};
  border-bottom: ${$borB};

  width: ${$width ? $width : "fit-content"};
  height: ${$height};
`;

const createModalBox = ({ $width }) => css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--c-white);

  width: ${$width ? $width : "280px"};
  padding: 16px 20px;
  border-radius: 4px;
  border: 1px solid var(--c-white);
`;

const createPadBox = ({ $pad, $width }) => css`
  padding: ${$pad};
  width: ${$width};
`;

const createShadowBox = ({ $pad, $position, $width, $height, $tAlign, $maxWidth, $minHeight, $maxHeight }) => css`
  height: ${$height ? $height : "100%"};
  min-height: ${$minHeight};
  max-height: ${$maxHeight};

  width: ${$width ? $width : "100%"};
  max-width: ${$maxWidth};

  padding: ${$pad};
  box-shadow: var(--shadow);
  text-align: ${$tAlign};
  border-radius: 4px;
  position: ${$position};
`;

const createInfoListBox = ({ $gap, $direct, $flexWrap }) => css`
  display: flex;
  flex-direction: ${$direct ? $direct : "column"};
  flex-wrap: ${$flexWrap};

  gap: ${$gap ? $gap : "12px"};
`;

const createIcon = ({ $url, $bor, $width, $height, $trans, $radius, $minWidth }) => css`
  display: block;
  border: ${$bor};
  width: ${$width};
  min-width: ${$minWidth};
  height: ${$height};
  background: ${`url(${$url}) no-repeat center`};
  border-radius: ${$radius};
  cursor: pointer;
  &.active {
    background: ${$url};
    transform: ${$trans};
  }
`;

const createPositionBox = ({ $position, $top, $bottom, $trans, $left, $right, $zIndex, $width, $pad, $backColor }) => css`
  position: ${$position};
  top: ${$top};
  bottom: ${$bottom};
  left: ${$left};
  right: ${$right};
  transform: ${$trans};
  z-index: ${$zIndex};
  width: ${$width};
  padding: ${$pad};
  background-color: ${$backColor ? $backColor : ""};
`;

const createDropMenuContainer = ({ $top, $bottom }) => css`
  position: absolute;
  top: ${$top ? $top : "100%"};
  bottom: ${$bottom};

  left: -1px;
  text-align: left;
  z-index: 99;
  width: 101%;
  margin-top: 6px;
  border-radius: 4px;
  box-shadow: var(--shadow);
  background-color: var(--c-white);

  padding: 4px;
`;

const createDropDown = ({ $borB, $width, $border, $height }) => css`
  cursor: pointer;

  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px 12px;
  width: ${$width ? $width : "230px"};
  height: ${$height ? $height : "40px"};

  border-radius: 4px;
  border-bottom: ${$borB};
  border: ${$border ? $border : "2px solid var(--c-gray-300)"};
  font-size: var(--s-caption);
  font-family: var(--f-caption);
`;

const DropDown = styled.button`
  ${createDropDown}
`;

const DropMenuContainer = styled.div`
  ${createDropMenuContainer}
`;

const IconLink = styled.span`
  ${createIcon}
`;

const FlexBox = styled.div`
  ${createFlexStyles}
`;

const LineSection = styled.div`
  ${createLineSection}
`;

const PadBox = styled.div`
  ${createPadBox}
`;

const ModalBox = styled.div`
  ${createModalBox}
`;

const ShadowBox = styled.div`
  ${createShadowBox}
`;

const InfoListBox = styled.ul`
  ${createInfoListBox}
`;

const PositionBox = styled.div`
  ${createPositionBox}
`;

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`

const TooltipText = styled.div`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  color: black;
  text-align: left;
  width : 300px;
  padding: 5px;
  font-size : 12px;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 100%;
  margin-left: -60px;  /* 가운데 정렬 */
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.3s;
  box-shadow: var(--shadow);
  background-color: var(--c-white);
  border: 1px solid var(--c-gray-300);

`;

const BorderBox = styled.div`
  border: 1px solid var(--c-gray-500);
`;

const UserManager = styled.div`
  position: relative;
  height: 100%;
`;

const ContentArea = styled.article`
  position: relative;

  width: 100%;
  padding: 16px 32px;

  background-color: var(--c-white);
  border-left: ${(props) => props.border};
`;

const NoticeLayOut = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  gap: 12px;
  margin: 12px 0;
  overflow-y: scroll;
  overscroll-behavior: contain;

  width: 100%;
  height: 100%;
  max-height: 600px;

  border-radius: 8px;
  box-shadow: var(--shadow);
`;

const InfoList = styled.li`
  min-width: 180px;
`;

const InfoContainer = styled.div`
  padding-bottom: 20px;
  width: 100%;
`;

const ScrollContent = styled.div`
  margin: 0;
  height: 100%;
  width: 100%;
  line-height: 1.2;
  overflow-y: auto;
  white-space: pre-wrap;
  overscroll-behavior: contain;
`;

const Dimmed = styled.div`
  background-color: var(--c-black-op);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
`;

const CategoryMenuBox = styled.div`
  width: fit-content;
`;

const DropMenuBox = styled.ul`
  overflow-y: auto;
  overscroll-behavior: contain;
  height: auto;
  max-height: 300px;
  padding: 4px;

  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--c-black-op);
    border-radius: 99px;
  }
`;

const DropMenuList = styled.li`
  padding: 12px;
  background-color: var(--c-white);

  font-size: var(--s-caption);
  font-family: var(--f-caption);
  line-height: var(--l-sub);

  cursor: pointer;

  border-radius: 4px;

  &:hover {
    background-color: var(--c-input);
  }
`;

const PeriodBox = styled.div`
  height: 34px;
  min-width: 120px;
  padding: 0 12px;
  position: relative;
  background-color: var(--c-white);
  border: 1px solid var(--c-gray-300);
  z-index: 999;
`;

const PeriodSelect = styled.ul`
  top: 100%;
  left: 0;
  width: 100%;
  display: flex;
  margin-top: 10px;
  text-align: left;
  position: absolute;
  align-items: center;
  flex-direction: column;
  box-shadow: var(--shadow);
  background-color: var(--c-white);
  border: 1px solid var(--c-gray-300);
`;

const PeriodList = styled.li`
  width: 100%;
  height: 34px;
  display: flex;
  cursor: pointer;
  text-align: left;
  padding: 0px 12px;
  align-items: center;
  white-space: nowrap;
  justify-content: flex-start;

  &.active {
    color: var(--c-mint-600);
  }

  &:hover {
    background-color: var(--c-mint-50);
  }
`;

const InfoTabBox = styled.ul`
  position: absolute;
  display: flex;
  z-index: 999;
  top: 100%;
  left: 50%;
  text-align: left;
  align-items: center;
  flex-direction: column;
  box-shadow: var(--shadow);
  background-color: var(--c-white);
  border: 1px solid var(--c-gray-300);
`;

const InfoTabList = styled.li`
  width: 100%;
  height: 34px;
  display: flex;
  cursor: pointer;
  text-align: left;
  padding: 0px 12px;
  align-items: center;
  white-space: nowrap;
  justify-content: flex-start;

  &.active {
    color: var(--c-mint-600);
  }

  &:hover {
    background-color: var(--c-mint-50);
  }
`;

const HoverBox = styled.div`
  position: absolute;
  left: 0;
  top: 100%;

  z-index: 999;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;

  padding: 12px;
  margin-top: 12px;

  width: 100%;
  min-width: 284px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  box-shadow: var(--shadow);
  background-color: var(--c-white);
  border: 1px solid var(--c-gray-300);
`;

const HoverCon = styled.div`
  position: relative;

  & > .h-a-box:hover ~ .h-box {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
`;

const UserStateHoverBox = styled.div`
  position: absolute;
  border: 8px;

  left: 0;
  bottom: 100%;

  z-index: 999;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  padding: 4px 8px;
  margin-bottom: 6px;
  border-radius: 8px;

  box-shadow: var(--shadow);
  background: var(--c-gray-700);
`;

const AgentInfoBox = styled.div`
  position: absolute;
  border: 8px;

  left: 0;

  z-index: 999;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;

  padding: 4px 8px;
  margin-bottom: 6px;
  border-radius: 8px;

  box-shadow: var(--shadow);
  background: var(--c-gray-700);
`;

export {
  UserManager,
  ContentArea,
  NoticeLayOut,
  //
  IconLink,
  InfoList,
  InfoContainer,
  //
  Dimmed,
  LineSection,
  ScrollContent,
  //
  PadBox,
  FlexBox,
  ModalBox,
  ShadowBox,
  PeriodBox,
  InfoTabBox,
  PeriodList,
  InfoListBox,
  PositionBox,
  InfoTabList,
  PeriodSelect,
  CategoryMenuBox,
  //
  DropDown,
  DropMenuBox,
  DropMenuList,
  DropMenuContainer,
  UserStateHoverBox,
  AgentInfoBox,
  //
  HoverCon,
  HoverBox,
  BorderBox,
  TooltipContainer,
  TooltipText
};
