import useFormData from "common/useFormData";
import useStateData from "common/useStateData";
import useTableData from "common/useTableData";
import AgentSearchFilter from "components/common/AgentSearchFilter";
import CalendarFilter from "components/common/CalendarFilter";
import { RadioForm, SelectForm } from "components/common/CommonForm";
import NoDataTable from "components/common/NoDataTable";
import { periodType, useFiveCountPerPage } from "constants/select/useValue";
import { useCommonState } from "contexts/useCommonState";
import { useAlert, usePopup } from "contexts/useWindow";
import { agentMoneyKindEnum, agentTypeEnum } from "enum/statistics/useAgentStatisticsEnum";
import { periodTypeEnum } from "enum/statistics/useTableEnum";
import { t } from "i18next";
import api from "interceptor/api";
import { isEmpty } from "lodash";
import { useState } from "react";
import { AreaHeader, Column, FilterBox, Row } from "style/custom/useCustomLayout";
import { FilterTitle } from "style/custom/useCustomText";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { IconLink, PositionBox } from "style/useLayoutStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { AreaTitle, SubText, TextBox } from "style/useTextStyles";
import Pagination from "utils/Pagination";
import { yyyymmToDateFormat, yyyymmddToDateFormat } from "utils/date/DateUtil";
import { sortDateObjectKeys } from "utils/statistics/StatisticsUtil";
import AgentBetMoneyList from "./AgentBetMoneyList";
import AgentChangeMoneyList from "./AgentChangeMoneyList";
import AgentCommissionList from "./AgentCommissionList";
import AgentInMoneyList from "./AgentInMoneyList";
import AgentPointList from "./AgentPointList";
import AgentWinMoneyList from "./AgentWinMoneyList";

const AgentStatistics = () => {
  const useStated = useStateData();
  const { setLoading } = useCommonState();

  const { openPopup } = usePopup();
  const { openAlert } = useAlert();
  const [period, setPeriod] = useState(periodTypeEnum.D.value);

  const getTableItem = () => {
    if (useSearch.searchValue.search_agent_val != "" && useSearch.searchValue.search_agent_category == "") {
      openAlert({
        title: t("header.alert_title"),
        message: t("agent.check_search_type"),
      });
      return
    }

    setLoading(true);

    const apiType = api.get;
    const apiUrl = "/v1/statistics/agent/list";
    apiType(apiUrl, {
      params: {
        ...useSearch.searchValue,
        ...useTG.extendData.pagingData,
        sortNm: useTG.extendData.sortNm,
      },
    })
      .then((res) => {
        if (res.data.content.state == "Null_AgentName") {
          openAlert({
            title: t("header.alert_title"),
            message: useSearch.searchValue.search_agent_name + t("agent.exist_agent_name"),
          });
          return
        } else if (res.data.content.state == "Null_AgentParentcode") {
          openAlert({
            title: t("header.alert_title"),
            message: useSearch.searchValue.search_agent_val + t("agent.exist_agent_code"),
          });
          return
        } else if (res.data.content.state == "Null_AgentNo") {
          openAlert({
            title: t("header.alert_title"),
            message: useSearch.searchValue.search_agent_val + t("agent.exist_agent_no"),
          });
          return
        }

        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setPeriod(useSearch.formValue.period);
        setUseSearchState(useSearch);
        useTG.setError("");
      })
      .catch((err) => {
        console.error(err);
        useTG.setError(true);
        useTG.setData([]);
        if (err.response.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      })
      .finally(() => {
        setLoading(false);
      });;
  };

  const useTG = useTableData(true, "5");
  const useSearch = useFormData(
    {
      search_sub_agent_yn: "Y",
      search_agent_category: "",
      search_agent_val: "",
      search_agent_name: "",

      period: periodTypeEnum.D.value,
      start_date: "",
      end_date: "",
    },
    getTableItem,
    useTG
  );

  const [useSearchState, setUseSearchState] = useState(useSearch);

  const groupedData = useTG.data
    ? useTG.data.reduce((acc, row) => {

      // 날짜 엔트리 초기화
      if (!acc[row.yyyymmdd]) {
        acc[row.yyyymmdd] = {};
      }

      // 결제 유형 엔트리 초기화
      if (!acc[row.yyyymmdd][row.m_kind]) {
        acc[row.yyyymmdd][row.m_kind] = {
          a_money: 0,
          b_money: 0,
          c_money: 0,
          d_money: 0,
        };
      }

      const paymentTypeEntry = acc[row.yyyymmdd][row.m_kind];

      // 타입에 따라 금액과 카운트 업데이트
      const updateEntry = (type, money) => {
        paymentTypeEntry[`${type.toLowerCase()}_money`] = money;
      };

      updateEntry(row.type, row.money);

      return acc;
    }, {})
    : {};

  const sortedDates = sortDateObjectKeys(groupedData);

  const showHistoryByKind = (date, kind, typeEnum) => {
    let contentComponent = null;
    switch (kind) {
      case agentMoneyKindEnum.A1.value:
        contentComponent = AgentInMoneyList;
        break;
      case agentMoneyKindEnum.A2.value:
        contentComponent = AgentChangeMoneyList;
        break;
      case agentMoneyKindEnum.A3.value:
        contentComponent = AgentBetMoneyList;
        break;
      case agentMoneyKindEnum.A4.value:
        contentComponent = AgentWinMoneyList;
        break;
      case agentMoneyKindEnum.A5.value:
        contentComponent = AgentPointList;
        break;
      case agentMoneyKindEnum.A6.value:
        contentComponent = AgentCommissionList;
        break;
      default:
        break;
    }


    if (contentComponent !== null) {
      openPopup({
        title: `${t("agentStatistics.agent_statistics")} - ${agentMoneyKindEnum[kind].label}(${typeEnum.label})`,
        content: contentComponent,
        props: {
          date,
          period,
          type: typeEnum.value,
          search: useSearchState,
        },
      });
    }
  }

  return (
    <>
      <AreaHeader>
        <SubText>{t("menu.stats_manager")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px" />
        <AreaTitle>{t("menu.agent_states")}</AreaTitle>
      </AreaHeader>

      <Column>
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
            <AgentSearchFilter useSearch={useSearch} useStated={useStated} $mar="0 24px 0 0 " />
            <Column $gap='4px'>
              <FilterTitle>{t("statistics.select_method_period")}</FilterTitle>
              <Row $align='center' $gap='12px' $height='34px'>
                <RadioForm name='period' options={periodType} {...useSearch} />
                <CalendarFilter endName='end_date' startName='start_date' {...useSearch} end />
              </Row>
            </Column>
          </Row>

          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
              {t("filter.search")}
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
              {t("filter.refresh")}
            </WhiteBtn>
          </Row>
        </FilterBox>

        <Row $jus="flex-end" $width="100%" $align="center" $pad="20px 0 14px">
          <SelectForm
            name="countPerPage"
            placeholder={useFiveCountPerPage[0].label}
            {...useTG}
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useFiveCountPerPage}
            formValue={useFiveCountPerPage.value}
          />
        </Row>

        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  <TableHeader $width="60px">{t("common.no")}</TableHeader>
                  <TableHeader $width="200px">{t("statistics.date")}</TableHeader>
                  <TableHeader $width="230px">{t("statistics.category")}</TableHeader>
                  <TableHeader $width="280px">{t("agentStatistics.head_agent")}</TableHeader>
                  <TableHeader $width="280px">{t("agentStatistics.branch_office")}</TableHeader>
                  <TableHeader $width="280px">{t("agentStatistics.general_distributor")}</TableHeader>
                  <TableHeader $width="280px">{t("agentStatistics.store")}</TableHeader>
                  <TableHeader $width="280px">{t("agentStatistics.sales")}</TableHeader>
                </tr>
              </thead>
              {sortedDates.map((date, index) => {
                const mKind = Object.keys(groupedData[date]);
                mKind.sort();
                return (
                  <tbody key={date}>
                    {mKind.map((m_kind, ptIndex) => {
                      const number = useTG.pagingInfo.offset - index;
                      const item = groupedData[date][m_kind];
                      const rowSpan = ptIndex === 0 ? mKind.length : 1;

                      const a_money = item.a_money ? parseInt(item.a_money) : 0;
                      const b_money = item.b_money ? parseInt(item.b_money) : 0;
                      const c_money = item.c_money ? parseInt(item.c_money) : 0;
                      const d_money = item.d_money ? parseInt(item.d_money) : 0;
                      const e_money = item.e_money ? parseInt(item.e_money) : 0;

                      const renderTextBox = (money, agentType) => {
                        return money !== 0 ? (
                          <TextBox
                            as="button"
                            $textDeco="underline"
                            onClick={() => showHistoryByKind(date, m_kind, agentType)}
                          >
                            {`${money.toLocaleString(undefined, {
                              style: "currency",
                              currency: "KRW",
                            })}`}
                          </TextBox>
                        ) : (
                          <TextBox>{`-`}</TextBox>
                        );
                      };

                      return (
                        <TableRow key={`${date}-${m_kind}-${ptIndex}`} className="table-content">
                          {ptIndex === 0 && (
                            <>
                              <TableDeps $width="60px" rowSpan={rowSpan}>{number}</TableDeps>
                              <TableDeps $width="130px" rowSpan={rowSpan}>
                                {period === periodTypeEnum.D.value
                                  ? yyyymmddToDateFormat(date) || "-"
                                  : period === periodTypeEnum.M.value
                                    ? yyyymmToDateFormat(date) || "-"
                                    : "-"}
                              </TableDeps>
                            </>
                          )}
                          <TableDeps $tAlign="left">{`${agentMoneyKindEnum[m_kind]?.label || "-"}`}</TableDeps>
                          <TableDeps $tAlign="right">{renderTextBox(a_money, agentTypeEnum.A)}</TableDeps>
                          <TableDeps $tAlign="right">{renderTextBox(b_money, agentTypeEnum.B)}</TableDeps>
                          <TableDeps $tAlign="right">{renderTextBox(c_money, agentTypeEnum.C)}</TableDeps>
                          <TableDeps $tAlign="right">{renderTextBox(d_money, agentTypeEnum.D)}</TableDeps>
                          <TableDeps $tAlign="right">{renderTextBox(e_money, agentTypeEnum.E)}</TableDeps>
                        </TableRow>
                      );
                    })}
                  </tbody>
                );
              })}
            </Table>
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}

        <Row
          $gap="8px"
          $pad="14px 0 0"
          $align="center"
          $jus="flex-end"
          $width="100%"
        >
          <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
            {!useTG.loading && !isEmpty(useTG.pagingInfo) && (
              <Pagination {...useTG} />
            )}
          </PositionBox>
          <SelectForm
            top="top"
            name="countPerPage"
            placeholder={useFiveCountPerPage[0].label}
            {...useTG}
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useFiveCountPerPage}
            formValue={useFiveCountPerPage.value}
          />
        </Row>
      </Column>
    </>
  );

};

export default AgentStatistics;
