import i18n from "locales/i18n";

const agentMoneyKindEnum = {
  A1: {
    label: `1. ${i18n.t("statistics.user_charge")}`,
    value: "A1",
    key: "A1",
  },
  A2: {
    label: `2. ${i18n.t("statistics.user_change")}`,
    value: "A2",
    key: "A2",
  },
  A3: {
    label: `3. ${i18n.t("statistics.bet_amount")}`,
    value: "A3",
    key: "A3",
  },
  A4: {
    label: `4. ${i18n.t("statistics.win_amount")}`,
    value: "A4",
    key: "A4",
  },
  A5: {
    label: `5. ${i18n.t("statistics.point")}`,
    value: "A5",
    key: "A5",
  },
  A6: {
    label: `6. ${i18n.t("statistics.commission_amount")}`,
    value: "A6",
    key: "A6",
  },
};

const agentTypeEnum = {
  A: {
    label: "본사",
    value: "A",
    key: "A",
  },
  B: {
    label: "부본사",
    value: "B",
    key: "B",
  },
  C: {
    label: "총판",
    value: "C",
    key: "C",
  },
  D: {
    label: "매장",
    value: "D",
    key: "D",
  },
  E: {
    label: "영업",
    value: "E",
    key: "E",
  },
};

export { agentMoneyKindEnum, agentTypeEnum };
