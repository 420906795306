import React, { useEffect, useState } from "react";

// 공용
import Pagination from "utils/Pagination";
import api from "interceptor/api";

import useTableData from "common/useTableData";

import { useAlert, useConfirm, usePopup } from "contexts/useWindow";
import { NoLabelCheckForm, InputForm, SelectForm, RadioForm } from "components/common/CommonForm";
import { getFormatDatetime } from "utils/date/DateUtil";
// 셀렉트 벨류 constant
import { useCountPerPage } from "constants/select/useValue";
// 공용 팝업
// style import
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { IconLink, PositionBox } from "style/useLayoutStyles";
import { AreaTitle, SubText, TextBox } from "style/useTextStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { AreaHeader, Column, FilterBox, Row } from "style/custom/useCustomLayout";
import { isEmpty } from "lodash";
import { FilterTitle } from "style/custom/useCustomText";
import useFormData from "common/useFormData";
import NoDataTable from "components/common/NoDataTable";
import { useCommonState } from "contexts/useCommonState";
import GameMngPopupForm from "./GameMngPopupForm";
import useStateData from "common/useStateData";
import { ModifyBtn } from "components/common/TableStatus";
import { t } from "i18next";

const VirtualCurrency = () => {
  const useTG = useTableData(true);
  const useStated = useStateData();

  const { openPopup } = usePopup();
  const { openAlert } = useAlert();
  const { setLoading } = useCommonState();

  const [vendorList, setVendorList] = useState([]);
  const [gameList, setGameList] = useState([]);
  const [allGameList, setAllGameList] = useState([]);

  const confirmUseYn = (use_yn) => {
    const checkedGameTitle = useTG.data
      .filter((item) => item.isChecked)
      .map((item) => item.title)
      .join(", ");
    if (checkedGameTitle === "") {
      openAlert({
        title: t("gameManagement.select_game"),
        message: t("gameManagement.no_selected_game"),
        iconURL: true,
      });

      return;
    }
    putUseYn(use_yn);
  };

  useEffect(() => {
    const getVendorApi = "/v1/game/vendor";
    api
      .get(getVendorApi, {})
      .then(async (res) => {
        await setVendorList(
          res.data.map((vendor, index) => {
            return {
              label: vendor.name,
              value: vendor.name,
              key: index + 1,
            };
          })
        );

        setVendorList((vendorList) => [{ label: t("gameManagement.game_vendor"), value: '', key: 0 }, ...vendorList])
      })
      .catch((err) => {
        console.error(err);
      });

    const getGameListApi = "/v1/game/game-list";
    api
      .get(getGameListApi, {
        params: {
          game_type: 'R'
        }
      })
      .then(async (res) => {
        await setGameList(
          res.data.map((data, index) => {
            return {
              label: data.title,
              value: data.title,
              key: index + 1,
              vendor: data.vendor,
            };
          })
        );
        setGameList((gameList) => [{ label: t("gameManagement.game_name"), value: '', key: -1 }, ...gameList])
        await setAllGameList(
          res.data.map((data, index) => {
            return {
              label: data.title,
              value: data.title,
              key: index + 1,
              vendor: data.vendor,
            };
          })
        );

        //지우지 말것
        console.log(allGameList);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const showPopup = (item) => {
    openPopup({
      title: t("gameManagement.game_management", { title: item?.title }),
      content: GameMngPopupForm,
      props: {
        item,
        getGameList,
      },
    });
  };

  const getGameList = () => {
    setLoading(true);
    api
      .get("/v1/game-management", {
        params: {
          ...useSearch.searchValue,
          ...useTG.extendData.pagingData,
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useStated.setSuccessData([res.data.content]);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status === 400) {
          useStated.setErrorData(error.response.data.errors);
        }
        console.error(error);
      });
  };

  const putUseYn = (use_yn) => {
    const checkedList = useTG.data.filter((item) => item.isChecked);
    api
      .put(`/v1/game-management/use-yn`, { no_list: checkedList.map((item) => item.no), use_yn: use_yn })
      .then((res) => {
        getGameList();
      })
      .catch((error) => {
        openAlert({
          title: t("gameManagement.change_use_status"),
          message: t("gameManagement.change_use_status_failed"),
          iconURL: true,
        });
        console.error(error);
      });
  };

  const useSearch = useFormData(
    {
      game_type: "R",
      vendorkeyword: "",
      titlekeyword: "",
      search_use_yn: activeStateEnum.all.value,
    },
    getGameList,
    useTG
  );

  const selectVendor = (vendor) => {
    if (vendor) {
      setGameList(
        allGameList.filter((data) => {
          return data.vendor == vendor
        })
      )
    } else {
      setGameList(allGameList)
    }
  }

  return (
    <>
      <AreaHeader>
        <SubText>{t("gameManagement.game_management")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{t("gameManagement.virtual_currency")}</AreaTitle>
      </AreaHeader>

      <Column>
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
            <Column $gap="4px">
              <FilterTitle>{t("gameManagement.search_filter")}</FilterTitle>
              <Row $gap="4px">
                <SelectForm
                  name="vendorkeyword"
                  placeholder={t("gameManagement.game_vendor")}
                  options={vendorList}
                  {...useSearch}
                  {...useStated}
                  width={'180px'}
                  onChangeEx={(e, v) => {
                    selectVendor(v)
                  }}
                />

                <SelectForm
                  name="titlekeyword"
                  placeholder={t("gameManagement.game_name")}
                  options={gameList}
                  {...useSearch}
                  {...useStated}
                  width={'440px'}
                />
              </Row>
            </Column>
            <Column $gap="4px">
              <FilterTitle>{t("common.state")}</FilterTitle>
              <Row $align="center" $gap="12px" $height="34px">
                <RadioForm name="search_use_yn" options={activeStateEnum} {...useSearch} />
              </Row>
            </Column>
          </Row>
          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
              {t("filter.search")}
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
              {t("filter.refresh")}
            </WhiteBtn>
          </Row>
        </FilterBox>
        <Row $gap="12px">
          <Row className="total-count" $gap="4px" $pad="14px 0 0 0">
            <TextBox $font="var(--f-subB)">{t("gameManagement.total_registered_games")}</TextBox>
            <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
              {useTG.error ? (
                <span>error</span>
              ) : (
                <>{useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords?.toLocaleString() : 0}</>
              )}
            </TextBox>
            <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
          </Row>
          <Row className="total-count" $gap="4px" $pad="14px 0 0 0">
            <TextBox $font="var(--f-subB)">{t("gameManagement.active_games")}</TextBox>
            <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
              {useTG.error ? (
                <span>error</span>
              ) : (
                <>{useTG && useTG.pagingInfo ? useTG.pagingInfo.using_game_count?.toLocaleString() : 0}</>
              )}
            </TextBox>
            <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
          </Row>
          <Row className="total-count" $gap="4px" $pad="14px 0 0 0">
            <TextBox $font="var(--f-subB)">{t("gameManagement.inactive_games")}</TextBox>
            <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
              {useTG.error ? (
                <span>error</span>
              ) : (
                <>
                  {useTG && useTG.pagingInfo
                    ? ((useTG.pagingInfo.totalRecords ?? 0) - (useTG.pagingInfo.using_game_count ?? 0)).toLocaleString()
                    : 0}
                </>
              )}
            </TextBox>
            <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
          </Row>
        </Row>

        <Row $jus="space-between" $width="100%" $align="center" $pad="14px 0">
          <Row $gap="10px" $align="center">
            <Row $gap="8px">
              <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => confirmUseYn(activeStateEnum.active.value)}>
                {t("common.use_y")}
              </MainBtn>
              <WhiteBtn onClick={() => confirmUseYn(activeStateEnum.inactive.value)}>{t("common.use_block")}</WhiteBtn>
            </Row>
          </Row>
          <SelectForm
            {...useTG}
            name="countPerPage"
            placeholder={useCountPerPage[0].label}
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useCountPerPage}
            formValue={useTG.extendData}
          />
        </Row>

        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            {
              <Table className="table-box">
                <thead>
                  <tr className="table-title">
                    <TableHeader $width="34px">
                      <NoLabelCheckForm $mar="0 0 0 !important" name="all" base={useTG} />
                    </TableHeader>
                    <TableHeader $width="60px">
                      <TextBox>{t("common.no")}</TextBox>
                    </TableHeader>
                    <TableHeader>{t("gameManagement.game_name")}</TableHeader>
                    <TableHeader>{t("gameManagement.game_vendor")}</TableHeader>
                    <TableHeader>{t("gameManagement.rolling")}</TableHeader>
                    <TableHeader>{t("gameManagement.losing")}</TableHeader>
                    <TableHeader $width="130px">{t("gameManagement.registered_date")}</TableHeader>
                    <TableHeader $width="100px">{t("common.state")}</TableHeader>
                    <TableHeader $width="150px">{t("gameManagement.total_bet_amount")}</TableHeader>
                    <TableHeader $width="130px">{t("gameManagement.total_win_amount")}</TableHeader>
                    <TableHeader $width="130px">{t("gameManagement.total_bet_win_amount")}</TableHeader>
                    <TableHeader $width="130px">{t("common.manage")}</TableHeader>
                  </tr>
                </thead>
                {useTG && useTG.data?.length > 0 && (
                  <tbody>
                    {useTG.data &&
                      useTG.data.map((item, index) => {
                        const number = useTG.pagingInfo.offset - index;
                        return (
                          <TableRow key={number} className="table-content">
                            <TableDeps>
                              <NoLabelCheckForm keys={item.no} base={useTG} eachData={item} />
                            </TableDeps>
                            <TableDeps $width="60px">{number || "-"}</TableDeps>
                            <TableDeps $tAlign="left">{item.title ?? "-"}</TableDeps>
                            <TableDeps $width="230px" $tAlign="left">
                              {item.vendor ?? "-"}
                            </TableDeps>
                            <TableDeps $width="90px" $tAlign="right">
                              {item.rolling_rate ?? "-"}
                            </TableDeps>
                            <TableDeps $width="90px" $tAlign="right">
                              {item.losing_rate ?? "-"}
                            </TableDeps>
                            <TableDeps $width="150px">{getFormatDatetime(item.create_at)}</TableDeps>
                            <TableDeps $width="100px">
                              <TextBox className={item.use_yn === "Y" ? "complete" : "warn"}>
                                {item.use_yn === "Y" ? t("gameManagement.active_games") : t("gameManagement.inactive_games")}
                              </TextBox>
                            </TableDeps>
                            <TableDeps $width="130px" $tAlign="right">
                              {item.bet_money?.toLocaleString() ?? "0"}
                            </TableDeps>
                            <TableDeps $width="130px" $tAlign="right">
                              {item.win_money?.toLocaleString() ?? "0"}
                            </TableDeps>
                            <TableDeps $width="130px" $tAlign="right">
                              {((item.bet_money ?? 0) - (item.win_money ?? 0)).toLocaleString()}
                            </TableDeps>
                            <TableDeps $width="80px">
                              <Row $width="100%" $jus="center" $gap="6px">
                                <ModifyBtn name={t("common.go_detail")} eventProps={() => showPopup(item)} />
                              </Row>
                            </TableDeps>
                          </TableRow>
                        );
                      })}
                  </tbody>
                )}
              </Table>
            }
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}
        <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="space-between" $width="100%">
          <Row $gap="8px">
            <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => confirmUseYn(activeStateEnum.active.value)}>
              {t("common.use_y")}
            </MainBtn>
            <WhiteBtn onClick={() => confirmUseYn(activeStateEnum.inactive.value)}>{t("common.block")}</WhiteBtn>
          </Row>
          <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
            {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
          </PositionBox>

          <Row $gap="8px">
            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              top="top"
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useTG.extendData}
            />
          </Row>
        </Row>
      </Column>
    </>
  );
};

const searchStateEnum = {
  title: {
    label: t("gameManagement.game_name"),
    value: "title",
    key: 0,
  },
  vendor: {
    label: t("gameManagement.game_vendor"),
    value: "vendor",
    key: 1,
  },
};

const activeStateEnum = {
  all: {
    label: t("common.all"),
    value: "",
    key: 0,
  },
  active: {
    label: t("common.use_y"),
    value: "Y",
    key: 1,
  },
  inactive: {
    label: t("common.use_n"),
    value: "N",
    key: 2,
  },
};

export default VirtualCurrency;
