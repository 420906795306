import React from "react";

import { ErrorMsg, SuccessMsg } from "style/useTextStyles";
import { useTranslation } from "react-i18next";
import { isString } from "lodash";
import { validationTypeEnum } from "enum/useCommonEnum";

/**
 * 에러를 처리하여 해당하는 에러를 반환합니다.
 * @param {string} name   : 에러 이름
 * @param {Array}  errors : 에러 배열
 * @returns {object|null} : 해당하는 에러 객체 또는 null
 */

export const findStateByName = (name, errors, groupNm, index) => {
  try {
    if (groupNm && index !== undefined) {
      return (
        errors && errors.length > 0 && errors.find((error) => error.path != null && error.path === `${groupNm}[${index}].${name}`)
      );
    }
    return errors && errors.length > 0 && errors.find((error) => error.path != null && error.path === name);
  } catch (err) {
    return false;
  }
};

export const StateMsgForm = ({ stateJson, stateType }) => {
  const { t, i18n } = useTranslation();

  // path를 설정합니다.
  // root key 가 있는 경우는 그대로 path를 설정하고 없는 경우는 defaultPath를 참조합니다.
  function setPath(pathValue) {
    if (isString(pathValue)) {
      if (pathValue.includes(".")) {
        return pathValue;
      }
      return i18n.exists(`${"defaultPath." + pathValue}`) ? `${"defaultPath." + pathValue}` : pathValue;
    } else {
      // String 값이 아니면 그대로 반환합니다.
      return pathValue;
    }
  }

  /**
   * @param {*} values
   * @returns {value0: values[0], value1: values[1], ...}
   */

  function errorObject(values) {
    if (!values) return {}; // 파라미터가 없으면 빈 객체 반환

    /**
     * @param acc   : 이전 요소의 값
     * @param value : 현재 요소의 값
     * @param index : 인덱스
     * @returns {values.reduce} : 파라미터 배열 객채로 변환
     */

    return values.reduce((acc, value, index) => {
      acc[`value${index}`] = t(setPath(value));
      return acc;
    }, {});
  }

  // msg.path가 있으면 path를 설정하고 없으면 defaultPath를 설정합니다.
  // error.path가 기본값이고 error.msg.path로 덮어씌워 다른 값을 설정할 수 있습니다.
  const path = stateJson.msg?.path ? setPath(stateJson.msg.path) : `${"defaultPath." + stateJson.path}`;
  return (
    <>
      {stateType == "success" && (
        <SuccessMsg className="Success">
          {t(`success.${stateJson.msg.code || "M000"}`, {
            param: i18n.exists(path) ? t(path) : t("success.defaultParam"),
            ...errorObject(stateJson.msg.values),
          })}
        </SuccessMsg>
      )}
      {stateType == "error" && (
        <ErrorMsg className="error">
          {t(`error.${stateJson.msg.code || "M000"}`, {
            param: i18n.exists(path) ? t(path) : t("error.defaultParam"),
            ...errorObject(stateJson.msg.values),
          })}
        </ErrorMsg>
      )}
    </>
  );
};

export const ErrorMsgForm = ({ errorJson }) => {
  const { t, i18n } = useTranslation();

  // path를 설정합니다.
  // root key 가 있는 경우는 그대로 path를 설정하고 없는 경우는 defaultPath를 참조합니다.
  function setPath(pathValue) {
    if (isString(pathValue)) {
      if (pathValue.includes(".")) {
        return pathValue;
      }
      return i18n.exists(`${"defaultPath." + pathValue}`) ? `${"defaultPath." + pathValue}` : pathValue;
    } else {
      // String 값이 아니면 그대로 반환합니다.
      return pathValue;
    }
  }

  /**
   * @param {*} values
   * @returns {value0: values[0], value1: values[1], ...}
   */

  function errorObject(values) {
    if (!values) return {}; // 파라미터가 없으면 빈 객체 반환

    /**
     * @param acc   : 이전 요소의 값
     * @param value : 현재 요소의 값
     * @param index : 인덱스
     * @returns {values.reduce} : 파라미터 배열 객채로 변환
     */

    return values.reduce((acc, value, index) => {
      acc[`value${index}`] = t(setPath(value));
      return acc;
    }, {});
  }

  // msg.path가 있으면 path를 설정하고 없으면 defaultPath를 설정합니다.
  // error.path가 기본값이고 error.msg.path로 덮어씌워 다른 값을 설정할 수 있습니다.
  const path = errorJson.msg?.path ? setPath(errorJson.msg.path) : setPath(errorJson.path);
  return (
    <ErrorMsg className="error">
      {t(`error.${errorJson.msg.code || "M000"}`, {
        param: i18n.exists(path) ? t(path) : t("error.defaultParam"),
        ...errorObject(errorJson.msg.values),
      })}
    </ErrorMsg>
  );
};

/**
 * @mask
 */

// 마스크 유형에 따라 마스크 문자열을 반환하는 함수
export const getMask = (maskType) => {
  switch (maskType) {
    case "time":
      return "99:99:99";
    case "phone":
      return "+99 99-9999-9999";
    case "date":
      return "YYYY-mM-dD";
    case "date_time":
      return "YYYY-mM-dD hH:nN:sS";
    default:
      return null;
  }
};

// 마스크 유형에 따라 포맷 문자를 반환하는 함수
export const getFormatChars = (maskType) => {
  switch (maskType) {
    case "date":
      return {
        Y: "[0-9]",
        m: "[0-1]",
        M: "[0-9]",
        d: "[0-3]",
        D: "[0-9]",
      };
    case "date_time":
      return {
        Y: "[0-9]",
        m: "[0-1]",
        M: "[0-9]",
        d: "[0-3]",
        D: "[0-9]",
        h: "[0-2]",
        H: "[0-9]",
        n: "[0-5]",
        N: "[0-9]",
        s: "[0-5]",
        S: "[0-9]",
      };
    default:
      return null;
  }
};

export const isValidInput = (value, validationType) => {
  switch (validationType) {
    case validationTypeEnum.ENGLISH:
      return /^[a-zA-Z]*$/.test(value);
    case validationTypeEnum.NUMBER:
      return /^[0-9]*$/.test(value);
    case validationTypeEnum.ENGLISH_AND_NUMBER:
      return /^[a-zA-Z0-9]*$/.test(value);
    default:
      return true; // No validation if validationType is not specified
  }
};
