import useFormData from "common/useFormData";
import useStateData from "common/useStateData";
import useTableData from "common/useTableData";
import AgentSearchFilter from "components/common/AgentSearchFilter";
import CalendarFilter from "components/common/CalendarFilter";
import { RadioForm, SelectForm } from "components/common/CommonForm";
import NoDataTable from "components/common/NoDataTable";
import { periodType, useCountPerPage } from "constants/select/useValue";
import { useCommonState } from "contexts/useCommonState";
import { useAlert, usePopup } from "contexts/useWindow";
import { periodTypeEnum } from "enum/statistics/useTableEnum";
import api from "interceptor/api";
import { isEmpty } from "lodash";
import { useState } from "react";
import { AreaHeader, Column, FilterBox, Row } from "style/custom/useCustomLayout";
import { FilterTitle } from "style/custom/useCustomText";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { IconLink, PositionBox } from "style/useLayoutStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { AreaTitle, SubText, TextBox } from "style/useTextStyles";
import { yyyymmToDateFormat, yyyymmddToDateFormat } from "utils/date/DateUtil";
import Pagination from "utils/Pagination";
import UserEveryChargePointList from "./UserEveryChargePointList";
import UserFirstChargePointList from "./UserFirstChargePointList";
import UserJoinFirstChargePointList from "./UserJoinFirstChargePointList";
import UserJoinPointList from "./UserJoinPointList";
import UserPaymentPointList from "./UserPaymentPointList";
import UserRetrievalPointList from "./UserRetrievalPointList";
import UserRollingPointInfo from "./UserRollingPointInfo";
import UserLosingPointInfo from "./UserLosingPointInfo";
import UserLevelUpList from "./UserLevelUpList";
import { t } from "i18next";

const UserPointStatistics = () => {
  const useStated = useStateData();
  const { setLoading } = useCommonState();
  const { openPopup } = usePopup();
  const { openAlert } = useAlert();
  const [period, setPeriod] = useState(periodTypeEnum.D.value);

  const popupConfig = {
    userJoinPointList: {
      title: `${t("pointStatistics.point_statistics")} - ${t("pointStatistics.join_point")}`,
      content: UserJoinPointList,
    },
    userJoinFirstChargePointList: {
      title: `${t("pointStatistics.point_statistics")} - ${t("pointStatistics.join_first_charge_point")}`,
      content: UserJoinFirstChargePointList,
    },
    userFirstChargePointList: {
      title: `${t("pointStatistics.point_statistics")} - ${t("pointStatistics.first_charge_point")}`,
      content: UserFirstChargePointList,
    },
    UserEveryChargePointList: {
      title: `${t("pointStatistics.point_statistics")} - ${t("pointStatistics.every_charge_point")}`,
      content: UserEveryChargePointList,
    },
    UserRollingPointInfo: {
      title: `${t("pointStatistics.point_statistics")} - ${t("pointStatistics.rolling")}`,
      content: UserRollingPointInfo,
    },
    UserLosingPointInfo: {
      title: `${t("pointStatistics.point_statistics")} - ${t("pointStatistics.losing")}`,
      content: UserLosingPointInfo,
    },
    UserRetrievalPointList: {
      title: `${t("pointStatistics.point_statistics")} - ${t("pointStatistics.retrieval_point")}`,
      content: UserRetrievalPointList,
    },
    UserPaymentPointList: {
      title: `${t("pointStatistics.point_statistics")} - ${t("pointStatistics.manual_payment")}`,
      content: UserPaymentPointList,
    },
    UserLevelUpList: {
      title: `${t("pointStatistics.point_statistics")} - ${t("pointStatistics.level_up_point")}`,
      content: UserLevelUpList,
    },
  };

  const showPopupByType = (popupType, itemDate, dateFormat) => {
    const { title, content } = popupConfig[popupType];
    const date = period === periodTypeEnum.D.value ? dateFormat : itemDate;

    openPopup({
      title,
      content,
      props: {
        date,
        period,
        search: useSearch,
      },
    });
  };

  const getTotalAmount = (item) => {
    const fields = [
      'total_join_amount',
      'total_join_first_charge_amount',
      'total_first_charge_amount',
      'total_every_charge_amount',
      'total_rolling_amount',
      'total_losing_amount',
      'total_retrieval_amount',
      'total_payment_amount'
    ];

    const totalAmount = fields.reduce((sum, field) => {
      if (field === 'total_retrieval_amount') {
        return sum + Math.abs(Number(item[field]) || 0);
      } else {
        return sum + (Number(item[field]) || 0);
      }
    }, 0);

    return `${totalAmount?.toLocaleString()}P` || 0;
  };

  const getTableItem = () => {
    if (useSearch.searchValue.search_agent_val != "" && useSearch.searchValue.search_agent_category == "") {
      openAlert({
        title: t("header.alert_title"),
        message: t("agent.check_search_type"),
      });
      return
    }

    setLoading(true);

    const apiType = api.get;
    const apiUrl = "/v1/statistics/user-point/list";

    apiType(apiUrl, {
      params: {
        ...useSearch.searchValue,
        ...useTG.extendData.pagingData,
        sortNm: useTG.extendData.sortNm,
        start_date: useSearch.searchValue.start_date == "" ? new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, "0") + '-' + new Date().getDate().toString().padStart(2, "0") : useSearch.searchValue.start_date,
        end_date: useSearch.searchValue.end_date == "" ? new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, "0") + '-' + new Date().getDate().toString().padStart(2, "0") : useSearch.searchValue.end_date
      },
    })
      .then((res) => {
        if (res.data.content.state == "Null_AgentName") {
          openAlert({
            title: t("header.alert_title"),
            message: useSearch.searchValue.search_agent_name + t("agent.exist_agent_name"),
          });
          return
        } else if (res.data.content.state == "Null_AgentParentcode") {
          openAlert({
            title: t("header.alert_title"),
            message: useSearch.searchValue.search_agent_val + t("agent.exist_agent_code"),
          });
          return
        } else if (res.data.content.state == "Null_AgentNo") {
          openAlert({
            title: t("header.alert_title"),
            message: useSearch.searchValue.search_agent_val + t("agent.exist_agent_no"),
          });
          return
        }

        useTG.setData(res.data.content);
        console.log(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setPeriod(useSearch.formValue.period);
        setLoading(false);
        useTG.setError("");
      })
      .catch((err) => {
        console.error(err);
        useTG.setError(true);
        setLoading(false);
        useTG.setData([]);
        if (err.response.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      })
      .finally(() => {
        setLoading(false);
      });



  };

  const useTG = useTableData(true);
  const useSearch = useFormData(
    {
      search_sub_agent_yn: "Y",
      search_agent_category: "",
      search_agent_val: "",
      search_agent_name: "",

      period: periodTypeEnum.D.value,
      start_date: "",
      end_date: "",
    },
    getTableItem,
    useTG
  );

  return (
    <>
      <AreaHeader>
        <SubText>{t("menu.stats_manager")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{t("menu.user_point_states")}</AreaTitle>
      </AreaHeader>

      <Column>
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
            <AgentSearchFilter useSearch={useSearch} useStated={useStated} $mar="0 24px 0 0 " />

            <Column $gap='4px'>
              <FilterTitle>{t("statistics.select_method_period")}</FilterTitle>
              <Row $align='center' $gap='12px' $height='34px'>
                <RadioForm name='period' options={periodType} {...useSearch} />
                <CalendarFilter endName="end_date" startName="start_date" {...useSearch} end />
              </Row>
            </Column>

          </Row>

          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
              {t("filter.search")}
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
              {t("filter.refresh")}
            </WhiteBtn>
          </Row>
        </FilterBox>

        <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
          <Row $gap="8px">
            <SelectForm
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              {...useTG}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useCountPerPage.value}
            />
          </Row>
        </Row>

        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  <TableHeader $width="60px">{t("common.no")}</TableHeader>
                  <TableHeader $width="130px">{t("statistics.date")}</TableHeader>
                  <TableHeader>{t("pointStatistics.occurrence_point")}</TableHeader>
                  <TableHeader>{t("pointStatistics.join")}</TableHeader>
                  <TableHeader>{t("pointStatistics.join_first_charge")}</TableHeader>
                  <TableHeader>{t("pointStatistics.first_charge")}</TableHeader>
                  <TableHeader>{t("pointStatistics.every_charge")}</TableHeader>
                  <TableHeader>{t("pointStatistics.rolling")}</TableHeader>
                  <TableHeader>{t("pointStatistics.losing")}</TableHeader>
                  <TableHeader>{t("pointStatistics.retrieval_point")}</TableHeader>
                  <TableHeader>{t("pointStatistics.payment_point")}</TableHeader>
                  <TableHeader>{t("pointStatistics.level_up_point")}</TableHeader>
                </tr>
              </thead>
              {useTG && useTG.data?.length > 0 && (
                <tbody>
                  {useTG.data &&
                    useTG.data.map((item, index) => {
                      const number = useTG.pagingInfo.offset - index;
                      const dateFormat = period === periodTypeEnum.D.value
                        ? yyyymmddToDateFormat(item.date) || "-"
                        : period === periodTypeEnum.M.value
                          ? yyyymmToDateFormat(item.date) || "-"
                          : "-";

                      const joinAmount = item.total_join_amount ? parseInt(item.total_join_amount) : 0;
                      const joinFirstChargeAmount = item.total_join_first_charge_amount ? parseInt(item.total_join_first_charge_amount) : 0;
                      const firstChargeAmount = item.total_first_charge_amount ? parseInt(item.total_first_charge_amount) : 0;
                      const everyChargeAmount = item.total_every_charge_amount ? parseInt(item.total_every_charge_amount) : 0;
                      const rollingAmount = item.total_rolling_amount ? parseInt(item.total_rolling_amount) : 0;
                      const losingAmount = item.total_losing_amount ? parseInt(item.total_losing_amount) : 0;
                      const retrievalAmount = item.total_retrieval_amount ? Math.abs(parseInt(item.total_retrieval_amount)) : 0;
                      const paymentAmount = item.total_payment_amount ? parseInt(item.total_payment_amount) : 0;
                      const levelUpAmount = item.total_level_up_amount ? parseInt(item.total_level_up_amount) : 0;

                      return (
                        <TableRow key={item.date} className="table-content">
                          <TableDeps $width="60px">{number || "-"}</TableDeps>
                          <TableDeps>{dateFormat}</TableDeps>
                          <TableDeps $tAlign="right">{getTotalAmount(item)}</TableDeps>

                          <TableDeps $tAlign="right">
                            {joinAmount !== 0 ? (
                              <TextBox
                                as="button"
                                $textDeco="underline"
                                onClick={() => showPopupByType("userJoinPointList", item.date, dateFormat)}
                              >
                                {`${joinAmount.toLocaleString()}P`}
                              </TextBox>
                            ) : (
                              <TextBox>{`-`}</TextBox>
                            )}
                          </TableDeps>

                          <TableDeps $tAlign="right">
                            {joinFirstChargeAmount !== 0 ? (
                              <TextBox
                                as="button"
                                $textDeco="underline"
                                onClick={() => showPopupByType("userJoinFirstChargePointList", item.date, dateFormat)}
                              >
                                {`${joinFirstChargeAmount.toLocaleString()}P`}
                              </TextBox>
                            ) : (
                              <TextBox>{`-`}</TextBox>
                            )}
                          </TableDeps>

                          <TableDeps $tAlign="right">
                            {firstChargeAmount !== 0 ? (
                              <TextBox
                                as="button"
                                $textDeco="underline"
                                onClick={() => showPopupByType("userFirstChargePointList", item.date, dateFormat)}
                              >
                                {`${firstChargeAmount.toLocaleString()}P`}
                              </TextBox>
                            ) : (
                              <TextBox>{`-`}</TextBox>
                            )}
                          </TableDeps>

                          <TableDeps $tAlign="right">
                            {everyChargeAmount !== 0 ? (
                              <TextBox
                                as="button"
                                $textDeco="underline"
                                onClick={() => showPopupByType("UserEveryChargePointList", item.date, dateFormat)}
                              >
                                {`${everyChargeAmount.toLocaleString()}P`}
                              </TextBox>
                            ) : (
                              <TextBox>{`-`}</TextBox>
                            )}
                          </TableDeps>

                          <TableDeps $tAlign="right">
                            {rollingAmount !== 0 ? (
                              <TextBox
                                as="button"
                                $textDeco="underline"
                                onClick={() => showPopupByType("UserRollingPointInfo", item.date, dateFormat)}
                              >
                                {`${rollingAmount.toLocaleString()}P`}
                              </TextBox>
                            ) : (
                              <TextBox>{`-`}</TextBox>
                            )}
                          </TableDeps>

                          <TableDeps $tAlign="right">
                            {losingAmount !== 0 ? (
                              <TextBox
                                as="button"
                                $textDeco="underline"
                                onClick={() => showPopupByType("UserLosingPointInfo", item.date, dateFormat)}
                              >
                                {`${losingAmount.toLocaleString()}P`}
                              </TextBox>
                            ) : (
                              <TextBox>{`-`}</TextBox>
                            )}
                          </TableDeps>

                          <TableDeps $tAlign="right">
                            {retrievalAmount !== 0 ? (
                              <TextBox
                                as="button"
                                $textDeco="underline"
                                onClick={() => showPopupByType("UserRetrievalPointList", item.date, dateFormat)}
                              >
                                {`${retrievalAmount.toLocaleString()}P/${item.count_retrieval}${t("common.count")}`}
                              </TextBox>
                            ) : (
                              <TextBox>{`-`}</TextBox>
                            )}
                          </TableDeps>

                          <TableDeps $tAlign="right">
                            {paymentAmount !== 0 ? (
                              <TextBox
                                as="button"
                                $textDeco="underline"
                                onClick={() => showPopupByType("UserPaymentPointList", item.date, dateFormat)}
                              >
                                {`${paymentAmount.toLocaleString()}P/${item.count_payment}${t("common.count")}`}
                              </TextBox>
                            ) : (
                              <TextBox>{`-`}</TextBox>
                            )}
                          </TableDeps>

                          <TableDeps $tAlign="right">
                            {levelUpAmount !== 0 ? (
                              <TextBox
                                as="button"
                                $textDeco="underline"
                                onClick={() => showPopupByType("UserLevelUpList", item.date, dateFormat)}
                              >
                                {`${levelUpAmount.toLocaleString()}P/${item.count_level_up_amount}${t("common.count")}`}
                              </TextBox>
                            ) : (
                              // <TextBox>{`-`}</TextBox>
                              <TextBox
                                as="button"
                                $textDeco="underline"
                                onClick={() => showPopupByType("UserLevelUpList", item.date, dateFormat)}
                              >
                                {`${levelUpAmount.toLocaleString()}P/${item.count_level_up_amount}${t("common.count")}`}
                              </TextBox>
                            )}
                          </TableDeps>
                        </TableRow>
                      );
                    })}
                </tbody>
              )}
            </Table>
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}

        <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%">
          <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
            {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
          </PositionBox>
          <SelectForm
            top="top"
            name="countPerPage"
            placeholder={useCountPerPage[0].label}
            {...useTG}
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useCountPerPage}
            formValue={useCountPerPage.value}
          />
        </Row>
      </Column>
    </>
  );
};

export default UserPointStatistics;
