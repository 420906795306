import React, { useEffect, useState } from "react";

// 공용
import api from "interceptor/api";

import useTableData from "common/useTableData";

import { usePopup } from "contexts/useWindow";
// style import
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { IconLink, LineSection } from "style/useLayoutStyles";
import { AreaTitle, SubText, TextBox } from "style/useTextStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { AreaHeader, Column, FilterBox, Row, RowLankAgent } from "style/custom/useCustomLayout";
import useFormData from "common/useFormData";
import { statesAgentEnum } from "enum/admin/useAdminMenuEnum";
import AgentForm from "./AgentForm";
import AgentInfoTab from "../info/AgentInfoTab";
import { BlockBtn, ModifyBtn } from "components/common/TableStatus";
import useStateData from "common/useStateData";
import { useCommonState } from "contexts/useCommonState";
import NoDataTable from "components/common/NoDataTable";
import AgentNameTab from "components/common/AgentNameTab";
import AgentSearchFilter from "components/common/AgentSearchFilter";
import AgentTypeFilter from "components/common/AgentTypeFilter";
import { t } from "i18next";

const AgentList = () => {
  const useStated = useStateData();

  const { openPopup } = usePopup();
  const [agentTypeList, setAgentTypeList] = useState([]);
  const { setLoading, isAdmin } = useCommonState();

  const showAgentFormPopup = (item, isSite) => {
    openPopup({
      title: t("agent.regis_sub_agent"),
      content: AgentForm,
      props: {
        item,
        isSite,
        refreshAgentAdmin,
      },
    });
  };

  const showAgentInfoPopup = (agentNo, code, tabNm) => {
    openPopup({
      title: `${t("agent.agent")} (${code})`,
      content: AgentInfoTab,
      props: {
        agentNo,
        tabNm,
        getTableItem,
      },
    });
  };

  useEffect(() => {
    getAgentTypeList();
  }, []);

  const getAgentTypeList = () => {
    const apiUrl = "/v1/agent/info/agent-types";
    api
      .get(apiUrl, {})
      .then((res) => {
        createAgentTypeEnum(res.data.content);
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const createAgentTypeEnum = (data) => {
    const newEnum = [];

    let allItem = {
      label: t("common.all"),
      value: "",
      key: 0,
    };
    newEnum.push(allItem);

    data.forEach((item) => {
      // 새로운 객체 생성
      let newItem = {
        label: item.name,
        value: item.type,
        key: item.depth,
      };

      // 변환된 객체를 새로운 형식의 객체에 추가
      newEnum.push(newItem);
    });

    // 새로운 형식의 객체 반환
    setAgentTypeList(newEnum);
  };

  const getTableItem = () => {
    setLoading(true);

    const apiUrl = "/v1/agent/info";
    api
      .get(apiUrl, {
        params: {
          ...useSearch.searchValue,
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        useTG.setError("");
      })
      .catch((err) => {
        useTG.setError(true);
        useTG.setData([]);

        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const refreshAgentAdmin = () => {
    getTableItem();
  };

  const agentAndSiteList = () => {
    getTableItem();
  };

  const useTG = useTableData(true);

  const useSearch = useFormData(
    {
      search_agent_category: "",
      search_agent_val: "",
      search_sub_agent_yn: "Y",
      search_agent_name: "",
      search_agent_type: "",
    },
    agentAndSiteList,
    useTG
  );

  const canInsertChildAgent = (type) => {
    if (!type) {
      return false;
    }
    const foundObject = agentTypeList.find((item) => item.value === type);

    if (!foundObject) {
      return false;
    }

    const foundChildObject = agentTypeList.find((item) => item.key === foundObject.key + 1);

    return !!foundChildObject;
  };

  const searchAgentList = () => {
    useSearch.formSearch();
  };

  return (
    <>
      <AreaHeader>
        <SubText>{t("menu.states_agent")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{statesAgentEnum.ALL_AGENT.title}</AreaTitle>
      </AreaHeader>
      <Column>
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
            <AgentSearchFilter useSearch={useSearch} useStated={useStated} />

            <AgentTypeFilter useSearch={useSearch} useStated={useStated} />
          </Row>

          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => searchAgentList()}>
            {t("filter.search")}
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
            {t("filter.refresh")}
            </WhiteBtn>
          </Row>
        </FilterBox>

        <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
          <Row $gap="10px" $align="center">
            <Row className="total-partner" $gap="4px">
              <TextBox $font="var(--f-subB)">{t("agent.total_agent_count")}</TextBox>

              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                {(useTG.data?.length || 0)}
              </TextBox>
              <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
            </Row>

            <LineSection $height="12px" $borL="1px solid var(--c-gray-300)"></LineSection>

            <Row className="total-money" $gap="4px">
              <TextBox $font="var(--f-subB)">{t("agent.agent_money")} (C)</TextBox>
              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                {useTG.data?.reduce((acc, curr) => acc + (curr.money || 0), 0).toLocaleString()}
              </TextBox>
              <TextBox $font="var(--f-subB)">{t("common.won")}</TextBox>
            </Row>

            <LineSection $height="12px" $borL="1px solid var(--c-gray-300)"></LineSection>

            <Row className="total-money" $gap="4px">
              <TextBox $font="var(--f-subB)">{t("agent.agent_point")} (P)</TextBox>
              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                {useTG.data?.reduce((acc, curr) => acc + (curr.point || 0), 0).toLocaleString()}
              </TextBox>
              <TextBox $font="var(--f-subB)">{t("common.won")}</TextBox>
            </Row>
          </Row>
        </Row>
        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  <TableHeader $width="60px">{t("common.no")}</TableHeader>
                  <TableHeader>{t("agent.code")}</TableHeader>
                  <TableHeader>{t("agent.agent")}</TableHeader>
                  <TableHeader>{t("agent.join_code")}</TableHeader>
                  <TableHeader>{t("agent.sub_agent_count")}</TableHeader>
                  <TableHeader>{t("agent.user_count")}</TableHeader>
                  <TableHeader>{t("agent.agent_money")} (C)</TableHeader>
                  <TableHeader>{t("agent.agent_point")} (P)</TableHeader>
                  {isAdmin && <TableHeader>{t("agent.manage")}</TableHeader>}
                </tr>
              </thead>
              <tbody>
                {useTG.data &&
                  useTG.data.map((item, index) => (
                    <TableRow key={item.no} className="table-content">
                      <TableDeps>{index +1}</TableDeps>
                      <TableDeps>
                        <RowLankAgent $pad={`0 0 0 ${(item.depth - 1) * 12}px`}>
                          {item.no !== 1 && <IconLink $url="/assets/svg/rowlank_agent.svg" $width="16px" $height="16px" />}
                          <TextBox $size="var(--s-table)" $line="var(--l-table)">
                            {item.code || "-"}
                          </TextBox>
                        </RowLankAgent>
                      </TableDeps>
                      <TableDeps $tAlign="left">
                        <AgentNameTab no={item.no} code={item.code} name={item.name} type_name={item.type_name} />
                      </TableDeps>
                      <TableDeps>{item.join_code}</TableDeps>
                      <TableDeps $tAlign="right">{(item?.children_agent_count || 0).toLocaleString()}</TableDeps>

                      <TableDeps $tAlign="right">{(item?.user_count || 0).toLocaleString()}</TableDeps>
                      <TableDeps $tAlign="right">{(item?.money || 0).toLocaleString()}</TableDeps>
                      <TableDeps $tAlign="right">{item.point ? item.point?.toLocaleString() : "-"}</TableDeps>

                      {isAdmin ? (
                        <TableDeps $maxWidth="90px">
                          <Row $width="100%" $jus="center" $gap="6px">
                            { item.no !== 1 && <ModifyBtn eventProps={() => showAgentInfoPopup(item.no, item.code)} />}
                            {canInsertChildAgent(item.type) && <BlockBtn name={t("agent.regis_sub")} eventProps={() => showAgentFormPopup(item.no, false)} /> }
                          </Row>
                        </TableDeps>
                      ) : null}
                    </TableRow>
                  ))}
              </tbody>
            </Table>
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}
      </Column>
    </>
  );
};

export default AgentList;
