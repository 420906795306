import React, { useEffect, useState } from "react";
import {
  InputForm,
  TextareaForm,
  SelectForm,
  ToggleButtonForm,
  EachCheckForm,
  EachCheckFormCustom,
  ImageForm,
  RadioForm,
} from "components/common/CommonForm";

import { FlexBox, HoverBox, HoverCon, LineSection } from "style/useLayoutStyles";
import { AreaTitle, SubText, TextBox, Label } from "style/useTextStyles";
import styled from "styled-components";
import useFormData from "common/useFormData";
import { MainBtn, MenuToggleBtn, WhiteBtn } from "style/useButtonStyles";
import { AreaHeader, Row, Column } from "style/custom/useCustomLayout";
import { IconLink } from "style/useLayoutStyles";
import api from "interceptor/api";
import { Table, TableHeader, TableRow, TableDeps } from "style/useTableStyles";
import { useCommonState } from "contexts/useCommonState";
import { useAlert, usePopup } from "contexts/useWindow";
import { dbSiteAccount, dbSiteConfig } from "enum/db/useSiteEnum";
import { checkboxYNEnum } from "enum/useCommonEnum";
import UserLvlUpPopup from "./UserLvlUpPopup";
import { ErrorMsgForm } from "utils/FormStateUtil";
import { isEmpty, isString } from "lodash";
import { t } from "i18next";

const SubTitleBox = styled(FlexBox).attrs({ className: "subtitle-box" })`
  padding: 10px 20px;
  background-color: var(--c-tableHead);
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const OptionBox = styled(FlexBox)`
  gap: 16px;
  padding: 14px 20px;
  min-height: 62px;
  border-bottom: 1px solid var(--c-gray-300);
`;

const SiteConfig = () => {
  const [initData, setInitData] = useState(); // 초기 데이터 저장
  const [bankMst, setBankMst] = useState([]); // 은행 목록
  const [dropdownState, setDropdownState] = useState({});

  const { loading, setLoading, setSiteName, setAlarmConfig } = useCommonState();
  const { openAlert } = useAlert();
  const { openPopup } = usePopup();
  const useSearch = useFormData(null);

  const handleDropdownToggle = (state) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [state]: !prevState[state],
    }));
  };

  const openAllDropdowns = () => {
    setDropdownState({
      1: true,
      2: true,
      3: true,
      4: true,
      5: true,
      6: true,
      7: true,
      8: true,
      9: true,
      10: true,
      11: true,
      12: true,
    });
  };

  const closeAllDropdowns = () => {
    setDropdownState({
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false,
      8: false,
      9: false,
      10: false,
      11: false,
      12: false,
    });
  };

  const showFailAlert = (errArr) => {
    openAlert({
      title: t("common.save_fail"),
      message:
        errArr === null || errArr === undefined ? null : (
          <Column>
            {errArr.map((err, index) => (
              <ErrorMsgForm errorJson={err} key={index} />
            ))}
          </Column>
        ),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const showPopup = (item, index) => {
    openPopup({
      title: t("siteConfig.levelup_message_title"),
      content: UserLvlUpPopup,
      props: {
        item,
        level: index,
        formData: useSearch,
      },
    });
  };

  const getSiteConfig = () => {
    const apiUrl = "v1/site/config/1";
    api
      .get(apiUrl)
      .then((res) => {
        let agent_max_level = 0;
        res.data.content.site_agent_type_config.map((item) => {
          if (item.use_yn === "Y") {
            agent_max_level++;
          } else {
            return null;
          }
          return null;
        });
        if (agent_max_level === 0) agent_max_level = 10; // 기본값 10 으로 설정
        const updatedSiteAgentTypeConfig = res.data.content.site_agent_type_config.map((item) => {
          if (item.agent_commission_yn === "N") {
            return { ...item, agent_commission_type: "N" };
          }
          return item;
        });
        useSearch.setFormValue({
          ...res.data.content,
          agent_max_level: agent_max_level,
          site_agent_type_config: updatedSiteAgentTypeConfig,
          inspec_notice_image: [
            {
              img_data: "",
              img_uuid: res.data.content.inspec_notice_image_uuid || "",
              img_size: "",
              img_exec: "",
              img_name: "",
            },
          ],
          inspec_popup_image: [
            {
              img_data: "",
              img_uuid: res.data.content.inspec_popup_image_uuid ?? "",
              img_size: "",
              img_exec: "",
              img_name: "",
            },
          ],
          site_main_account:
            res.data.content.site_multi_account_yn === "N"
              ? !isEmpty(res.data.content?.site_account)
                ? [res.data.content?.site_account[0]]
                : [
                  {
                    account_no: "",
                    account_name: "",
                    account_bank: "",
                  },
                ]
              : [{ account_no: "", account_name: "", account_bank: "" }],
        });
        setInitData({
          ...res.data.content,
          agent_max_level: agent_max_level,
          site_agent_type_config: updatedSiteAgentTypeConfig,
          inspec_notice_image: {
            img_data: "",
            img_uuid: res.data.content.inspec_notice_image_uuid || "",
            img_size: "",
            img_exec: "",
            img_name: "",
          },
          inspec_popup_image: {
            img_data: "",
            img_uuid: res.data.content.inspec_popup_image_uuid || "",
            img_size: "",
            img_exec: "",
            img_name: "",
          },
          site_main_account:
            res.data.content.site_multi_account_yn === "N"
              ? !isEmpty(res.data.content?.site_account)
                ? [res.data.content?.site_account[0]]
                : [
                  {
                    account_no: "",
                    account_name: "",
                    account_bank: "",
                  },
                ]
              : [{ account_no: "", account_name: "", account_bank: "" }],
        }); // 초기 데이터 저장
        setBankMst(res.data.content.bank_mst.map((item) => ({ label: item.bank_name, value: item.bank_name })));
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const showSuccessAlert = () => {
    openAlert({
      title: t("common.save_msg"),
      iconURL: false,
      mainBtn: t("common.check"),
    });
  };

  const putSiteConfig = (body) => {
    const apiUrl = "v1/site/config/1";
    api
      .put(apiUrl, body)
      .then((res) => {
        getSiteConfig();
        setSiteName(body.name);
        setAlarmConfig({
          alarm_in_type: body.alarm_in_type,
          alarm_out_type: body.alarm_out_type,
          alarm_join_type: body.alarm_join_type,
          alarm_qna_type: body.alarm_qna_type,
          alarm_in_delay_time: body.alarm_in_delay_time,
          alarm_out_delay_time: body.alarm_out_delay_time,
          alarm_join_delay_time: body.alarm_join_delay_time,
          alarm_qna_delay_time: body.alarm_qna_delay_time,
        });
        showSuccessAlert();
      })
      .catch((err) => {
        console.error(err);
        showFailAlert(err.response?.data?.errors);
      });
  };

  /**
   * 사이트 정보 저장하기 (전체적 체크 후 저장)
   */
  const saveConfig = (errorCheck = false) => {
    if (errorCheck) {
      // 콤프사용이 '사용 안함'이 아닐 때 (agent_commission_type != 'T'), 0이면 다이얼로그
      let ErrorArray = []
      useSearch.formValue.site_agent_type_config.map((data, index) => {
        if (data.agent_commission_type != 'N' && index !== 0) {
          // 에이전트 레벨을 넘기면 0 체크 무시
          if (useSearch.formValue.agent_max_level >= index + 1) {
            // 값 체크
            if (data.casino_losing_rate == 0 || data.casino_rolling_rate == 0 || data.mini_game_losing_rate == 0 ||
              data.mini_game_rolling_rate == 0 || data.slot_losing_rate == 0 || data.slot_rolling_rate == 0 ||
              data.sports_losing_rate == 0 || data.sports_rolling_rate == 0) {
              ErrorArray.push(index + 1)
            }
          }
        }
      })
      // 0이 있으면 다이얼로그 처리
      if (ErrorArray.length) {
        showFailAlert(ErrorArray)

        return openAlert({
          title: t("common.save_fail"),
          message:
            ErrorArray === null || ErrorArray === undefined ? null : (
              <Column>
                {`${t("siteConfig.use_level_by_settings")} ${ErrorArray.join(', ')} ${t("common.level")}  ${t("common.check")}`}
              </Column>
            ),
          iconURL: true,
          mainBtn: t("common.check"),
        });
      }
    }
    putSiteConfig(useSearch.formValue);
  };

  useEffect(() => {
    getSiteConfig();
  }, []);

  useEffect(() => {
    if (!useSearch.formValue || useSearch.formValue?.agent_max_level === null) return;
    useSearch.setFormValue((prev) => {
      const updatedConfig = prev.site_agent_type_config?.map((item, index) => {
        // agentMaxLevel까지의 항목은 'Y'로 변경하고, 그 이후 항목은 'N'로 변경합니다.
        if (index <= useSearch.formValue?.agent_max_level - 1) {
          return { ...item, use_yn: "Y" };
        } else {
          return { ...item, use_yn: "N" };
        }
      });

      return {
        ...prev,
        site_agent_type_config: updatedConfig,
      };
    });
  }, [useSearch.formValue?.agent_max_level]);

  useEffect(() => {
    if (!useSearch.formValue || useSearch.formValue?.site_max_account === null) return;

    if (useSearch.formValue.site_max_account > useSearch.formValue.site_account.length) {
      const newSiteAccount = [];
      for (let i = 0; i < useSearch.formValue.site_max_account - useSearch.formValue.site_account.length; i++) {
        newSiteAccount.push({
          site_no: 1,
          account_yn: null,
          account_bank: null,
          account_name: null,
          account_no: null,
          user_state1: null,
        });
      }
      useSearch.setFormValue((prev) => ({
        ...prev,
        site_account: [...useSearch.formValue.site_account, ...newSiteAccount],
      }));
    } else {
      useSearch.setFormValue((prev) => ({
        ...prev,
        site_account: useSearch.formValue.site_account.slice(0, useSearch.formValue.site_max_account),
      }));
    }
  }, [useSearch.formValue?.site_max_account]);

  useEffect(() => {
    if (!useSearch.formValue || useSearch.formValue?.user_max_level === null) return;

    if (useSearch.formValue.user_max_level + 1 > useSearch.formValue.site_level_mng.length) {
      const newSiteLevelMng = [];

      for (let i = 0; i < useSearch.formValue.user_max_level + 1 - useSearch.formValue.site_level_mng.length; i++) {
        newSiteLevelMng.push({
          site_no: 1,
          level: useSearch.formValue.site_level_mng.length + i,
          level_icon_file_uuid: "",
          level_name: "",
          account_in_count: 0,
          account_out_prop: 0,
          total_account_in: 0,
          total_betting: 0,
          profit_amount: 0,
          levelup_bonus: 0,
          levelup_msg: null,
          user_casino_rolling_prop: 0,
          user_slot_rolling_prop: 0,
          user_mini_game_rolling_prop: 0,
          user_sports_rolling_prop: 0,
          recommend_casino_rolling_prop: 0,
          recommend_slot_rolling_prop: 0,
          recommend_mini_game_rolling_prop: 0,
          recommend_sports_rolling_prop: 0,
          user_casino_losing_prop: 0,
          user_slot_losing_prop: 0,
          user_mini_game_losing_prop: 0,
          user_sports_losing_prop: 0,
          recommend_casino_losing_prop: 0,
          recommend_slot_losing_prop: 0,
          recommend_mini_game_losing_prop: 0,
          recommend_sports_losing_prop: 0,
        });
      }
      useSearch.setFormValue((prev) => ({
        ...prev,
        site_level_mng: [...useSearch.formValue.site_level_mng, ...newSiteLevelMng],
      }));
    } else {
      useSearch.setFormValue((prev) => ({
        ...prev,
        site_level_mng: useSearch.formValue.site_level_mng.slice(0, useSearch.formValue.user_max_level + 1),
      }));
    }
  }, [useSearch.formValue?.user_max_level]);

  if (loading || !initData) return;

  return (
    <>
      <AreaHeader>
        <SubText>{t("menu.site_config_manager")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{t("menu.site_config")}</AreaTitle>
        <Row $gap="8px" style={{ marginLeft: "auto" }}>
          {/* 메뉴 모두 펼치기 */}
          <MenuToggleBtn
            $bcolor="var(--c-gray-300)"
            $size="var(--s-table)"
            $font="var(--f-table)"
            $height="28px"
            $line="20px"
            onClick={openAllDropdowns}
          >
            {t("common.unfold_all_menu")}
          </MenuToggleBtn>
          {/* 메뉴 모두 닫기 */}
          <MenuToggleBtn
            $bcolor="var(--c-gray-300)"
            $size="var(--s-table)"
            $font="var(--f-table)"
            $height="28px"
            onClick={closeAllDropdowns}
          >
            {t("common.fold_all_menu")}
          </MenuToggleBtn>
        </Row>
      </AreaHeader>
      <Column $gap="64px">
        {/* 1. 기본 설정 */}
        <Column className="area-box">
          <SubTitleBox onClick={() => handleDropdownToggle(1)}>
            <Row $gap="6px" $align="center">
              <AreaTitle>1. {t("siteConfig.basic_settings")}</AreaTitle>
              <HoverCon
                onClick={(e) => {
                  e.stopPropagation(); //탭이 접히지 않게
                }}
              >
                <IconLink className="h-a-box" $url="/assets/svg/help.svg" $width="20px" $height="20px"></IconLink>
                <HoverBox className="h-box">
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("siteConfig.basic_settings_info1")}
                  </TextBox>
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("siteConfig.basic_settings_info2")}
                  </TextBox>
                </HoverBox>
              </HoverCon>
            </Row>
            <Row $gap="20px">
              <MainBtn
                $pad="6px 12px"
                onClick={(e) => {
                  e.stopPropagation(); //탭이 접히지 않게
                  saveConfig();
                }}
              >
                {t("common.go_save")}
              </MainBtn>
              <IconLink
                $url="/assets/svg/site_config_menu_fold.svg"
                $width="32px"
                $height="32px"
                $bor="1px solid var(--c-gray-500)"
                $trans="rotate(180deg)"
                className={dropdownState[1] && "active"}
              ></IconLink>
            </Row>
          </SubTitleBox>
          {dropdownState[1] && (
            <>
              <Row>
                <OptionBox $flex="1" $align="center">
                  <Row $width="160px" $minWidth="160px">
                    <TextBox $tAlign="center" $line="var(--l-sub)">
                      {t("siteConfig.site_name")}
                    </TextBox>
                  </Row>
                  <InputForm width="160px" type="text" name="name" placeholder={t("common.input")} {...useSearch} />
                </OptionBox>
                <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
                <OptionBox $flex="1" $align="center">
                  <Row $width="160px" $minWidth="160px">
                    <TextBox $width="160px" $minWidth="160px" $line="var(--l-sub)">
                      {t("siteConfig.site_code")}
                    </TextBox>
                  </Row>
                  <InputForm width="160px" type="text" name="code" placeholder={t("common.input")} {...useSearch} />
                </OptionBox>
              </Row>
              <OptionBox $align="center">
                <Row $width="160px" $minWidth="160px">
                  <TextBox $line="var(--l-sub)">{t("siteConfig.auto_logout")}</TextBox>
                </Row>
                <Row $gap="12px" $align="center">
                  <TextBox>{t("siteConfig.auto_logout_info1")}</TextBox>
                  <SelectForm name="auto_logout" placeholder="10분" options={dbSiteConfig.auto_logout} {...useSearch} />
                  <TextBox>{t("siteConfig.auto_logout_info2")}</TextBox>
                </Row>
              </OptionBox>
            </>
          )}
        </Column>

        {/* 2. 예약 점검 설정 */}
        <Column className="area-box">
          <SubTitleBox onClick={() => handleDropdownToggle(2)}>
            <Row $gap="6px" $align="center">
              <AreaTitle>2. {t("siteConfig.plan_inspec_settings")}</AreaTitle>
              <HoverCon
                onClick={(e) => {
                  e.stopPropagation(); //탭이 접히지 않게
                }}
              >
                <IconLink className="h-a-box" $url="/assets/svg/help.svg" $width="20px" $height="20px"></IconLink>
                <HoverBox className="h-box">
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("siteConfig.plan_inspec_info1")}
                  </TextBox>
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("siteConfig.plan_inspec_info2")}
                  </TextBox>
                </HoverBox>
              </HoverCon>
            </Row>
            <Row $gap="20px">
              <MainBtn
                $pad="6px 12px"
                onClick={(e) => {
                  e.stopPropagation(); // 저장하기 누를땐 탭이 접히지 않게
                  saveConfig();
                }}
              >
                {t("common.go_save")}
              </MainBtn>
              <IconLink
                $url="/assets/svg/site_config_menu_fold.svg"
                $width="32px"
                $height="32px"
                $bor="1px solid var(--c-gray-500)"
                $trans="rotate(180deg)"
                className={dropdownState[2] && "active"}
              ></IconLink>
            </Row>
          </SubTitleBox>
          {dropdownState[2] && (
            <>
              <OptionBox $align="center">
                <Row $width="160px" $minWidth="160px">
                  <TextBox $line="var(--l-sub)">{t("siteConfig.plan_inspec_settings")}</TextBox>
                </Row>
                <Row>
                  <ToggleButtonForm name="plan_inspec_yn" options={dbSiteConfig.plan_inspec_yn} {...useSearch} />
                </Row>
              </OptionBox>
              {useSearch.formValue?.plan_inspec_yn === dbSiteConfig.plan_inspec_yn.Y.value && (
                <>
                  <OptionBox $backColor="var(--c-gray-100)" $align="center">
                    <Row $width="160px" $minWidth="160px">
                      <TextBox $line="var(--l-sub)">{t("siteConfig.inspection_time")}</TextBox>
                    </Row>
                    <Row $gap="24px" $align="center">
                      <Row $gap="12px" $align="center">
                        <Row>
                          <EachCheckFormCustom
                            labelFor="plan-all-days"
                            name="plan_inspec_week"
                            index={0}
                            options={checkboxYNEnum}
                            {...useSearch}
                          />
                          <Label htmlFor="plan-all-days">{t("days.every_day")}</Label>
                        </Row>
                        <Row $gap="4px" $align="center">
                          <EachCheckFormCustom
                            labelFor="plan-sun"
                            name="plan_inspec_week"
                            index={1}
                            options={checkboxYNEnum}
                            {...useSearch}
                          />
                          <Label htmlFor="plan-sun">{t("days.sunday")}</Label>
                        </Row>
                        <Row $gap="4px" $align="center">
                          <EachCheckFormCustom
                            labelFor="plan-mon"
                            name="plan_inspec_week"
                            index={2}
                            options={checkboxYNEnum}
                            {...useSearch}
                          />
                          <Label htmlFor="plan-mon">{t("days.monday")}</Label>
                        </Row>
                        <Row $gap="4px" $align="center">
                          <EachCheckFormCustom
                            labelFor="plan-tue"
                            name="plan_inspec_week"
                            index={3}
                            options={checkboxYNEnum}
                            {...useSearch}
                          />
                          <Label htmlFor="plan-tue">{t("days.tuesday")}</Label>
                        </Row>
                        <Row $gap="4px" $align="center">
                          <EachCheckFormCustom
                            labelFor="plan-wen"
                            name="plan_inspec_week"
                            index={4}
                            options={checkboxYNEnum}
                            {...useSearch}
                          />
                          <Label htmlFor="plan-wen">{t("days.wednesday")}</Label>
                        </Row>
                        <Row $gap="4px" $align="center">
                          <EachCheckFormCustom
                            labelFor="plan-thur"
                            name="plan_inspec_week"
                            index={5}
                            options={checkboxYNEnum}
                            {...useSearch}
                          />
                          <Label htmlFor="plan-thur">{t("days.thursday")}</Label>
                        </Row>
                        <Row $gap="4px" $align="center">
                          <EachCheckFormCustom
                            labelFor="plan-fri"
                            name="plan_inspec_week"
                            index={6}
                            options={checkboxYNEnum}
                            {...useSearch}
                          />
                          <Label htmlFor="plan-fri">{t("days.friday")}</Label>
                        </Row>
                        <Row $gap="4px" $align="center">
                          <EachCheckFormCustom
                            labelFor="plan-sat"
                            name="plan_inspec_week"
                            index={7}
                            options={checkboxYNEnum}
                            {...useSearch}
                          />
                          <Label htmlFor="plan-sat">{t("days.saturday")}</Label>
                        </Row>
                      </Row>
                    </Row>
                    <Row $gap="4px" $align="center">
                      <InputForm
                        width="120px"
                        type="text"
                        name="plan_inspec_start"
                        placeholder="23:59:59"
                        maskType="time"
                        {...useSearch}
                      />
                      <TextBox>~</TextBox>
                      <InputForm
                        width="120px"
                        type="text"
                        name="plan_inspec_end"
                        placeholder="23:59:59"
                        maskType="time"
                        {...useSearch}
                      />
                    </Row>
                  </OptionBox>
                  <SubTitleBox $backColor="var(--c-gray-100)">
                    <Row $width="160px" $minWidth="160px">
                      <AreaTitle>{t("siteConfig.inspection_message")}</AreaTitle>
                    </Row>
                  </SubTitleBox>
                  <OptionBox $align="center">
                    <Row $width="160px" $minWidth="160px">
                      <TextBox $pad="4px 0" $line="var(--l-sub)">
                        {t("siteConfig.notice_board")}
                      </TextBox>
                    </Row>
                    <Row>
                      <ToggleButtonForm index={0} name="plan_inspec_view_type" options={planInspectMessageEnum} {...useSearch} />
                    </Row>
                  </OptionBox>
                  {isString(useSearch.formValue?.plan_inspec_view_type) &&
                    useSearch.formValue?.plan_inspec_view_type.length > 2 &&
                    useSearch.formValue?.plan_inspec_view_type[0] === dbSiteConfig.plan_inspec_yn.Y.value && (
                      <>
                        <OptionBox $flex="1" $align="center" $backColor="var(--c-gray-100)">
                          <Row $width="160px" $minWidth="160px">
                            <TextBox $line="var(--l-sub)">{t("siteConfig.title")}</TextBox>
                          </Row>
                          <InputForm
                            width="100%"
                            maxWidth="100%"
                            type="text"
                            name="inspec_notice_title"
                            placeholder={t("common.input")}
                            {...useSearch}
                          />
                        </OptionBox>
                        <OptionBox $flex="1" $align="center" $backColor="var(--c-gray-100)">
                          <Row $width="160px" $minWidth="160px">
                            <TextBox $line="var(--l-sub)">{t("siteConfig.content")}</TextBox>
                          </Row>
                          <TextareaForm
                            width="100%"
                            name="inspec_notice_contents"
                            placeholder={t("siteConfig.enter_content")}
                            {...useSearch}
                          />
                        </OptionBox>
                        <OptionBox $flex="1" $align="center" $backColor="var(--c-gray-100)">
                          <Row $width="160px" $minWidth="160px">
                            <TextBox $line="var(--l-sub)">{t("siteConfig.attachment")}</TextBox>
                          </Row>
                          <ImageForm index={0} groupNm="inspec_notice_image" name="img_uuid" {...useSearch} />
                        </OptionBox>
                      </>
                    )}
                  <OptionBox $align="center">
                    <Row $width="160px" $minWidth="160px">
                      <TextBox $line="var(--l-sub)">{t("siteConfig.popup_banner")}</TextBox>
                    </Row>
                    <Row>
                      <ToggleButtonForm index={1} name="plan_inspec_view_type" options={planInspectMessageEnum} {...useSearch} />
                    </Row>
                  </OptionBox>
                  {isString(useSearch.formValue?.plan_inspec_view_type) &&
                    useSearch.formValue?.plan_inspec_view_type.length > 2 &&
                    useSearch.formValue?.plan_inspec_view_type[1] === dbSiteConfig.plan_inspec_yn.Y.value && (
                      <>
                        <OptionBox $align="center" $backColor="var(--c-gray-100)">
                          <Row $width="160px" $minWidth="160px">
                            <TextBox $pad="4px 0" $line="var(--l-sub)">
                              {t("siteConfig.show_before_login")}
                            </TextBox>
                          </Row>
                          <Row>
                            <ToggleButtonForm name="inspec_popup_expo_yn" options={dbSiteConfig.plan_inspec_yn} {...useSearch} />
                          </Row>
                        </OptionBox>
                        <OptionBox $flex="1" $backColor="var(--c-gray-100)">
                          <Row $width="160px" $minWidth="160px">
                            <TextBox $pad="4px 0" $line="var(--l-sub)">
                              {t("siteConfig.content")}
                            </TextBox>
                          </Row>
                          <TextareaForm
                            width="100%"
                            name="inspec_popup_contents"
                            placeholder={t("siteConfig.enter_content")}
                            {...useSearch}
                          />
                        </OptionBox>
                        <OptionBox $flex="1" $align="center" $backColor="var(--c-gray-100)">
                          <Row $width="160px" $minWidth="160px">
                            <TextBox $line="var(--l-sub)">{t("siteConfig.attachment")}</TextBox>
                          </Row>
                          <Column $gap="4px">
                            <ImageForm index={0} groupNm="inspec_popup_image" name="img_uuid" {...useSearch} />{" "}
                            <TextBox $size="var(--s-caption)" $line="var(--l-caption)" $color="var(--c-gray-700)">
                              {t("popup.img_msg")}
                            </TextBox>
                          </Column>
                        </OptionBox>
                        <OptionBox $flex="1" $align="center" $backColor="var(--c-gray-100)">
                          <Row $width="160px" $minWidth="160px">
                            <TextBox $line="var(--l-sub)">{t("siteConfig.popup_position")}</TextBox>
                          </Row>
                          <RadioForm
                            options={dbSiteConfig.popup_location_type}
                            name="popup_location_type"
                            placeholder={t("siteConfig.enter_content")}
                            {...useSearch}
                          />
                        </OptionBox>
                        {/* 
                        팝업 크기 높이 부분 주석처리
                        <Row>
                          <OptionBox $flex="1" $align="center" $backColor="var(--c-gray-100)">
                            <Row $width="160px" $minWidth="160px">
                              <TextBox $tAlign="center" $line="var(--l-sub)">
                                {t("siteConfig.popup_width")}
                              </TextBox>
                            </Row>
                            <InputForm name="popup_width" placeholder={t("siteConfig.popup_width")} {...useSearch} />
                          </OptionBox>
                          <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
                          <OptionBox $flex="1" $align="center" $backColor="var(--c-gray-100)">
                            <Row $width="160px" $minWidth="160px">
                              <TextBox $width="160px" $minWidth="160px" $line="var(--l-sub)">
                                {t("siteConfig.popup_height")}
                              </TextBox>
                            </Row>
                            <InputForm name="popup_height" placeholder={t("siteConfig.popup_height")} {...useSearch} />
                          </OptionBox>
                        </Row> */}
                        {useSearch.formValue?.popup_location_type === dbSiteConfig.popup_location_type.F.value && (
                          <Row>
                            <OptionBox $flex="1" $align="center" $backColor="var(--c-gray-100)">
                              <Row $width="160px" $minWidth="160px">
                                <TextBox $tAlign="center" $line="var(--l-sub)">
                                  {t("siteConfig.popup_top_position")}
                                </TextBox>
                              </Row>
                              <InputForm name="popup_top" placeholder={t("siteConfig.popup_top_position")} {...useSearch} />
                            </OptionBox>
                            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
                            <OptionBox $flex="1" $align="center" $backColor="var(--c-gray-100)">
                              <Row $width="160px" $minWidth="160px">
                                <TextBox $width="160px" $minWidth="160px" $line="var(--l-sub)">
                                  {t("siteConfig.popup_left_position")}
                                </TextBox>
                              </Row>
                              <InputForm name="popup_left" placeholder={t("siteConfig.popup_left_position")} {...useSearch} />
                            </OptionBox>
                          </Row>
                        )}
                      </>
                    )}
                  <OptionBox $align="center">
                    <Row $width="160px" $minWidth="160px">
                      <TextBox $line="var(--l-sub)">{t("siteConfig.main_bottom_scroll")}</TextBox>
                    </Row>
                    <Row>
                      <ToggleButtonForm index={2} name="plan_inspec_view_type" options={planInspectMessageEnum} {...useSearch} />
                    </Row>
                  </OptionBox>
                  {isString(useSearch.formValue?.plan_inspec_view_type) &&
                    useSearch.formValue?.plan_inspec_view_type.length > 2 &&
                    useSearch.formValue?.plan_inspec_view_type[2] === checkboxYNEnum.Y.value && (
                      <OptionBox $flex="1" $backColor="var(--c-gray-100)">
                        <Row $width="160px" $minWidth="160px">
                          <TextBox $pad="4px 0" $line="var(--l-sub)">
                            {t("siteConfig.content")}
                          </TextBox>
                        </Row>
                        <TextareaForm
                          width="100%"
                          name="inspec_main_scroll_contents"
                          placeholder={t("siteConfig.enter_content")}
                          {...useSearch}
                        />
                      </OptionBox>
                    )}
                </>
              )}
            </>
          )}
        </Column>

        {/* 3. 긴급 점검 설정 */}
        <Column className="area-box">
          <SubTitleBox onClick={() => handleDropdownToggle(3)}>
            <Row $gap="6px" $align="center">
              <AreaTitle>3. {t("siteConfig.urgent_inspec_settings")}</AreaTitle>
              <HoverCon
                onClick={(e) => {
                  e.stopPropagation(); //탭이 접히지 않게
                }}
              >
                <IconLink className="h-a-box" $url="/assets/svg/help.svg" $width="20px" $height="20px"></IconLink>
                <HoverBox className="h-box">
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("siteConfig.urgent_inspec_info1")}
                  </TextBox>
                </HoverBox>
              </HoverCon>
            </Row>
            <Row $gap="20px">
              <MainBtn
                $pad="6px 12px"
                onClick={(e) => {
                  e.stopPropagation(); // 저장하기 누를땐 탭이 접히지 않게
                  saveConfig();
                }}
              >
                {t("common.go_save")}
              </MainBtn>
              <IconLink
                $url="/assets/svg/site_config_menu_fold.svg"
                $width="32px"
                $height="32px"
                $bor="1px solid var(--c-gray-500)"
                $trans="rotate(180deg)"
                className={dropdownState[3] && "active"}
              ></IconLink>
            </Row>
          </SubTitleBox>
          {dropdownState[3] && (
            <>
              <OptionBox>
                <Row $width="160px" $minWidth="160px" $align="center">
                  <TextBox $line="var(--l-sub)">{t("siteConfig.urgent_inspection")}</TextBox>
                </Row>
                <ToggleButtonForm name="inspec_yn" options={dbSiteConfig.inspec_yn} {...useSearch} />
              </OptionBox>
              {useSearch.formValue?.inspec_yn === dbSiteConfig.inspec_yn.Y.value && (
                <OptionBox $backColor="var(--c-gray-100)">
                  <Row $width="160px" $minWidth="160px">
                    <TextBox $pad="4px 0" $line="var(--l-sub)">
                      {t("siteConfig.inspection_message")}
                    </TextBox>
                  </Row>
                  <TextareaForm name="inspec_memo" placeholder={t("siteConfig.enter_content")} {...useSearch} />
                </OptionBox>
              )}
            </>
          )}
        </Column>

        {/* 4. 회원 레벨 설정 */}
        <Column className="area-box">
          <SubTitleBox onClick={() => handleDropdownToggle(4)}>
            <Row $gap="6px" $align="center">
              <AreaTitle>4. {t("siteConfig.member_level_settings")}</AreaTitle>
              <HoverCon
                onClick={(e) => {
                  e.stopPropagation(); //탭이 접히지 않게
                }}
              >
                <IconLink className="h-a-box" $url="/assets/svg/help.svg" $width="20px" $height="20px"></IconLink>
                <HoverBox className="h-box">
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("siteConfig.member_level_info")}
                  </TextBox>
                </HoverBox>
              </HoverCon>
            </Row>
            <Row $gap="20px">
              <MainBtn
                $pad="6px 12px"
                onClick={(e) => {
                  e.stopPropagation(); // 저장하기 누를땐 탭이 접히지 않게
                  saveConfig();
                }}
              >
                {t("common.go_save")}
              </MainBtn>
              <IconLink
                $url="/assets/svg/site_config_menu_fold.svg"
                $width="32px"
                $height="32px"
                $bor="1px solid var(--c-gray-500)"
                $trans="rotate(180deg)"
                className={dropdownState[4] && "active"}
              ></IconLink>
            </Row>
          </SubTitleBox>
          {dropdownState[4] && (
            <>
              <OptionBox $align="center">
                <Row $width="160px" $minWidth="160px">
                  <TextBox $line="var(--l-sub)">{t("siteConfig.use_member_level")}</TextBox>
                </Row>
                <ToggleButtonForm name="user_level_yn" options={dbSiteConfig.user_level_yn} {...useSearch} />
              </OptionBox>
              {useSearch.formValue?.user_level_yn === dbSiteConfig.user_level_yn.Y.value && (
                <>
                  <OptionBox $backColor="var(--c-gray-100)">
                    <Row $width="160px" $minWidth="160px">
                      <TextBox $pad="4px 0" $line="var(--l-sub)">
                        {t("siteConfig.use_member_level")}
                      </TextBox>
                    </Row>
                    <Column $gap="4px">
                      <SelectForm name="user_max_level" options={dbSiteConfig.user_max_level} {...useSearch} />
                      <TextBox $size="var(--s-caption)" $line="var(--l-caption)" $color="var(--c-gray-700)">
                        {t("siteConfig.max_member_level_info")}
                      </TextBox>
                    </Column>
                  </OptionBox>
                  <OptionBox $backColor="var(--c-gray-100)">
                    <Row $width="160px" $minWidth="160px" $align="center">
                      <TextBox $line="var(--l-sub)">{t("siteConfig.auto_levelup")}</TextBox>
                    </Row>
                    <ToggleButtonForm name="user_auto_levelup_yn" options={dbSiteConfig.user_auto_level_yn} {...useSearch} />
                  </OptionBox>
                  {useSearch.formValue?.user_auto_levelup_yn === dbSiteConfig.user_auto_level_yn.Y.value && (
                    <>
                      <OptionBox $backColor="var(--c-gray-100)">
                        <Row $width="160px" $minWidth="160px">
                          <TextBox $pad="4px 0" $line="var(--l-sub)">
                            {t("siteConfig.levelup_conditions")}
                          </TextBox>
                        </Row>
                        <Column $gap="12px" $width="100%">
                          <Row $align="center" $gap="12px">
                            <Row $gap="4px" $align="center">
                              <EachCheckFormCustom
                                labelFor="user-0"
                                name="user_auto_levelup_condition"
                                options={checkboxYNEnum}
                                index={0}
                                {...useSearch}
                              />{" "}
                              <Label htmlFor="user-0">{t("siteConfig.all_conditions_met")}</Label>
                            </Row>
                            <Row $gap="4px" $align="center">
                              <EachCheckFormCustom
                                labelFor="user-1"
                                name="user_auto_levelup_condition"
                                options={checkboxYNEnum}
                                index={1}
                                {...useSearch}
                              />{" "}
                              <Label htmlFor="user-1">{t("siteConfig.deposit_count_label")}</Label>
                            </Row>
                            <Row $gap="4px" $align="center">
                              <EachCheckFormCustom
                                labelFor="user-3"
                                name="user_auto_levelup_condition"
                                options={checkboxYNEnum}
                                index={3}
                                {...useSearch}
                              />{" "}
                              <Label htmlFor="user-3">{t("siteConfig.total_deposit_label")}</Label>
                            </Row>
                            <Row $gap="4px" $align="center">
                              <EachCheckFormCustom
                                labelFor="user-4"
                                name="user_auto_levelup_condition"
                                options={checkboxYNEnum}
                                index={4}
                                {...useSearch}
                              />{" "}
                              <Label htmlFor="user-4">{t("siteConfig.total_betting_label")}</Label>
                            </Row>
                            <Row $gap="4px" $align="center">
                              <EachCheckFormCustom
                                labelFor="user-5"
                                name="user_auto_levelup_condition"
                                options={checkboxYNEnum}
                                index={5}
                                {...useSearch}
                              />{" "}
                              <Label htmlFor="user-5">{t("siteConfig.profit_amount_label")}</Label>
                            </Row>
                          </Row>
                          <Table className="table-box">
                            <thead>
                              <TableRow className="table-title">
                                <TableHeader $width="56px">{t("siteConfig.level")}</TableHeader>
                                <TableHeader $width="132px">{t("siteConfig.level_name")}</TableHeader>
                                <TableHeader $width="140px">{t("siteConfig.level_icon")}</TableHeader>
                                <TableHeader $width="133px">{t("siteConfig.deposit_count_label")}</TableHeader>
                                <TableHeader $width="133px">{t("siteConfig.total_deposit_label")}</TableHeader>
                                <TableHeader $width="133px">{t("siteConfig.total_betting_label")}</TableHeader>
                                <TableHeader $width="133px">{t("siteConfig.profit_amount_label")}</TableHeader>
                                <TableHeader $width="133px">{t("siteConfig.levelup_bonus")}</TableHeader>
                                <TableHeader $width="92px">{t("siteConfig.levelup_message")}</TableHeader>
                              </TableRow>
                            </thead>
                            <tbody>
                              {useSearch.formValue?.site_level_mng.map((item, index) => (
                                <TableRow key={index} className="table-content">
                                  <TableDeps $height="42px">{index}</TableDeps>
                                  <TableDeps $height="42px">
                                    <InputForm
                                      maxWidth="100px"
                                      type="text"
                                      name="level_name"
                                      groupNm="site_level_mng"
                                      index={index}
                                      height={"28px"}
                                      placeholder={t("siteConfig.level_name")}
                                      {...useSearch}
                                    />
                                  </TableDeps>
                                  {/* 레벨 아이콘 */}
                                  <TableDeps $height="42px">
                                    <ImageForm
                                      name="level_icon_file_uuid"
                                      groupNm="site_level_mng"
                                      index={index}
                                      height={"28px"}
                                      {...useSearch}
                                    />
                                  </TableDeps>
                                  {/* 입금 횟수 */}
                                  <TableDeps $height="42px">
                                    <InputForm
                                      maxWidth="100px"
                                      type="text"
                                      name="account_in_count"
                                      groupNm="site_level_mng"
                                      index={index}
                                      placeholder="0"
                                      height={"28px"}
                                      disabled={index == 0 ? true : false}
                                      tAlign="right"
                                      {...useSearch}
                                    />
                                    <Label>{t("siteConfig.times_or_more")}</Label>
                                  </TableDeps>
                                  {/* 누적 입금액 */}
                                  <TableDeps $height="42px">
                                    <InputForm
                                      maxWidth="100px"
                                      type="text"
                                      name="total_account_in"
                                      groupNm="site_level_mng"
                                      index={index}
                                      placeholder="0"
                                      height={"28px"}
                                      tAlign="right"
                                      disabled={index == 0 ? true : false}
                                      {...useSearch}
                                    />
                                    <Label>{t("siteConfig.won_or_more")}</Label>
                                  </TableDeps>
                                  {/* 누적 배팅액 */}
                                  <TableDeps $height="42px">
                                    <InputForm
                                      maxWidth="100px"
                                      type="text"
                                      name="total_betting"
                                      groupNm="site_level_mng"
                                      index={index}
                                      placeholder="0"
                                      height={"28px"}
                                      tAlign="right"
                                      disabled={index == 0 ? true : false}
                                      {...useSearch}
                                    />
                                    <Label>{t("siteConfig.won_or_more")}</Label>
                                  </TableDeps>
                                  {/* 수익 금액 */}
                                  <TableDeps $height="42px">
                                    <InputForm
                                      maxWidth="100px"
                                      type="text"
                                      name="profit_amount"
                                      groupNm="site_level_mng"
                                      index={index}
                                      placeholder="0"
                                      height={"28px"}
                                      tAlign="right"
                                      disabled={index == 0 ? true : false}
                                      {...useSearch}
                                    />
                                    <Label>{t("siteConfig.won_or_more")}</Label>
                                  </TableDeps>
                                  {/* 레벨업 보너스 */}
                                  <TableDeps $height="42px">
                                    <InputForm
                                      maxWidth="100px"
                                      type="text"
                                      name="levelup_bonus"
                                      groupNm="site_level_mng"
                                      index={index}
                                      placeholder="0"
                                      height={"28px"}
                                      tAlign="right"
                                      disabled={index == 0 ? true : false}
                                      {...useSearch}
                                    />
                                  </TableDeps>
                                  {index == 0 ? null :
                                    <TableDeps $height="42px">
                                      <WhiteBtn onClick={() => showPopup(item, index)}>{t("siteConfig.manage_message")}</WhiteBtn>
                                    </TableDeps>
                                  }
                                </TableRow>
                              ))}
                            </tbody>
                          </Table>
                        </Column>
                      </OptionBox>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Column>
        {/* 계좌 설정 */}
        <Column className="area-box">
          <SubTitleBox onClick={() => handleDropdownToggle(5)}>
            <Row $gap="6px" $align="center">
              <AreaTitle>5. {t("siteConfig.account_settings")}</AreaTitle>
              <HoverCon
                onClick={(e) => {
                  e.stopPropagation(); //탭이 접히지 않게
                }}
              >
                <IconLink className="h-a-box" $url="/assets/svg/help.svg" $width="20px" $height="20px"></IconLink>
                <HoverBox className="h-box">
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("siteConfig.account_settings_info1")}
                  </TextBox>
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("siteConfig.account_settings_info2")}
                  </TextBox>
                </HoverBox>
              </HoverCon>
            </Row>
            <Row $gap="20px">
              <MainBtn
                $pad="6px 12px"
                onClick={(e) => {
                  e.stopPropagation(); // 저장하기 누를땐 탭이 접히지 않게
                  saveConfig();
                }}
              >
                {t("common.go_save")}
              </MainBtn>
              <IconLink
                $url="/assets/svg/site_config_menu_fold.svg"
                $width="32px"
                $height="32px"
                $bor="1px solid var(--c-gray-500)"
                $trans="rotate(180deg)"
                className={dropdownState[5] && "active"}
              ></IconLink>
            </Row>
          </SubTitleBox>
          {dropdownState[5] && (
            <>
              <OptionBox $align="center">
                <TextBox $width="160px" $line="var(--l-sub)">
                  {t("siteConfig.multi_account_settings")}
                </TextBox>
                <Row>
                  <ToggleButtonForm name="site_multi_account_yn" options={dbSiteConfig.site_multi_account_yn} {...useSearch} />
                </Row>
              </OptionBox>
              {useSearch.formValue?.site_multi_account_yn === dbSiteConfig.site_multi_account_yn.N.value && (
                <OptionBox $backColor="var(--c-gray-100)" $align="center">
                  <TextBox $width="160px" $pad="4px 0" $line="var(--l-sub)">
                    {t("siteConfig.main_guide_account")}
                  </TextBox>
                  <Row $gap="24px" $align="center">
                    <SelectForm
                      width="160px"
                      index={0}
                      name="account_bank"
                      groupNm="site_main_account"
                      options={bankMst}
                      placeholder={t("common.select")}
                      {...useSearch}
                    />
                    <InputForm
                      width="320px"
                      maxWidth="320px"
                      type="text"
                      index={0}
                      name="account_no"
                      groupNm="site_main_account"
                      placeholder={t("common.input")}
                      {...useSearch}
                    />
                  </Row>
                  <Row $gap="24px" $align="center">
                    <TextBox $white="nowrap">{t("siteConfig.account_holder")} : </TextBox>
                    <InputForm
                      width="160px"
                      maxWidth="160px"
                      type="text"
                      index={0}
                      name="account_name"
                      groupNm="site_main_account"
                      placeholder={t("common.input")}
                      {...useSearch}
                    />
                  </Row>
                </OptionBox>
              )}
              {useSearch.formValue?.site_multi_account_yn === dbSiteConfig.site_multi_account_yn.Y.value && (
                <>
                  <OptionBox $backColor="var(--c-gray-100)">
                    <TextBox $width="160px" $pad="4px 0" $line="var(--l-sub)">
                      {t("siteConfig.number_of_accounts")}
                    </TextBox>
                    <Column $gap="4px">
                      <Row>
                        <SelectForm
                          width="160px"
                          name="site_max_account"
                          options={dbSiteConfig.site_max_account}
                          {...useSearch}
                        />
                      </Row>
                      <TextBox $size="var(--s-caption)" $line="var(--l-caption)" $color="var(--c-gray-700)">
                        {t("siteConfig.multi_account_usage_limit_info")}
                      </TextBox>
                    </Column>
                  </OptionBox>
                  <OptionBox $backColor="var(--c-gray-100)">
                    <TextBox $width="160px" $pad="4px 0" $line="var(--l-sub)">
                      {t("siteConfig.account_settings")}
                    </TextBox>
                    <Column $flex="1" $gap="14px">
                      {useSearch.formValue?.site_account.map((item, index) => (
                        <Row $align="center" key={index}>
                          <Row $gap="4px" $align="center">
                            <EachCheckForm
                              index={index}
                              labelFor={`accountY-${index}`}
                              labelName={t("common.use_y")}
                              options={checkboxYNEnum}
                              name="account_yn"
                              groupNm="site_account"
                              {...useSearch}
                            />
                          </Row>
                          <Row $pad="0px 12px" $gap="4px" $align="center">
                            <EachCheckForm
                              index={index}
                              labelFor={`accountN-${index}`}
                              labelName={t("common.use_n")}
                              reverse={true}
                              options={checkboxYNEnum}
                              name="account_yn"
                              groupNm="site_account"
                              {...useSearch}
                            />
                          </Row>
                          <Row $gap="24px" $align="center">
                            <SelectForm
                              index={index}
                              width="160px"
                              name="account_bank"
                              groupNm="site_account"
                              options={bankMst}
                              placeholder={t("siteConfig.select_bank")}
                              {...useSearch}
                            />
                            <InputForm
                              width="320px"
                              maxWidth="320px"
                              type="text"
                              index={index}
                              name="account_no"
                              groupNm="site_account"
                              placeholder={t("siteConfig.account_number")}
                              {...useSearch}
                            />
                            <TextBox $white="nowrap">{t("siteConfig.account_settings_info3")} </TextBox>
                            <SelectForm
                              index={index}
                              width="160px"
                              name="user_state1"
                              groupNm="site_account"
                              options={dbSiteAccount.user_state1}
                              placeholder={t("common.select")}
                              {...useSearch}
                            />
                            <TextBox $width="200px">{t("siteConfig.account_settings_info4")}</TextBox>
                          </Row>
                          <Row $gap="24px" $align="center">
                            <TextBox $white="nowrap">{t("siteConfig.account_holder")} : </TextBox>
                            <InputForm
                              width="160px"
                              maxWidth="160px"
                              type="text"
                              index={index}
                              name="account_name"
                              groupNm="site_account"
                              placeholder={t("siteConfig.account_holder")}
                              {...useSearch}
                            />
                          </Row>
                        </Row>
                      ))}
                    </Column>
                  </OptionBox>
                </>
              )}
            </>
          )}
        </Column>
        {/* 6.입금 설정 */}
        <Column className="area-box">
          <SubTitleBox onClick={() => handleDropdownToggle(6)}>
            <Row $gap="6px" $align="center">
              <AreaTitle>6. {t("siteConfig.deposit_settings")}</AreaTitle>
              <HoverCon
                onClick={(e) => {
                  e.stopPropagation(); //탭이 접히지 않게
                }}
              >
                <IconLink className="h-a-box" $url="/assets/svg/help.svg" $width="20px" $height="20px"></IconLink>
                <HoverBox className="h-box">
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("siteConfig.deposit_settings_info1")}
                  </TextBox>
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("siteConfig.deposit_settings_info2")}
                  </TextBox>
                </HoverBox>
              </HoverCon>
            </Row>
            <Row $gap="20px">
              <MainBtn
                $pad="6px 12px"
                onClick={(e) => {
                  e.stopPropagation(); // 저장하기 누를땐 탭이 접히지 않게
                  saveConfig();
                }}
              >
                {t("common.go_save")}
              </MainBtn>
              <IconLink
                $url="/assets/svg/site_config_menu_fold.svg"
                $width="32px"
                $height="32px"
                $bor="1px solid var(--c-gray-500)"
                $trans="rotate(180deg)"
                className={dropdownState[6] && "active"}
              ></IconLink>
            </Row>
          </SubTitleBox>
          {dropdownState[6] && (
            <>
              <Row $jus="space-between">
                <Column $width="50%">
                  <Row>
                    <OptionBox $width="100%" $align="center">
                      <Row $width="160px" $minWidth="160px">
                        <TextBox $tAlign="center" $line="var(--l-sub)">
                          {t("siteConfig.deposit_waiting_time")}
                        </TextBox>
                      </Row>
                      <InputForm
                        tAlign="right"
                        width="120px"
                        type="text"
                        name="account_in_wait"
                        placeholder={t("common.input")}
                        {...useSearch}
                      />
                      <TextBox $width="160px" $minWidth="160px" $line="var(--l-sub)">
                        {t("siteConfig.minutes")}
                      </TextBox>
                    </OptionBox>
                  </Row>
                  <Row>
                    <OptionBox $width="100%" $align="center">
                      <TextBox $width="160px" $minWidth="160px" $line="var(--l-sub)">
                        {t("siteConfig.deposit_reapplication_waiting_time")}
                      </TextBox>
                      <InputForm
                        tAlign="right"
                        width="120px"
                        type="text"
                        name="account_in_re_wait"
                        placeholder={t("common.input")}
                        {...useSearch}
                      />
                      <TextBox $width="160px" $minWidth="160px" $line="var(--l-sub)">
                        {t("siteConfig.seconds")}
                      </TextBox>
                    </OptionBox>
                  </Row>
                  <Row>
                    <OptionBox $width="100%" $align="center">
                      <Row $width="160px" $minWidth="160px">
                        <TextBox $tAlign="center" $line="var(--l-sub)">
                          {t("siteConfig.minimum_deposit_request_amount")}
                        </TextBox>
                      </Row>
                      <InputForm
                        tAlign="right"
                        width="160px"
                        type="text"
                        name="account_in_min_money"
                        placeholder={t("common.input")}
                        {...useSearch}
                      />
                    </OptionBox>
                  </Row>
                  <Row>
                    <OptionBox $width="100%" $align="center">
                      <Row $width="160px" $minWidth="160px">
                        <TextBox $width="160px" $minWidth="160px" $line="var(--l-sub)">
                          {t("siteConfig.maximum_deposit_request_amount")}
                        </TextBox>
                      </Row>
                      <InputForm
                        tAlign="right"
                        width="160px"
                        type="text"
                        name="account_in_max_money"
                        placeholder={t("common.input")}
                        {...useSearch}
                      />
                    </OptionBox>
                  </Row>
                </Column>
                <LineSection $borL="1px solid var(--c-gray-300)" />
                <Column $pad="20px" $gap="20px" $width="50%" $borB="1px solid var(--c-gray-300)">
                  <TextBox $size="var(--s-header)" $line="var(--l-table)">
                    {t("siteConfig.deposit_settings_guide")}
                  </TextBox>
                  <Column $gap="8px">
                    <TextBox $size="var(--s-main)" $line="var(--l-table)">
                      {t("siteConfig.deposit_settings_guide1")}
                    </TextBox>
                    <TextBox $size="var(--s-main)" $line="var(--l-table)">
                      {t("siteConfig.deposit_settings_guide2")}
                    </TextBox>
                  </Column>
                </Column>
              </Row>

              <OptionBox $align="center">
                <Row $width="160px" $minWidth="160px">
                  <TextBox $line="var(--l-sub)">{t("siteConfig.deposit_limit")}</TextBox>
                </Row>
                <Row>
                  <ToggleButtonForm
                    name="account_in_limit_time_yn"
                    options={dbSiteConfig.account_in_limit_time_yn}
                    {...useSearch}
                  />
                </Row>
              </OptionBox>
              {useSearch.formValue?.account_in_limit_time_yn === dbSiteConfig.account_in_limit_time_yn.Y.value && (
                <>
                  <OptionBox $backColor="var(--c-gray-100)" $align="center">
                    <Row $width="160px" $minWidth="160px">
                      <TextBox $line="var(--l-sub)">{t("siteConfig.deposit_limit_time")}</TextBox>
                    </Row>
                    <Row $gap="24px" $align="center">
                      <Row $gap="12px" $align="center">
                        <Row>
                          <EachCheckFormCustom
                            labelFor="account-in-all-days"
                            name="account_in_limit_week"
                            index={0}
                            options={checkboxYNEnum}
                            {...useSearch}
                          />
                          <Label htmlFor="account-in-all-days">{t("days.every_day")}</Label>
                        </Row>
                        <Row $gap="4px" $align="center">
                          <EachCheckFormCustom
                            labelFor="account-in-sun"
                            name="account_in_limit_week"
                            index={1}
                            options={checkboxYNEnum}
                            {...useSearch}
                          />
                          <Label htmlFor="account-in-sun">{t("days.sunday")}</Label>
                        </Row>
                        <Row $gap="4px" $align="center">
                          <EachCheckFormCustom
                            labelFor="account-in-mon"
                            name="account_in_limit_week"
                            index={2}
                            options={checkboxYNEnum}
                            {...useSearch}
                          />
                          <Label htmlFor="account-in-mon">{t("days.monday")}</Label>
                        </Row>
                        <Row $gap="4px" $align="center">
                          <EachCheckFormCustom
                            labelFor="account-in-tue"
                            name="account_in_limit_week"
                            index={3}
                            options={checkboxYNEnum}
                            {...useSearch}
                          />
                          <Label htmlFor="account-in-tue">{t("days.tuesday")}</Label>
                        </Row>
                        <Row $gap="4px" $align="center">
                          <EachCheckFormCustom
                            labelFor="account-in-wen"
                            name="account_in_limit_week"
                            index={4}
                            options={checkboxYNEnum}
                            {...useSearch}
                          />
                          <Label htmlFor="account-in-wen">{t("days.wednesday")}</Label>
                        </Row>
                        <Row $gap="4px" $align="center">
                          <EachCheckFormCustom
                            labelFor="account-in-thur"
                            name="account_in_limit_week"
                            index={5}
                            options={checkboxYNEnum}
                            {...useSearch}
                          />
                          <Label htmlFor="account-in-thur">{t("days.thursday")}</Label>
                        </Row>
                        <Row $gap="4px" $align="center">
                          <EachCheckFormCustom
                            labelFor="account-in-fri"
                            name="account_in_limit_week"
                            index={6}
                            options={checkboxYNEnum}
                            {...useSearch}
                          />
                          <Label htmlFor="account-in-fri">{t("days.friday")}</Label>
                        </Row>
                        <Row $gap="4px" $align="center">
                          <EachCheckFormCustom
                            labelFor="account-in-sat"
                            name="account_in_limit_week"
                            index={7}
                            options={checkboxYNEnum}
                            {...useSearch}
                          />
                          <Label htmlFor="account-in-sat">{t("days.saturday")}</Label>
                        </Row>
                      </Row>
                    </Row>
                    <Row $gap="4px" $align="center">
                      <InputForm
                        width="120px"
                        type="text"
                        name="account_in_limit_start_time"
                        placeholder="23:59:59"
                        maskType="time"
                        {...useSearch}
                      />
                      <TextBox>~</TextBox>
                      <InputForm
                        width="120px"
                        type="text"
                        name="account_in_limit_end_time"
                        placeholder="23:59:59"
                        maskType="time"
                        {...useSearch}
                      />
                    </Row>
                  </OptionBox>
                </>
              )}
            </>
          )}
        </Column>
        {/* 7.출금 설정 */}
        <Column className="area-box">
          <SubTitleBox onClick={() => handleDropdownToggle(7)}>
            <Row $gap="6px" $align="center">
              <AreaTitle>7. {t("siteConfig.withdrawal_settings")}</AreaTitle>
              <HoverCon
                onClick={(e) => {
                  e.stopPropagation(); //탭이 접히지 않게
                }}
              >
                <IconLink className="h-a-box" $url="/assets/svg/help.svg" $width="20px" $height="20px"></IconLink>
                <HoverBox className="h-box">
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("siteConfig.withdrawal_settings_info1")}
                  </TextBox>
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("siteConfig.withdrawal_settings_info2")}
                  </TextBox>
                </HoverBox>
              </HoverCon>
            </Row>
            <Row $gap="20px">
              <MainBtn
                $pad="6px 12px"
                onClick={(e) => {
                  e.stopPropagation(); // 저장하기 누를땐 탭이 접히지 않게
                  saveConfig();
                }}
              >
                {t("common.go_save")}
              </MainBtn>
              <IconLink
                $url="/assets/svg/site_config_menu_fold.svg"
                $width="32px"
                $height="32px"
                $bor="1px solid var(--c-gray-500)"
                $trans="rotate(180deg)"
                className={dropdownState[7] && "active"}
              ></IconLink>
            </Row>
          </SubTitleBox>
          {dropdownState[7] && (
            <>
              <Row $jus="space-between">
                <Column $width="50%">
                  <Row>
                    <OptionBox $width="100%" $align="center">
                      <Row $width="160px" $minWidth="160px">
                        <TextBox $tAlign="center" $line="var(--l-sub)">
                          {t("siteConfig.withdrawal_waiting_time")}
                        </TextBox>
                      </Row>
                      <InputForm
                        tAlign="right"
                        width="120px"
                        type="text"
                        name="account_out_wait"
                        placeholder={t("common.input")}
                        {...useSearch}
                      />
                      <TextBox $width="160px" $minWidth="160px" $line="var(--l-sub)">
                        {t("siteConfig.minutes")}
                      </TextBox>
                    </OptionBox>
                  </Row>
                  <Row>
                    <OptionBox $width="100%" $align="center">
                      <TextBox $width="160px" $minWidth="160px" $line="var(--l-sub)">
                        {t("siteConfig.withdrawal_reapplication_waiting_time")}
                      </TextBox>
                      <InputForm
                        tAlign="right"
                        width="120px"
                        type="text"
                        name="account_out_re_wait"
                        placeholder={t("common.input")}
                        {...useSearch}
                      />
                      <TextBox $width="160px" $minWidth="160px" $line="var(--l-sub)">
                        {t("siteConfig.seconds")}
                      </TextBox>
                    </OptionBox>
                  </Row>
                  <Row>
                    <OptionBox $width="100%" $align="center">
                      <Row $width="160px" $minWidth="160px">
                        <TextBox $tAlign="center" $line="var(--l-sub)">
                          {t("siteConfig.minimum_withdrawal_request_amount")}
                        </TextBox>
                      </Row>
                      <InputForm
                        tAlign="right"
                        width="160px"
                        type="text"
                        name="account_out_min_money"
                        placeholder={t("common.input")}
                        {...useSearch}
                      />
                    </OptionBox>
                  </Row>
                  <Row>
                    <OptionBox $width="100%" $align="center">
                      <Row $width="160px" $minWidth="160px">
                        <TextBox $width="160px" $minWidth="160px" $line="var(--l-sub)">
                          {t("siteConfig.maximum_withdrawal_request_amount")}
                        </TextBox>
                      </Row>
                      <InputForm
                        tAlign="right"
                        width="160px"
                        type="text"
                        name="account_out_max_money"
                        placeholder={t("common.input")}
                        {...useSearch}
                      />
                    </OptionBox>
                  </Row>
                </Column>
                <LineSection $borL="1px solid var(--c-gray-300)" />
                <Column $pad="20px" $gap="20px" $width="50%" $borB="1px solid var(--c-gray-300)">
                  <TextBox $size="var(--s-header)" $line="var(--l-table)">
                    {t("siteConfig.withdrawal_settings_guide")}
                  </TextBox>
                  <Column $gap="8px">
                    <TextBox $size="var(--s-main)" $line="var(--l-table)">
                      {t("siteConfig.withdrawal_settings_guide1")}
                    </TextBox>
                    <TextBox $size="var(--s-main)" $line="var(--l-table)">
                      {t("siteConfig.withdrawal_settings_guide2")}
                    </TextBox>
                  </Column>
                </Column>
              </Row>

              <OptionBox $align="center">
                <Row $width="160px" $minWidth="160px">
                  <TextBox $line="var(--l-sub)">{t("siteConfig.withdrawal_limit")}</TextBox>
                </Row>
                <Row>
                  <ToggleButtonForm
                    name="account_out_limit_time_yn"
                    options={dbSiteConfig.account_out_limit_time_yn}
                    {...useSearch}
                  />
                </Row>
              </OptionBox>
              {useSearch.formValue?.account_out_limit_time_yn === dbSiteConfig.account_out_limit_time_yn.Y.value && (
                <>
                  <OptionBox $backColor="var(--c-gray-100)" $align="center">
                    <Row $width="160px" $minWidth="160px">
                      <TextBox $line="var(--l-sub)">{t("siteConfig.withdrawal_limit_time")}</TextBox>
                    </Row>
                    <Row $gap="24px" $align="center">
                      <Row $gap="12px" $align="center">
                        <Row>
                          <EachCheckFormCustom
                            labelFor="account-out-all-days"
                            name="account_out_limit_week"
                            index={0}
                            options={checkboxYNEnum}
                            {...useSearch}
                          />
                          <Label htmlFor="account-out-all-days">{t("days.every_day")}</Label>
                        </Row>
                        <Row $gap="4px" $align="center">
                          <EachCheckFormCustom
                            labelFor="account-out-sun"
                            name="account_out_limit_week"
                            index={1}
                            options={checkboxYNEnum}
                            {...useSearch}
                          />
                          <Label htmlFor="account-out-sun">{t("days.sunday")}</Label>
                        </Row>
                        <Row $gap="4px" $align="center">
                          <EachCheckFormCustom
                            labelFor="account-out-mon"
                            name="account_out_limit_week"
                            index={2}
                            options={checkboxYNEnum}
                            {...useSearch}
                          />
                          <Label htmlFor="account-out-mon">{t("days.monday")}</Label>
                        </Row>
                        <Row $gap="4px" $align="center">
                          <EachCheckFormCustom
                            labelFor="account-out-tue"
                            name="account_out_limit_week"
                            index={3}
                            options={checkboxYNEnum}
                            {...useSearch}
                          />
                          <Label htmlFor="account-out-tue">{t("days.tuesday")}</Label>
                        </Row>
                        <Row $gap="4px" $align="center">
                          <EachCheckFormCustom
                            labelFor="account-out-wed"
                            name="account_out_limit_week"
                            index={4}
                            options={checkboxYNEnum}
                            {...useSearch}
                          />
                          <Label htmlFor="account-out-wed">{t("days.wednesday")}</Label>
                        </Row>
                        <Row $gap="4px" $align="center">
                          <EachCheckFormCustom
                            labelFor="account-out-thu"
                            name="account_out_limit_week"
                            index={5}
                            options={checkboxYNEnum}
                            {...useSearch}
                          />
                          <Label htmlFor="account-out-thu">{t("days.thursday")}</Label>
                        </Row>
                        <Row $gap="4px" $align="center">
                          <EachCheckFormCustom
                            labelFor="account-out-fri"
                            name="account_out_limit_week"
                            index={6}
                            options={checkboxYNEnum}
                            {...useSearch}
                          />
                          <Label htmlFor="account-out-fri">{t("days.friday")}</Label>
                        </Row>
                        <Row $gap="4px" $align="center">
                          <EachCheckFormCustom
                            labelFor="account-out-sat"
                            name="account_out_limit_week"
                            index={7}
                            options={checkboxYNEnum}
                            {...useSearch}
                          />
                          <Label htmlFor="account-out-sat">{t("days.saturday")}</Label>
                        </Row>
                      </Row>
                    </Row>
                    <Row $gap="4px" $align="center">
                      <InputForm
                        width="120px"
                        type="text"
                        name="account_out_limit_start_time"
                        placeholder="23:59:59"
                        maskType="time"
                        {...useSearch}
                      />
                      <TextBox>~</TextBox>
                      <InputForm
                        width="120px"
                        type="text"
                        name="account_out_limit_end_time"
                        placeholder="23:59:59"
                        maskType="time"
                        {...useSearch}
                      />
                    </Row>
                  </OptionBox>
                </>
              )}
              <OptionBox $align="center">
                <Row $width="160px" $minWidth="160px">
                  <TextBox $pad="4px 0" $line="var(--l-sub)">
                    {t("siteConfig.withdrawal_rate")}
                  </TextBox>
                </Row>
                <Row>
                  <ToggleButtonForm
                    name="account_out_limit_rate_yn"
                    options={dbSiteConfig.account_out_limit_rate_yn}
                    {...useSearch}
                  />
                </Row>
              </OptionBox>
              {useSearch.formValue?.account_out_limit_rate_yn === dbSiteConfig.account_out_limit_rate_yn.Y.value && (
                <>
                  <OptionBox $backColor="var(--c-gray-100)">
                    <Row $width="160px" $minWidth="160px">
                      <TextBox $line="var(--l-sub)">{t("siteConfig.withdrawal_rate")}</TextBox>
                    </Row>
                    <Column $gap="12px" $width="100%">
                      <TextBox $size="var(--s-caption)" $line="var(--l-caption)" $color="var(--c-gray-700)">
                        {t("siteConfig.withdrawal_rate_info")}
                      </TextBox>
                      <Table className="table-box">
                        <thead>
                          <TableRow className="table-title">
                            <TableHeader $width="100px">{t("siteConfig.category")}</TableHeader>
                            {useSearch.formValue?.site_level_mng.map((item, index) => (
                              <TableHeader $width="132px" key={item.level}>{`${index} ${t("siteConfig.level")}`}</TableHeader>
                            ))}
                          </TableRow>
                        </thead>
                        <tbody>
                          <TableRow className="table-content">
                            <TableDeps $height="42px">{t("siteConfig.withdrawal_rate")}</TableDeps>
                            {useSearch.formValue?.site_level_mng.map((item, index) => (
                              <TableDeps $pad="0px 12px" $height="42px" key={item.level}>
                                <InputForm
                                  key={index}
                                  maxWidth="240px"
                                  type="text"
                                  name="account_out_prop"
                                  groupNm="site_level_mng"
                                  index={index}
                                  height={"28px"}
                                  placeholder="0%"
                                  {...useSearch}
                                />
                              </TableDeps>
                            ))}
                          </TableRow>
                        </tbody>
                      </Table>
                    </Column>
                  </OptionBox>
                </>
              )}
            </>
          )}
        </Column>

        <Column className="area-box">
          <SubTitleBox onClick={() => handleDropdownToggle(8)}>
            <Row $gap="6px" $align="center">
              <AreaTitle>8. {t("siteConfig.agent_provide_settings")}</AreaTitle>
              <HoverCon
                onClick={(e) => {
                  e.stopPropagation(); //탭이 접히지 않게
                }}
              >
                <IconLink className="h-a-box" $url="/assets/svg/help.svg" $width="20px" $height="20px"></IconLink>
                <HoverBox className="h-box">
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("siteConfig.agent_provide_settings_info")}
                  </TextBox>
                </HoverBox>
              </HoverCon>
            </Row>
            <Row $gap="20px">
              <MainBtn
                $pad="6px 12px"
                onClick={(e) => {
                  e.stopPropagation(); // 저장하기 누를땐 탭이 접히지 않게
                  saveConfig();
                }}
              >
                {t("common.go_save")}
              </MainBtn>
              <IconLink
                $url="/assets/svg/site_config_menu_fold.svg"
                $width="32px"
                $height="32px"
                $bor="1px solid var(--c-gray-500)"
                $trans="rotate(180deg)"
                className={dropdownState[8] && "active"}
              ></IconLink>
            </Row>
          </SubTitleBox>
          {dropdownState[8] && (
            <>
              <OptionBox $align="center">
                <Row $width="160px" $minWidth="160px">
                  <TextBox $line="var(--l-sub)">{t("siteConfig.provide_limit")}</TextBox>
                </Row>
                <Row>
                  <ToggleButtonForm
                    name="agent_provide_limit_yn"
                    options={dbSiteConfig.account_out_limit_time_yn}
                    {...useSearch}
                  />
                </Row>
              </OptionBox>
              {useSearch.formValue?.agent_provide_limit_yn === dbSiteConfig.account_out_limit_time_yn.Y.value && (
                <>
                  <OptionBox $backColor="var(--c-gray-100)" $align="center">
                    <Row $width="160px" $minWidth="160px">
                      <TextBox $line="var(--l-sub)">{t("siteConfig.provide_limit_time")}</TextBox>
                    </Row>
                    <Row $gap="24px" $align="center">
                      <Row $gap="12px" $align="center">
                        <EachCheckFormCustom
                          labelFor="agent_provide_limit-all-days"
                          labelName={t("days.every_day")}
                          name="agent_provide_limit_week"
                          index={0}
                          options={checkboxYNEnum}
                          {...useSearch}
                        />
                        <EachCheckFormCustom
                          labelFor="agent_provide_limit-sun"
                          labelName={t("days.sunday")}
                          name="agent_provide_limit_week"
                          index={1}
                          options={checkboxYNEnum}
                          {...useSearch}
                        />
                        <EachCheckFormCustom
                          labelFor="agent_provide_limit-mon"
                          labelName={t("days.monday")}
                          name="agent_provide_limit_week"
                          index={2}
                          options={checkboxYNEnum}
                          {...useSearch}
                        />
                        <EachCheckFormCustom
                          labelFor="agent_provide_limit-tue"
                          labelName={t("days.tuesday")}
                          name="agent_provide_limit_week"
                          index={3}
                          options={checkboxYNEnum}
                          {...useSearch}
                        />
                        <EachCheckFormCustom
                          labelFor="agent_provide_limit-wen"
                          labelName={t("days.wednesday")}
                          name="agent_provide_limit_week"
                          index={4}
                          options={checkboxYNEnum}
                          {...useSearch}
                        />
                        <EachCheckFormCustom
                          labelFor="agent_provide_limit-thur"
                          labelName={t("days.thursday")}
                          name="agent_provide_limit_week"
                          index={5}
                          options={checkboxYNEnum}
                          {...useSearch}
                        />
                        <EachCheckFormCustom
                          labelFor="agent_provide_limit-fr"
                          labelName={t("days.friday")}
                          name="agent_provide_limit_week"
                          index={6}
                          options={checkboxYNEnum}
                          {...useSearch}
                        />
                        <EachCheckFormCustom
                          labelFor="agent_provide_limit-sat"
                          labelName={t("days.saturday")}
                          name="agent_provide_limit_week"
                          index={7}
                          options={checkboxYNEnum}
                          {...useSearch}
                        />
                      </Row>
                    </Row>
                    <Row $gap="4px" $align="center">
                      <InputForm
                        width="120px"
                        type="text"
                        name="agent_provide_limit_start_time"
                        placeholder="23:59:59"
                        maskType="time"
                        {...useSearch}
                      />
                      <TextBox>~</TextBox>
                      <InputForm
                        width="120px"
                        type="text"
                        name="agent_provide_limit_end_time"
                        placeholder="23:59:59"
                        maskType="time"
                        {...useSearch}
                      />
                    </Row>
                  </OptionBox>
                </>
              )}
            </>
          )}
        </Column>

        {/* 9. 에이전트 단계 설정 */}
        <Column className="area-box">
          <SubTitleBox onClick={() => handleDropdownToggle(9)}>
            <Row $gap="6px" $align="center">
              <AreaTitle>9. {t("siteConfig.agent_level_settings")}</AreaTitle>
              <HoverCon
                onClick={(e) => {
                  e.stopPropagation(); //탭이 접히지 않게
                }}
              >
                <IconLink className="h-a-box" $url="/assets/svg/help.svg" $width="20px" $height="20px"></IconLink>
                <HoverBox className="h-box">
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("siteConfig.urgent_commission_split_explained_info_O")}
                  </TextBox>
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("siteConfig.urgent_commission_split_explained_info_V")}
                  </TextBox>
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("siteConfig.urgent_commission_split_explained_info_F")}
                  </TextBox>
                </HoverBox>
              </HoverCon>
            </Row>
            <Row $gap="20px">
              <MainBtn
                $pad="6px 12px"
                onClick={(e) => {
                  e.stopPropagation(); // 저장하기 누를땐 탭이 접히지 않게
                  saveConfig(true);
                }}
              >
                {t("common.go_save")}
              </MainBtn>
              <IconLink
                $url="/assets/svg/site_config_menu_fold.svg"
                $width="32px"
                $height="32px"
                $bor="1px solid var(--c-gray-500)"
                $trans="rotate(180deg)"
                className={dropdownState[9] && "active"}
              ></IconLink>
            </Row>
          </SubTitleBox>
          {dropdownState[9] && (
            <>
              <OptionBox $align="center">
                <Row $width="160px" $minWidth="160px">
                  <TextBox $line="var(--l-sub)">{t("siteConfig.use_level_settings")}</TextBox>
                </Row>
                <SelectForm
                  name="agent_max_level"
                  options={agentLevelEnum}
                  placeholder={t("siteConfig.select_stage")}
                  {...useSearch}
                />
              </OptionBox>
              <OptionBox>
                <Row $width="160px" $minWidth="160px">
                  <TextBox $pad="4px 0" $line="var(--l-sub)">
                    {t("siteConfig.use_level_by_settings")}
                  </TextBox>
                </Row>
                <Table className="table-box">
                  <thead>
                    <TableRow className="table-title">
                      <TableHeader $width="56px">{t("siteConfig.stage")}</TableHeader>
                      <TableHeader $width="132px">{t("siteConfig.stage_name")}</TableHeader>
                      <TableHeader $width="140px">{t("siteConfig.comp_use")}</TableHeader>
                      <TableHeader $width="133px">{t("common.casino_rolling_rate")}(%)</TableHeader>
                      <TableHeader $width="133px">{t("common.casino_losing_rate")}(%)</TableHeader>
                      <TableHeader $width="133px">{t("common.slot_rolling_rate")}(%)</TableHeader>
                      <TableHeader $width="133px">{t("common.slot_losing_rate")}(%)</TableHeader>
                      <TableHeader $width="133px">{t("common.mini_game_rolling_rate")}(%)</TableHeader>
                      <TableHeader $width="133px">{t("common.mini_game_losing_rate")}(%)</TableHeader>
                      <TableHeader $width="133px">{t("common.sports_rolling_rate")}(%)</TableHeader>
                      <TableHeader $width="133px">{t("common.sports_losing_rate")}(%)</TableHeader>
                    </TableRow>
                  </thead>
                  <tbody>
                    {useSearch.formValue?.site_agent_type_config.map((item, index) => (
                      <TableRow key={index} className="table-content" disabled={item.use_yn !== "Y"}>
                        <TableDeps $height="42px">{item.depth}</TableDeps>
                        <TableDeps $height="42px">
                          <InputForm
                            maxWidth="100px"
                            type="text"
                            name="name"
                            groupNm="site_agent_type_config"
                            index={index}
                            height={"28px"}
                            placeholder={t("siteConfig.level_name")}
                            {...useSearch}
                            disabled={index == 0}
                          />
                        </TableDeps>
                        <TableDeps $height="42px">
                          <SelectForm
                            width="56px"
                            height={"28px"}
                            top={index > 5 ? "top" : "bottom"}
                            name="agent_commission_type"
                            groupNm="site_agent_type_config"
                            placeholder={t("siteConfig.use_select")}
                            options={agentCommissionsEnum}
                            index={index}
                            {...useSearch}
                            disabled={index == 0}
                          />
                        </TableDeps>
                        <TableDeps $height="42px">
                          <InputForm
                            maxWidth="100px"
                            type="number"
                            name="casino_rolling_rate"
                            groupNm="site_agent_type_config"
                            index={index}
                            placeholder="0.00"
                            height={"28px"}
                            tAlign={"right"}
                            {...useSearch}
                            disabled={index == 0}
                          />
                        </TableDeps>
                        <TableDeps $height="42px">
                          <InputForm
                            maxWidth="100px"
                            type="text"
                            name="casino_losing_rate"
                            groupNm="site_agent_type_config"
                            index={index}
                            placeholder="0.00"
                            height={"28px"}
                            tAlign={"right"}
                            {...useSearch}
                            disabled={index == 0}
                          />
                        </TableDeps>
                        <TableDeps $height="42px">
                          <InputForm
                            maxWidth="100px"
                            type="text"
                            name="slot_rolling_rate"
                            groupNm="site_agent_type_config"
                            index={index}
                            placeholder="0.00"
                            height={"28px"}
                            tAlign={"right"}
                            {...useSearch}
                            disabled={index == 0}
                          />
                        </TableDeps>
                        <TableDeps $height="42px">
                          <InputForm
                            maxWidth="100px"
                            type="text"
                            name="slot_losing_rate"
                            groupNm="site_agent_type_config"
                            index={index}
                            placeholder="0.00"
                            height={"28px"}
                            tAlign={"right"}
                            {...useSearch}
                            disabled={index == 0}
                          />
                        </TableDeps>
                        <TableDeps $height="42px">
                          <InputForm
                            maxWidth="100px"
                            type="text"
                            name="mini_game_rolling_rate"
                            groupNm="site_agent_type_config"
                            index={index}
                            placeholder="0.00"
                            height={"28px"}
                            tAlign={"right"}
                            {...useSearch}
                            disabled={index == 0}
                          />
                        </TableDeps>
                        <TableDeps $height="42px">
                          <InputForm
                            maxWidth="100px"
                            type="text"
                            name="mini_game_losing_rate"
                            groupNm="site_agent_type_config"
                            index={index}
                            placeholder="0.00"
                            height={"28px"}
                            tAlign={"right"}
                            {...useSearch}
                            disabled={index == 0}
                          />
                        </TableDeps>
                        <TableDeps $height="42px">
                          <InputForm
                            maxWidth="100px"
                            type="text"
                            name="sports_rolling_rate"
                            groupNm="site_agent_type_config"
                            index={index}
                            placeholder="0.00"
                            height={"28px"}
                            tAlign={"right"}
                            {...useSearch}
                            disabled={index == 0}
                          />
                        </TableDeps>
                        <TableDeps $height="42px">
                          <InputForm
                            maxWidth="100px"
                            type="text"
                            name="sports_losing_rate"
                            groupNm="site_agent_type_config"
                            index={index}
                            placeholder="0.00"
                            height={"28px"}
                            tAlign={"right"}
                            {...useSearch}
                            disabled={index == 0}
                          />
                        </TableDeps>
                      </TableRow>
                    ))}
                  </tbody>
                </Table>
              </OptionBox>
            </>
          )}
        </Column>
        {/* 10. 회원 -> 에이전트 전환 설정 */}
        <Column className="area-box">
          <SubTitleBox onClick={() => handleDropdownToggle(10)}>
            <Row $gap="6px" $align="center">
              <AreaTitle>10. {t("siteConfig.user_to_agent_settings")}</AreaTitle>{" "}
              <HoverCon
                onClick={(e) => {
                  e.stopPropagation(); //탭이 접히지 않게
                }}
              >
                <IconLink className="h-a-box" $url="/assets/svg/help.svg" $width="20px" $height="20px"></IconLink>
                <HoverBox className="h-box">
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("siteConfig.user_to_agent_settings_info1")}
                  </TextBox>
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("siteConfig.user_to_agent_settings_info2")}
                  </TextBox>
                </HoverBox>
              </HoverCon>
            </Row>
            <Row $gap="20px">
              <MainBtn
                $pad="6px 12px"
                onClick={(e) => {
                  e.stopPropagation(); // 저장하기 누를땐 탭이 접히지 않게
                  saveConfig();
                }}
              >
                {t("common.go_save")}
              </MainBtn>
              <IconLink
                $url="/assets/svg/site_config_menu_fold.svg"
                $width="32px"
                $height="32px"
                $bor="1px solid var(--c-gray-500)"
                $trans="rotate(180deg)"
                className={dropdownState[10] && "active"}
              ></IconLink>
            </Row>
          </SubTitleBox>
          {dropdownState[10] && (
            <>
              <OptionBox $align="center">
                <Row $width="160px" $minWidth="160px">
                  <TextBox $tAlign="center" $line="var(--l-sub)">
                    {t("siteConfig.agent_conversion_settings")}
                  </TextBox>
                </Row>
                {t("siteConfig.user_to_agent_condition1")}
                <InputForm type="number" name="allow_agent_user_count" placeholder={t("common.input")} tAlign={"right"} {...useSearch} />
                {t("siteConfig.user_to_agent_condition2")}
              </OptionBox>
              <OptionBox $align="center">
                <Row $width="160px" $minWidth="160px">
                  <TextBox $line="var(--l-sub)">{t("siteConfig.send_message")}</TextBox>
                </Row>
                <Row>
                  <ToggleButtonForm name="allow_agent_msg_yn" options={dbSiteConfig.user_auto_level_yn} {...useSearch} />
                </Row>
              </OptionBox>
              {useSearch.formValue?.allow_agent_msg_yn === dbSiteConfig.user_auto_level_yn.Y.value && (
                <OptionBox $backColor="var(--c-gray-100)">
                  <Row $width="160px" $minWidth="160px">
                    <TextBox $pad="4px 0" $line="var(--l-sub)">
                      {t("siteConfig.agent_conversion_message")}
                    </TextBox>
                  </Row>
                  <TextareaForm name="allow_agent_msg" placeholder={t("siteConfig.enter_content")} {...useSearch} />
                </OptionBox>
              )}
            </>
          )}
        </Column>

        <Column className="area-box" $overflow="hidden">
          <SubTitleBox onClick={() => handleDropdownToggle(11)}>
            <Row $gap="6px" $align="center">
              <AreaTitle>11. {t("siteConfig.notification_settings")}</AreaTitle>
              <HoverCon
                onClick={(e) => {
                  e.stopPropagation(); //탭이 접히지 않게
                }}
              >
                <IconLink className="h-a-box" $url="/assets/svg/help.svg" $width="20px" $height="20px"></IconLink>
                <HoverBox className="h-box">
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("siteConfig.notification_settings_info1")}
                  </TextBox>
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("siteConfig.notification_settings_info2")}
                  </TextBox>
                </HoverBox>
              </HoverCon>
            </Row>
            <Row $gap="20px">
              <MainBtn
                $pad="6px 12px"
                onClick={(e) => {
                  e.stopPropagation(); // 저장하기 누를땐 탭이 접히지 않게
                  saveConfig();
                }}
              >
                {t("common.go_save")}
              </MainBtn>
              <IconLink
                $url="/assets/svg/site_config_menu_fold.svg"
                $width="32px"
                $height="32px"
                $bor="1px solid var(--c-gray-500)"
                $trans="rotate(180deg)"
                className={dropdownState[11] && "active"}
              ></IconLink>
            </Row>
          </SubTitleBox>

          {dropdownState[11] && (
            <>
              <OptionBox $align="center">
                <Row $width="160px" $minWidth="160px">
                  <TextBox $line="var(--l-sub)">{t("siteConfig.deposit_notification")}</TextBox>
                </Row>
                <Row $gap="12px" $align="center">
                  <TextBox>{t("siteConfig.after_new_deposit_request")}</TextBox>
                  <SelectForm
                    name="alarm_in_type"
                    placeholder={t("common.select")}
                    options={tmpAlarmTypeEnum}
                    {...useSearch}
                    top="auto"
                  />
                  <TextBox>{t("siteConfig.repeat_play_for")}</TextBox>
                  <SelectForm
                    name="alarm_in_delay_time"
                    placeholder={t("common.select")}
                    options={dbSiteConfig.alarm_delay_time}
                    {...useSearch}
                    top="auto"
                  />
                  <TextBox>{t("siteConfig.repeat_play_until_check")}</TextBox>
                </Row>
              </OptionBox>
              <OptionBox $align="center">
                <Row $width="160px" $minWidth="160px">
                  <TextBox $line="var(--l-sub)">{t("siteConfig.withdrawal_notification")}</TextBox>
                </Row>
                <Row $gap="12px" $align="center">
                  <TextBox>{t("siteConfig.after_new_withdrawal_request")}</TextBox>
                  <SelectForm
                    name="alarm_out_type"
                    placeholder={t("common.select")}
                    options={tmpAlarmTypeEnum}
                    {...useSearch}
                    top="auto"
                  />
                  <TextBox>{t("siteConfig.repeat_play_for")}</TextBox>
                  <SelectForm
                    name="alarm_out_delay_time"
                    placeholder={t("common.select")}
                    options={dbSiteConfig.alarm_delay_time}
                    {...useSearch}
                    top="auto"
                  />
                  <TextBox>{t("siteConfig.repeat_play_until_check")}</TextBox>
                </Row>
              </OptionBox>
              <OptionBox $align="center">
                <Row $width="160px" $minWidth="160px">
                  <TextBox $line="var(--l-sub)">{t("siteConfig.signup_notification")}</TextBox>
                </Row>
                <Row $gap="12px" $align="center">
                  <TextBox>{t("siteConfig.after_new_signup_request")}</TextBox>
                  <SelectForm
                    name="alarm_join_type"
                    placeholder={t("common.select")}
                    options={tmpAlarmTypeEnum}
                    {...useSearch}
                    top="auto"
                  />
                  <TextBox>{t("siteConfig.repeat_play_for")}</TextBox>
                  <SelectForm
                    name="alarm_join_delay_time"
                    placeholder={t("common.select")}
                    options={dbSiteConfig.alarm_delay_time}
                    {...useSearch}
                    top="auto"
                  />
                  <TextBox>{t("siteConfig.repeat_play_until_check")}</TextBox>
                </Row>
              </OptionBox>
              <OptionBox $align="center">
                <Row $width="160px" $minWidth="160px">
                  <TextBox $line="var(--l-sub)">{t("siteConfig.qna_notification")}</TextBox>
                </Row>
                <Row $gap="12px" $align="center">
                  <TextBox>{t("siteConfig.after_new_qna")}</TextBox>
                  <SelectForm
                    name="alarm_qna_type"
                    placeholder={t("common.select")}
                    options={tmpAlarmTypeEnum}
                    {...useSearch}
                    top="auto"
                  />
                  <TextBox>{t("siteConfig.repeat_play_for")}</TextBox>
                  <SelectForm
                    name="alarm_qna_delay_time"
                    placeholder={t("common.select")}
                    options={dbSiteConfig.alarm_delay_time}
                    {...useSearch}
                    top="auto"
                  />
                  <TextBox>{t("siteConfig.repeat_play_until_check")}</TextBox>
                </Row>
              </OptionBox>
              <OptionBox $align="center">
                <Row $width="160px" $minWidth="160px">
                  <TextBox $line="var(--l-sub)">{t("siteConfig.agent_qna_notification")}</TextBox>
                </Row>
                <Row $gap="12px" $align="center">
                  <TextBox>{t("siteConfig.after_new_agent_qna")}</TextBox>
                  <SelectForm
                    name="alarm_agent_qna_type"
                    placeholder={t("common.select")}
                    options={tmpAlarmTypeEnum}
                    {...useSearch}
                    top="auto"
                  />
                  <TextBox>{t("siteConfig.repeat_play_for")}</TextBox>
                  <SelectForm
                    name="alarm_agent_qna_delay_time"
                    placeholder={t("common.select")}
                    options={dbSiteConfig.alarm_delay_time}
                    {...useSearch}
                    top="auto"
                  />
                  <TextBox>{t("siteConfig.repeat_play_until_check")}</TextBox>
                </Row>
              </OptionBox>

            </>
          )}
        </Column>
        {/*  12. 회원 가입 설정 */}
        <Column className="area-box">
          <SubTitleBox onClick={() => handleDropdownToggle(12)}>
            <Row $gap="6px" $align="center">
              <AreaTitle>12. {t("siteConfig.signup_setting")}</AreaTitle>
              <HoverCon
                onClick={(e) => {
                  e.stopPropagation(); //탭이 접히지 않게
                }}
              >
                <IconLink className="h-a-box" $url="/assets/svg/help.svg" $width="20px" $height="20px"></IconLink>
                <HoverBox className="h-box">
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("siteConfig.signup_wait_limit_info")}
                  </TextBox>
                </HoverBox>
              </HoverCon>
            </Row>
            <Row $gap="20px">
              <MainBtn
                $pad="6px 12px"
                onClick={(e) => {
                  e.stopPropagation(); // 저장하기 누를땐 탭이 접히지 않게
                  saveConfig();
                }}
              >
                {t("common.go_save")}
              </MainBtn>
              <IconLink
                $url="/assets/svg/site_config_menu_fold.svg"
                $width="32px"
                $height="32px"
                $bor="1px solid var(--c-gray-500)"
                $trans="rotate(180deg)"
                className={dropdownState[12] && "active"}
              ></IconLink>
            </Row>
          </SubTitleBox>
          {dropdownState[12] && (
            <>
              <OptionBox $align="center">
                <Row $width="260px" $minWidth="260px">
                  <TextBox $line="var(--l-sub)">{t("siteConfig.signup_wait_limit")}</TextBox>
                </Row>
                <Row>
                  <ToggleButtonForm
                    name="signup_wait_limit"
                    options={dbSiteConfig.signup_wait_limit}
                    {...useSearch}
                  />
                </Row>
              </OptionBox>
            </>
          )}
        </Column>

      </Column>
    </>
  );
};

const agentLevelEnum = [
  { value: 1, label: 1 + t("siteConfig.stage") },
  { value: 2, label: 2 + t("siteConfig.stage") },
  { value: 3, label: 3 + t("siteConfig.stage") },
  { value: 4, label: 4 + t("siteConfig.stage") },
  { value: 5, label: 5 + t("siteConfig.stage") },
  { value: 6, label: 6 + t("siteConfig.stage") },
  { value: 7, label: 7 + t("siteConfig.stage") },
  { value: 8, label: 8 + t("siteConfig.stage") },
  { value: 9, label: 9 + t("siteConfig.stage") },
  { value: 10, label: 10 + t("siteConfig.stage") },
];

const planInspectMessageEnum = [
  { value: "Y", label: t("common.post_y") },
  { value: "N", label: t("common.post_n") },
];

const agentCommissionsEnum = [
  { value: "N", label: t("siteConfig.no_use") },
  { value: "T", label: t("siteConfig.use_all") },
  { value: "R", label: t("siteConfig.use_rolling") },
  { value: "L", label: t("siteConfig.use_losing") },
];

const tmpAlarmTypeEnum = [
  { value: 1, label: t("siteConfig.alarm_sound") + 1 },
  { value: 2, label: t("siteConfig.alarm_sound") + 2 },
  { value: 3, label: t("siteConfig.alarm_sound") + 3 },
  { value: 4, label: t("siteConfig.alarm_sound") + 4 },
];

export default SiteConfig;
