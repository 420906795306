import React, { useEffect } from "react";

// 공용
import Pagination from "utils/Pagination";
import api from "interceptor/api";

import useTableData from "common/useTableData";

import { useAlert, useConfirm, usePopup } from "contexts/useWindow";
import { NoLabelCheckForm, InputForm, SelectForm, TextareaForm, EachCheckForm, ImageForm, TextareaEditorForm } from "components/common/CommonForm";
import { getFormatDatetime } from "utils/date/DateUtil";
// 셀렉트 벨류 constant
import { useCountPerPage, userState1 } from "constants/select/useValue";
// 공용 팝업
// style import
import { MainBtn, RedBtn, WhiteBtn } from "style/useButtonStyles";
import { IconLink, PositionBox } from "style/useLayoutStyles";
import { AreaTitle, SubText, TextBox } from "style/useTextStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { AreaHeader, Column, FilterBox, FormBox, Row } from "style/custom/useCustomLayout";
import { isEmpty } from "lodash";
import useFormData from "common/useFormData";
import NoDataTable from "components/common/NoDataTable";
import { useCommonState } from "contexts/useCommonState";
import useStateData from "common/useStateData";
import { DeleteBtn, ModifyBtn } from "components/common/TableStatus";
import { ErrorMsgForm } from "utils/FormStateUtil";
import { t } from "i18next";

const UserQna = () => {
  const useTG = useTableData(true);
  const useStated = useStateData();

  const { openPopup } = usePopup();
  const { openAlert } = useAlert();
  const { setLoading } = useCommonState();
  const { openConfirm } = useConfirm();

  const showPopup = (item, isReadOnly) => {
    openPopup({
      title: isReadOnly ? t("UserFAQ.FAQmodify") : t("UserFAQ.FAQadd"),
      content: UserQnAPopup,
      props: {
        item,
        isReadOnly,
        getTableItem,
      },
    });
  };

  const successDeleteAlert = () => {
    openAlert({
      title: t("UserFAQ.FAQ_delete_success"),
      message: t("UserFAQ.FAQ_delete_success_message"),
      iconURL: false,
      mainBtn: t("common.check"),
    });
  };

  // useAlarmCheck("user-qna");

  const getTableItem = () => {
    setLoading(true);
    api
      .get("/v1/cs-management/FAQ", {
        params: {
          ...useTG.extendData.pagingData,
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useStated.setSuccessData([res.data.content]);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status === 400) {
          useStated.setErrorData(error.response.data.errors);
        }
        console.error(error);
      });
  };

  const deleteSelect = (no) => {
    const checkedList = useTG.data.filter((item) => item.isChecked);
    const no_list = no ? [no] : checkedList.map((item) => item.no);
    openConfirm({
      title: t("UserFAQ.FAQ"),
      message: no ? t("UserFAQ.FAQdelete_contents", { number: [no] }) : t("UserFAQ.FAQdelete_contents_Arr", { number: no_list.length }) ,
      iconURL: false,
      mainBtn: t("common.check"),
      Func: () => deleteQnA(no_list),
    });
  }

  const deleteQnA = (no) => {
    if (!no.length) return;
    api
      .delete(`/v1/cs-management/FAQ`, { params: { no_list: no } })
      .then((res) => {
        getTableItem();
        successDeleteAlert()
      })
      .catch((error) => {
        const errArr = error.response?.data.errors;
        openAlert({
          title: t("common.delete_fail"),
          message:
            errArr === null || errArr === undefined ? null : (
              <Column>
                {errArr.map((err, index) => (
                  <ErrorMsgForm errorJson={err} key={index} />
                ))}
              </Column>
            ),
        });
        console.error(error);
      });
  };

  const useSearch = useFormData(
    {

    },
    getTableItem,
    useTG
  );

  // 검색 조건이 변경되면 검색어 초기화 (InputForm 에서 SelectForm 으로 변경될 때 오류 방지)
  useEffect(() => {
    useSearch.setFormValue({
      ...useSearch.formValue,
      keyword: "",
    });
  }, [useSearch.formValue?.search_type]);

  return (
    <>
      <AreaHeader>
        <SubText>{t("menu.operate_manager")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{t("UserFAQ.FAQ")}</AreaTitle>
      </AreaHeader>

      <Column>
        <Row $gap="12px">
          <Row className="total-count" $gap="4px" $pad="14px 0 0 0">
            <TextBox $font="var(--f-subB)">{t("userQna.total_inquiries")}</TextBox>
            <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
              {useTG.error ? (
                <span>error</span>
              ) : (
                <>{useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords?.toLocaleString() : 0}</>
              )}
            </TextBox>
            <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
          </Row>
        </Row>

        <Row $jus="space-between" $width="100%" $align="center" $pad="14px 0">
          <RedBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => deleteSelect()}>
            {t("common.delete")}
          </RedBtn>
          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => showPopup()}>
              {t("userQna.register")}
            </MainBtn>
            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useTG.extendData}
            />
          </Row>
        </Row>

        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            {
              <Table className="table-box">
                <thead>
                  <tr className="table-title">
                    <TableHeader $width="34px">
                      <NoLabelCheckForm $mar="0 0 0 !important" name="all" base={useTG} />
                    </TableHeader>
                    <TableHeader $width="40px">No</TableHeader>
                    <TableHeader $width="130px">{t("userQna.title")}</TableHeader>
                    <TableHeader $width="100px">{t("userQna.registrant")}</TableHeader>
                    <TableHeader $width="130px">{t("userQna.registration_date")}</TableHeader>
                    <TableHeader $width="100px">{t("UserFAQ.FAQ_modifier")}</TableHeader>
                    <TableHeader $width="130px">{t("UserFAQ.FAQ_modify_date")}</TableHeader>
                    <TableHeader $width="60px">{t("userQna.management")}</TableHeader>
                  </tr>
                </thead>
                {useTG && useTG.data?.length > 0 && (
                  <tbody>
                    {useTG.data &&
                      useTG.data.map((item, index) => {
                        const number = useTG.pagingInfo.offset - index;
                        return (
                          <TableRow key={number} className="table-content">
                            <TableDeps>
                              <NoLabelCheckForm keys={item.no} base={useTG} eachData={item} />
                            </TableDeps>
                            <TableDeps $maxWidth="40px" $tAlign="center" $overflow="hidden" $white="nowrap" $textOverflow="ellipsis">
                              {item.no ? item.no : ' - '}
                            </TableDeps>
                            <TableDeps $maxWidth="231px" $tAlign="left" $overflow="hidden" $white="nowrap" $textOverflow="ellipsis">
                              {item.title ? item.title : ' - '}
                            </TableDeps>
                            <TableDeps $tAlign="left" $maxWidth="84px" $overflow="hidden" $white="nowrap">
                              {item.create_id ? item.create_id : ' - '}
                            </TableDeps>
                            <TableDeps $maxWidth="130px" $tAlign="center">{getFormatDatetime(item.create_at) ?? "-"}</TableDeps>
                            <TableDeps $width="84px" $tAlign={`${item.modify_id ? "left" : "center"}`}>{item.modify_id ? item.modify_id : ' - '}</TableDeps>
                            <TableDeps $maxWidth="130px" $tAlign="center">{getFormatDatetime(item.modify_at) ?? "-"}</TableDeps>
                            <TableDeps $maxWidth="84px">
                              <Row $width="100%" $jus="center" $gap="6px">
                                {item?.no ? (
                                  <>
                                    <ModifyBtn name={t("common.modify")} eventProps={() => showPopup(item, true)} />
                                    <DeleteBtn eventProps={() => deleteSelect(item?.no)} />
                                  </>
                                ) : (
                                  "-"
                                )}
                              </Row>
                            </TableDeps>
                          </TableRow>
                        );
                      })}
                  </tbody>
                )}
              </Table>
            }
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}
        
        <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="space-between" $width="100%">
          <RedBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => deleteSelect()}>
            {t("common.delete")}
          </RedBtn>
          <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
            {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
          </PositionBox>
          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => showPopup()}>
              {t("userQna.register")}
            </MainBtn>
            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useTG.extendData}
            />
          </Row>
        </Row>
      </Column>
    </>
  );
};

const UserQnAPopup = ({ item, isReadOnly, getTableItem }) => {
  const { closePopup } = usePopup();
  const useStated = useStateData();
  const { openAlert } = useAlert();

  const { openConfirm } = useConfirm();
  
  const successAlert = () => {
    openAlert({
      title: t("UserFAQ.FAQ"),
      message: isReadOnly ? t("UserFAQ.FAQ_medify_success_message") : t("UserFAQ.FAQ_add_success_message"),
      iconURL: false,
      mainBtn: t("common.check"),
    });
  };

  const showStateFailAlert = (list) => {
    openAlert({
      title: t("UserFAQ.FAQ_save_fail"),
      message:  list ? t("UserFAQ.FAQ_empty") : t("UserFAQ.FAQ_faill_message"),
      iconURL: true, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn: t("common.check"),
    });
  };

  const nothingChanged = () => {
    openAlert({
      title: t("UserFAQ.FAQ_save_fail"),
      message:  t("UserFAQ.nothing_changed"),
      iconURL: true, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn: t("common.check"),
    });
  };

  const confFunc = () => {
    if(isReadOnly){
      if(item.title === useSearch.formValue.title && item.contents === useSearch.formValue.contents) return nothingChanged()
    }
    openConfirm({
      title: isReadOnly ? t("UserFAQ.FAQmodify") : t("UserFAQ.FAQadd"),
      message: isReadOnly ? t("UserFAQ.FAQ_modify_message") : t("UserFAQ.FAQ_add_message"),
      iconURL: false,
      mainBtn: t("common.check"),
      Func: () => createFAQ(),
    });
  }

  const useSearch = useFormData({
    no: item?.no,
    title: item?.title,
    contents: item?.contents,
  });

  const createFAQ = () => {
    let apiMethod = isReadOnly ? "put" : "post";
    api[apiMethod]("/v1/cs-management/FAQ", { ...useSearch.formValue })
      .then((res) => {
        closePopup();
        successAlert(true);
        getTableItem();
      })
      .catch((err) => {
        if(err.response?.status === 400){
          showStateFailAlert(true)
          useStated.setErrorData(err.response.data.errors, true);
        } else {
          showStateFailAlert(false)
        }
      });
  };

  return (
    <>
      <Column $width="800px" $gap="30px" $pad="20px">
        <FormBox $gap="12px" $align="flex-start">
          <FormBox $flex="1">
            <AreaTitle>{t("noticeBoard.title")} : </AreaTitle>
          </FormBox>
          <FormBox $flex="5.8">
            <InputForm
              name="title"
              width="100%"
              minWidth="100%"
              maxWidth="100%"
              {...useSearch}
              {...useStated}
            />
          </FormBox>
        </FormBox>
        <FormBox $align="flex-start" $pad="0 0 16px 0">
          <FormBox $flex="1" $pad="8px 0 0 0">
            <AreaTitle>{t("noticeBoard.contents")} : </AreaTitle>
          </FormBox>
          <FormBox $flex="6">
            <TextareaEditorForm
              name="contents"
              width="100%"
              height="320px"
              minWidth="100%"
              maxWidth="100%"
              {...useSearch}
              {...useStated}
            ></TextareaEditorForm>
          </FormBox>
        </FormBox>
        <Row $jus="flex-end" $right="100%" $pad="12px 4px 0px 0px" $gap="8px">
          <MainBtn $width="61px" $height="34px" onClick={confFunc}>
            {isReadOnly ? t("common.modify") : t("common.registration")}
          </MainBtn>
          <RedBtn $width="61px" $height="34px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
            {t("common.cancel")}
          </RedBtn>
        </Row>
      </Column>
    </>
  );
};

export default UserQna;
