import { useLocation, Navigate } from "react-router-dom";
import queryString from "query-string";

function Oauth() {
  localStorage.removeItem("auth-admin");

  const { search } = useLocation();
  const { id, no, type, code } = queryString.parse(search) || {};

  localStorage.setItem("auth-admin", JSON.stringify({ id, no, type, code }));

  return <Navigate to="/" />;
}

export default Oauth;
