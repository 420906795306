/** @format */
import { useEffect, useRef, useState } from "react";

// 공용
import api from "interceptor/api";
import Pagination from "utils/Pagination";

import useFormData from "common/useFormData";
import useTableData from "common/useTableData";

import { useAlert, usePopup } from "contexts/useWindow";
// 셀렉트 벨류 constant
import { inoutState, useCountPerPage, userState1, withdrawalFilterCondition } from "constants/select/useValue";
// 공용 팝업
// style import
import { EachCheckForm, InputForm, SelectForm, SortForm } from "components/common/CommonForm";
import NoDataTable from "components/common/NoDataTable";
import { isEmpty } from "lodash";
import { AreaHeader, Column, FilterBox, Row } from "style/custom/useCustomLayout";
import { FilterTitle, HoverTableText } from "style/custom/useCustomText";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { IconLink, InfoTabBox, InfoTabList, LineSection, PositionBox } from "style/useLayoutStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { AreaTitle, SubText, TextBox } from "style/useTextStyles";

import useStateData from "common/useStateData";
import UserInfo from "components/admin/user/info/UserInfo";
import AgentNameTab from "components/common/AgentNameTab";
import AgentSearchFilter from "components/common/AgentSearchFilter";
import CalendarFilter from "components/common/CalendarFilter";
import { BlockBtn, ModifyBtn } from "components/common/TableStatus";
import UserLevelFilter from "components/common/UserLevelFilter";
import { useCommonState } from "contexts/useCommonState";
import { dbUser, dbUserHistoryAccount } from "enum/db/useUserEnum";
import { checkboxYNEnum, infoTabEnum, validationTypeEnum } from "enum/useCommonEnum";
import { t } from "i18next";
import { useAlarmCheck } from "utils/ApiUtil";
import { getFormatDatetime } from "utils/date/DateUtil";
import WithdrawalManagementForm from "./WithdrawalManagementForm";
import UserLevelTab from "components/common/UserLevelTab";

const WithdrawalManagement = () => {
  const useStated = useStateData();
  const { setLoading } = useCommonState();

  const { openPopup } = usePopup();
  const { openAlert } = useAlert();

  const [todayWithdrawalRequestCount, setTodayWithdrawalRequestCount] = useState(0);
  const [completedWithdrawalCount, setCompletedWithdrawalCount] = useState(0);
  const [rejectedWithdrawalCount, setRejectedWithdrawalCount] = useState(0);

  const [userNm, setUserNm] = useState("");
  const [openUserInfoTab, setOpenUserInfoTab] = useState(false);
  const [openAgentInfoTab, setOpenAgentInfoTab] = useState(false);
  const userTabRef = useRef(null);
  const agentTabRef = useRef(null);

  useAlarmCheck("withdrawal");

  const infoTabToggle = (tabType, Nm) => {
    if (tabType === infoTabEnum.tabType.u.value) {
      setUserNm(Nm);
      setOpenUserInfoTab(!openUserInfoTab);
    } else if (tabType === infoTabEnum.tabType.a.value) {
      setUserNm(Nm);
      setOpenAgentInfoTab(!openAgentInfoTab);
    }
  };

  useEffect(() => {
    const onBlurEvent = (event) => {
      if (userTabRef.current && !userTabRef.current.contains(event.target)) {
        setOpenUserInfoTab(false);
      }
      if (agentTabRef.current && !agentTabRef.current.contains(event.target)) {
        setOpenAgentInfoTab(false);
      }
    };

    document.addEventListener("mousedown", onBlurEvent);
    return () => {
      document.removeEventListener("mousedown", onBlurEvent);
    };
  }, []);

  const showMessageAlert = (message) => {
    openAlert({
      title: t("withdrawal.withdrawal_reject_reason"),
      message: message || "-",
      iconURL: false,
      mainBtn: t("common.check"),
    });
  };

  const showWithdrawalPopup = (historyNo, userNo, nickName, agentNo) => {
    const userInfo = {
      no: userNo,
      agent_no: agentNo
    };

    openPopup({
      title: nickName ? `${t("withdrawal.withdrawal_manage")} (${nickName})` : t("withdrawal.withdrawal_manage"),
      content: WithdrawalManagementForm,
      props: {
        historyNo,
        userNo,
        userInfo,
        getTableItem,
      },
    });
  };

  const showUserInfoPopup = (userNo, nickName) => {
    openPopup({
      title: nickName ? `${t("user.tab_user_info")} (${nickName})` : t("user.tab_user_info"),
      content: UserInfo,
      props: {
        userNo,
        getTableItem,
      },
    });
  };

  const getTableItem = () => {
    setLoading(true);

    const apiType = api.get;
    const apiUrl = "/v1/inout/withdrawal/list";
    apiType(apiUrl, {
      params: {
        ...useSearch.searchValue,
        ...useTG.extendData.pagingData,
        sortNm: useTG.extendData.sortNm,
      },
    })
      .then((res) => {
        useTG.setData(res.data.content);
        setTodayWithdrawalRequestCount(res.data.todayWithdrawalRequestCount);
        setCompletedWithdrawalCount(res.data.completedWithdrawalCount);
        setRejectedWithdrawalCount(res.data.rejectedWithdrawalCount);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useTG.setError("");
      })
      .catch((err) => {
        console.error(err);
        useTG.setError(true);
        setLoading(false);
        useTG.setData([]);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const useTG = useTableData(true);
  const useSearch = useFormData(
    {
      search_sub_agent_yn: "Y",
      search_agent_category: "",
      search_agent_val: "",
      search_agent_name: "",

      search_user_lv: "",
      search_user_nickname: "",
      search_user_no: "",
      search_user_state1: "",
      search_inout_state: "",
      search_manage_user_yn: "Y",

      search_calendar_category: "",
      start_date: "",
      end_date: "",
    },
    getTableItem,
    useTG
  );

  return (
    <>
      <AreaHeader>
        <SubText>{t("menu.deposit_and_withdrawal_manage")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{t("menu.withdrawal_manage")}</AreaTitle>
      </AreaHeader>

      <Column>
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
            <AgentSearchFilter useSearch={useSearch} useStated={useStated} $mar="0 24px 0 0 " />

            <Column $gap="4px">
              <FilterTitle>{t("user.user")}</FilterTitle>
              <InputForm type="text" name="search_user_nickname" placeholder={t("common.input")} {...useSearch} />
            </Column>

            <Column $gap="4px">
              <FilterTitle>{t("user.user_no")}</FilterTitle>
              <InputForm validationType={validationTypeEnum.NUMBER} name="search_user_no" placeholder={t("common.input")} {...useSearch} />
            </Column>

            <Column $gap="4px">
              <FilterTitle>{t("user.search_user_state")}</FilterTitle>
              <SelectForm
                name="search_user_state1"
                placeholder={t("common.all")}
                options={userState1}
                {...useSearch}
                {...useStated}
              />
            </Column>

            <UserLevelFilter useSearch={useSearch} useStated={useStated} />

            <Column $gap="4px">
              <FilterTitle>{t("inout.process_status")}</FilterTitle>
              <SelectForm name="search_inout_state" placeholder={t("common.all")} options={inoutState} {...useSearch} {...useStated} />
            </Column>
            <Row $pad="28px 0 0 0">
              <EachCheckForm
                $size="var(--s-caption)"
                name="search_manage_user_yn"
                labelFor="search_manage_user_yn"
                options={checkboxYNEnum}
                labelName={t("filter.include_manage_user")}
                {...useSearch}
              />
            </Row>

            <Column $gap="4px">
              <FilterTitle>{t("withdrawal.withdrawal_request_process_data")}</FilterTitle>
              <Row $gap="4px" $align="center">
                <SelectForm
                  name="search_calendar_category"
                  placeholder={t("common.select")}
                  options={withdrawalFilterCondition}
                  {...useSearch}
                  {...useStated}
                />
                <CalendarFilter endName="end_date" startName="start_date" {...useSearch} end />
              </Row>
            </Column>
          </Row>

          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
              {t("filter.search")}
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
              {t("filter.refresh")}
            </WhiteBtn>
          </Row>
        </FilterBox>

        <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
          <Row $gap="10px" $align="center">
            <Row className="total-money" $gap="4px">
              <TextBox $font="var(--f-subB)">{t("withdrawal.today_withdrawal_request_count")}</TextBox>
              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                {useTG.error ? <span>error</span> : <>{todayWithdrawalRequestCount?.toLocaleString() || "0"}</>}
              </TextBox>
              <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
            </Row>

            <LineSection $height="12px" $borL="1px solid var(--c-gray-300)"></LineSection>

            <Row className="total-money" $gap="4px">
              <TextBox $font="var(--f-subB)">{t("withdrawal.today_withdrawal_done_count")}</TextBox>
              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                {useTG.error ? <span>error</span> : <>{completedWithdrawalCount?.toLocaleString() || "0"}</>}
              </TextBox>
              <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
            </Row>

            <LineSection $height="12px" $borL="1px solid var(--c-gray-300)"></LineSection>

            <Row className="total-money" $gap="4px">
              <TextBox $font="var(--f-subB)">{t("withdrawal.today_withdrawal_cancel_count")}</TextBox>
              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                {useTG.error ? <span>error</span> : <>{rejectedWithdrawalCount?.toLocaleString() || "0"}</>}
              </TextBox>
              <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
            </Row>
          </Row>

          <SelectForm
            name="countPerPage"
            placeholder={useCountPerPage[0].label}
            {...useTG}
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useCountPerPage}
            formValue={useCountPerPage.value}
          />
        </Row>

        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  <TableHeader $width="60px">{t("common.no")}</TableHeader>
                  <TableHeader>{t("inout.parent_agent")}</TableHeader>
                  <TableHeader>{t("user.agent_code")}</TableHeader>
                  <TableHeader>{t("user.agent")}</TableHeader>
                  <TableHeader>{t("user.user")}</TableHeader>
                  <TableHeader $width="130px">{t("user.user_level")}</TableHeader>
                  <TableHeader $width="130px">{t("user.user_state")}</TableHeader>
                  <TableHeader>{t("withdrawal.account_name")}</TableHeader>
                  <TableHeader $width="130px">
                    <Row $gap="2px" $jus="center" $align="center">
                      <TextBox $font="PreSB">{t("inout.request_at")}</TextBox>
                      <SortForm colNm="create_at" sortNm={useTG.extendData.sortNm} onSortClick={useTG.onSortClick} />
                    </Row>
                  </TableHeader>
                  <TableHeader $width="130px">{t("withdrawal.withdrawal_amount")}</TableHeader>
                  <TableHeader $width="130px">{t("inout.cumulative_count")}</TableHeader>
                  <TableHeader $width="130px">{t("inout.process_status")}</TableHeader>
                  <TableHeader $width="130px">{t("inout.process_at")}</TableHeader>
                  <TableHeader>{t("common.manage")}</TableHeader>
                  <TableHeader>{t("inout.processor")}</TableHeader>
                </tr>
              </thead>
              {useTG && useTG.data?.length > 0 && (
                <tbody>
                  {useTG.data &&
                    useTG.data.map((item, index) => {
                      const number = useTG.pagingInfo.offset - index;
                      return (
                        <TableRow
                          key={item.no}
                          className={
                            item.state === dbUserHistoryAccount.state.P.value
                              ? "complete"
                              : item.state === dbUserHistoryAccount.state.B.value
                                ? "warn"
                                : item.state === dbUserHistoryAccount.state.C.value
                                  ? "normal"
                                  : "wait"
                          }
                        >
                          <TableDeps $width="60px">{number || "-"}</TableDeps>
                          <TableDeps $tAlign="left">{item.parent_code || "-"}</TableDeps>
                          <TableDeps $tAlign="left">{item.code}</TableDeps>

                          <TableDeps $tAlign="left">
                            <AgentNameTab
                              no={item.agent_no}
                              code={item.code}
                              name={item.name}
                              type_name={item.type_name}
                            />
                          </TableDeps>

                          <TableDeps $tAlign="left" onClick={() => infoTabToggle(infoTabEnum.tabType.u.value, item.no)}>
                            <HoverTableText>
                              {`${item.user_nickname || "-"} (${item.user_no || "-"})`}
                              {openUserInfoTab && item.no === userNm && (
                                <InfoTabBox ref={userTabRef}>
                                  <InfoTabList onClick={() => showUserInfoPopup(item.user_no, item.user_nickname)}>
                                    {t("user.tab_user_info")}
                                  </InfoTabList>
                                </InfoTabBox>
                              )}
                            </HoverTableText>
                          </TableDeps>

                          <TableDeps>
                            <UserLevelTab item={item} />
                          </TableDeps>

                          <TableDeps>{dbUser.state1[item.state1]?.label || "-"}</TableDeps>
                          <TableDeps $tAlign="left">{item.account_name || "-"}</TableDeps>
                          <TableDeps>{getFormatDatetime(item.create_at) || "-"}</TableDeps>
                          <TableDeps $tAlign="right">{item.amount?.toLocaleString() || "0"}</TableDeps>
                          <TableDeps $tAlign="right">{item.account_out_count?.toLocaleString() || "0" || "0"}</TableDeps>
                          <TableDeps>
                            <TextBox
                              className={
                                item.state === dbUserHistoryAccount.state.P.value
                                  ? "complete"
                                  : item.state === dbUserHistoryAccount.state.B.value
                                    ? "warn"
                                    : item.state === dbUserHistoryAccount.state.C.value
                                      ? "normal"
                                      : "wait"
                              }
                            >
                              {dbUserHistoryAccount.state[item.state]?.label || "-"}
                            </TextBox>
                          </TableDeps>
                          <TableDeps>{getFormatDatetime(item.process_at) || "-"}</TableDeps>
                          <TableDeps $width="120px">
                            <Row $width="100%" $jus="center" $gap="6px">
                              {item.user_no ? (
                                <>
                                  {(item.state === dbUserHistoryAccount.state.D.value ||
                                    item.state === dbUserHistoryAccount.state.C.value) && (
                                      <ModifyBtn
                                        name={t("withdrawal.withdrawal_process")}
                                        eventProps={() => showWithdrawalPopup(item.no, item.user_no, item.user_nickname, item.agent_no)}
                                      />
                                    )}
                                  {item.state === dbUserHistoryAccount.state.P.value && (
                                    <ModifyBtn
                                      name={t("inout.detail_list")}
                                      eventProps={() => showUserInfoPopup(item.user_no, item.user_nickname)}
                                    />
                                  )}
                                  {item.state === dbUserHistoryAccount.state.B.value && (
                                    <BlockBtn name={t("inout.reason")} eventProps={() => showMessageAlert(item.message)} />
                                  )}
                                </>
                              ) : (
                                "-"
                              )}
                            </Row>
                          </TableDeps>
                          <TableDeps $tAlign="left">{`${item.process_nickname || "-"} (${item.process_no || "-"})`}</TableDeps>
                        </TableRow>
                      );
                    })}
                </tbody>
              )}
            </Table>
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}

        <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%">
          <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
            {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
          </PositionBox>

          <SelectForm
            top="top"
            name="countPerPage"
            placeholder={useCountPerPage[0].label}
            {...useTG}
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useCountPerPage}
            formValue={useCountPerPage.value}
          />
        </Row>
      </Column>
    </>
  );
};

export default WithdrawalManagement;
