import React, { useState } from "react";

// 공용
import api from "interceptor/api";

import useTableData from "common/useTableData";

import { useAlert, useConfirm, usePopup } from "contexts/useWindow";
// style import
import { MainBtn, MenuToggleBtn, WhiteBtn } from "style/useButtonStyles";
import { BorderBox, IconLink } from "style/useLayoutStyles";
import { AreaTitle, SubText, TextBox } from "style/useTextStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { AreaHeader, Column, FilterBox, Row, SubTitleBox } from "style/custom/useCustomLayout";
import AdminForm from "./AdminForm";
import { getFormatStringToDate } from "utils/date/DateUtil";
import { dbAdmin } from "enum/db/useAdminEnum";
import { FilterTitle } from "style/custom/useCustomText";
import { InputForm, SelectForm } from "components/common/CommonForm";
import useFormData from "common/useFormData";
import { BlockBtn, ModifyBtn, NormalStatus, WarnStatus } from "components/common/TableStatus";
import useStateData from "common/useStateData";
import { addAllFromEnum } from "utils/common/EnumUtil";
import { useCommonState } from "contexts/useCommonState";
import NoDataTable from "components/common/NoDataTable";
import { t } from "i18next";

const SiteAdmin = () => {
  const useStated = useStateData();
  const { setLoading } = useCommonState();

  const { openPopup } = usePopup();
  const { openConfirm } = useConfirm();
  const { openAlert } = useAlert();
  const [dropdownState, setDropdownState] = useState({});

  const showPopup = (item) => {
    openPopup({
      title: item ? t("admin.modify_admin") : t("admin.regis_admin"),
      content: AdminForm,
      height: "835px",
      props: {
        item,
        getTableItem,
      },
    });
  };

  const showBlockStateConfirm = (no, state) => {
    const isBlocked = state === dbAdmin.state.B.value;
    const mainBtn = isBlocked ? t("common.block") : t("common.unlock");
    const actionMessage = isBlocked ? t("admin.block_state_msg") : "";

    const title = isBlocked ? t("admin.block_state_confirm") : t("admin.unblock_state_confirm");

    openConfirm({
      title,
      message: actionMessage,
      iconURL: true,
      mainBtn,
      Func: () => updateState(no, state),
    });
  };

  const showBlockStateSuccessAlert = (state) => {
    const title = state === "B" ? t("admin.block_complete") : t("admin.unblock_complete");
    openAlert({
      title,
      iconURL: false, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn: t("common.check"),
    });
  };

  const showBlockStateFailAlert = (state) => {
    const title = state === "B" ? t("admin.block_fail") : t("admin.unblock_fail");
    openAlert({
      title,
      message: t("common.try_later"),
      iconURL: true, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn: t("common.check"),
    });
  };

  const updateState = (no, state) => {
    setLoading(true);
    const apiUrl = `v1/site/admin/state/${no}`;
    api
      .put(apiUrl, {
        state,
      })
      .then((res) => {
        showBlockStateSuccessAlert(state);
        getTableItem();
      })
      .catch((err) => {
        showBlockStateFailAlert(state);
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getTableItem = () => {
    setLoading(true);

    const apiUrl = "/v1/site/admin";
    api
      .get(apiUrl, {
        params: {
          ...useSearch.searchValue,
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        useTG.setError("");
      })
      .catch((err) => {
        useTG.setError(true);
        useTG.setData([]);

        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const useTG = useTableData(true);

  const useSearch = useFormData(
    {
      search_nickname: "",
      search_state: "",
      search_id: "",
    },
    getTableItem,
    useTG
  );

  const getOppositeState = (state) => {
    const { N, B } = dbAdmin.state;
    return state === N.value ? B.value : N.value;
  };

  const handleDropdownToggle = (state) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [state]: !prevState[state],
    }));
  };

  const openAllDropdowns = () => {
    setDropdownState({
      1: true,
    });
  };

  const closeAllDropdowns = () => {
    setDropdownState({
      1: false,
    });
  };

  return (
    <>
      <AreaHeader>
        <SubText>{t("menu.site_config_manager")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{t("menu.admin_config")}</AreaTitle>

        <Row $gap="8px" style={{ marginLeft: "auto" }}>
          <MenuToggleBtn
            $bcolor="var(--c-gray-300)"
            $size="var(--s-table)"
            $font="var(--f-table)"
            $height="28px"
            $line="20px"
            onClick={openAllDropdowns}
          >
            {t("common.unfold_all_menu")}

          </MenuToggleBtn>
          <MenuToggleBtn
            $bcolor="var(--c-gray-300)"
            $size="var(--s-table)"
            $font="var(--f-table)"
            $height="28px"
            onClick={closeAllDropdowns}
          >
            {t("common.fold_all_menu")}
          </MenuToggleBtn>
        </Row>
      </AreaHeader>
      <Column>
        <SubTitleBox onClick={() => handleDropdownToggle(1)} $cursor="pointer">
          <Row $gap="6px" $align="center">
            <AreaTitle>1. {t("admin.admin_allow_ip_config")}</AreaTitle>
          </Row>

          <BorderBox>
            <IconLink
              className={dropdownState[1] && "active"}
              $trans="rotate(-180deg)"
              $url="/assets/svg/arrow_toggle.svg"
              $width="32px"
              $height="32px"
            ></IconLink>
          </BorderBox>
        </SubTitleBox>
        {dropdownState[1] && (
          <Column $mar ="16px 0 0 0">
            <FilterBox $pad="12px">
              <Row $gap="12px" $align="center">
                <Column $gap="4px">
                  <FilterTitle>{t("admin.id")}</FilterTitle>
                  <Row $gap="4px">
                    <InputForm type="text" name="search_id" placeholder={t("common.input")} {...useSearch} {...useStated} />
                  </Row>
                </Column>
                <Column $gap="4px">
                  <FilterTitle>{t("admin.nickname")}</FilterTitle>
                  <Row $gap="4px">
                    <InputForm type="text" name="search_nickname" placeholder={t("common.input")} {...useSearch} {...useStated} />
                  </Row>
                </Column>

                <Column $gap="4px">
                  <FilterTitle>{t("common.state")}</FilterTitle>
                  <Row $gap="4px">
                    <SelectForm
                      name="search_state"
                      placeholder={t("common.all")}
                      options={addAllFromEnum(dbAdmin.state)}
                      {...useSearch}
                      {...useStated}
                    />
                  </Row>
                </Column>
              </Row>

              <Row $gap="8px">
                <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
                  {t("filter.search")}
                </MainBtn>
                <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
                  {t("filter.refresh")}
                </WhiteBtn>
              </Row>
            </FilterBox>

            <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
              <Row $gap="10px" $align="center">
                <Row className="total-count" $gap="4px">
                  <TextBox $font="var(--f-subB)">{t("common.total")}</TextBox>
                  <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                    {useTG.data?.length}
                  </TextBox>
                  <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
                </Row>
              </Row>

              <Row $gap="8px">
                <MainBtn $width="100px" $size="var(--s-table)" $line="var(--l-table)" onClick={() => showPopup()}>
                  {t("admin.regis_admin")}
                </MainBtn>
              </Row>
            </Row>

            {useTG.error ? (
              <span>error</span>
            ) : (
              <>
                <Table className="table-box">
                  <thead>
                    <tr className="table-title">
                      <TableHeader $width="60px">{t("common.no")}</TableHeader>
                      <TableHeader $tAlign="left">{t("admin.id")}</TableHeader>
                      <TableHeader>{t("admin.admin")}</TableHeader>
                      <TableHeader>{t("common.state")}</TableHeader>
                      <TableHeader>{t("admin.ip")}</TableHeader>
                      <TableHeader $width="130px">{t("admin.date")}</TableHeader>
                      <TableHeader>{t("common.manage")}</TableHeader>
                    </tr>
                  </thead>
                  <tbody>
                    {useTG.data &&
                      useTG.data.map((item, index) => (
                        <TableRow key={item.no} className="table-content">
                          <TableDeps>{useTG.data.length - index}</TableDeps>
                          <TableDeps $tAlign="left">{item.id}</TableDeps>
                          <TableDeps $tAlign="left">{`${item.nickname}(${item.no})`}</TableDeps>
                          <TableDeps>
                            <Row $width="100%" $jus="center">
                              {item.state === dbAdmin.state.N.value ? (
                                <NormalStatus name={dbAdmin.state[item.state].label} />
                              ) : (
                                <WarnStatus name={dbAdmin.state[item.state].label} />
                              )}
                            </Row>
                          </TableDeps>
                          <TableDeps>
                            <Column>
                              {item.ip_arr.map((ip) => (
                                <TextBox key={ip} $size="var(--s-sub)" $line="var(--l-sub)" $pad="2px">
                                  {ip}
                                </TextBox>
                              ))}
                            </Column>
                          </TableDeps>
                          <TableDeps>
                            <Column>
                              <TextBox $size="var(--s-sub)" $line="var(--l-sub)" $pad="2px">
                                {item.create_at ? getFormatStringToDate(item.create_at) : "-"}
                              </TextBox>
                              <TextBox $size="var(--s-sub)" $line="var(--l-sub)" $pad="2px">
                                {item.block_date ? getFormatStringToDate(item.block_date) : "-"}
                              </TextBox>
                            </Column>
                          </TableDeps>

                          <TableDeps $maxWidth="90px">
                            <Row $width="100%" $jus="center" $gap="6px">
                              <ModifyBtn eventProps={() => showPopup(item.no)} />
                              <BlockBtn
                                name={item.state === dbAdmin.state.N.value ? t("common.block") : t("common.unlock")}
                                eventProps={() => showBlockStateConfirm(item.no, getOppositeState(item.state))}
                              />
                            </Row>
                          </TableDeps>
                        </TableRow>
                      ))}
                  </tbody>
                </Table>
                {useTG && useTG.data?.length === 0 && <NoDataTable />}
              </>
            )}

            <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="space-between" $width="100%">
              <Row $gap="8px"></Row>

              <Row $gap="8px">
                <MainBtn $width="100px" $size="var(--s-table)" $line="var(--l-table)" onClick={() => showPopup()}>
                  {t("admin.regis_admin")}
                </MainBtn>
              </Row>
            </Row>
          </Column>
        )}
      </Column>
    </>
  );
};

export default SiteAdmin;
