import React, { useEffect } from "react";

import useFormData from "common/useFormData";
import { AreaTitle } from "style/useTextStyles";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { TextBox } from "style/useTextStyles";
import { PadBox, PositionBox, IconLink } from "style/useLayoutStyles";
import { InputForm, SelectForm } from "components/common/CommonForm";
import { AreaHeader, Between, Column, FilterBox, Row } from "style/custom/useCustomLayout";
import api from "interceptor/api";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { useAlert, usePopup } from "contexts/useWindow";
import useTableData from "common/useTableData";
import { FilterTitle } from "style/custom/useCustomText";
import { dateFormat, timeFormat } from "utils/date/DateUtil";
import Pagination from "utils/Pagination";
import { isEmpty } from "lodash";
import { useFiveCountPerPage } from "constants/select/useValue";

import useStateData from "common/useStateData";
import NoDataTable from "components/common/NoDataTable";
import { useCommonState } from "contexts/useCommonState";

import { useTranslation } from "react-i18next";
import AgentSearchFilter from "components/common/AgentSearchFilter";

import CalendarFilter from "components/common/CalendarFilter";
import styled from "styled-components";
import { BlockBtn, ModifyBtn, NormalStatus, SuccessStatus, WarnStatus } from "components/common/TableStatus";
import { betEnum, gameEnum } from "enum/useCommonEnum";
import AgentNameTab from "components/common/AgentNameTab";
import UserState2Hover from "components/common/UserState2Hover";
import UserNameTab from "components/common/UserNameTab";
import UserBettingTab from "../user/betting/UserBettingTab";

const Poker = styled.div`
  width: 24px;
  height: fit-content;

  text-align: center;

  border: 1px solid #d9e2e9;
  background: var(--c-white);
  color: black;
  padding: 2px 8px;

  border-radius: 4px;

  &.H {
    & > p {
      color: var(--c-red);
    }
  }

  &.D {
    & > p {
      color: var(--c-red);
    }
  }

  &.ho {
    display: flex;
    width: fit-content;
    align-items: center;
    padding: 4px 8px;

    & > img {
      position: relative;
      margin-left: 3px;
    }
  }
`;

const PokerImg = styled.img`
  width: 10px;
`;

const PokerCon = styled.div`
  display: flex;
  align-items: center;

  gap: 4px;
  justify-content: center;
`;

const BettingCon = styled.div`
  width: 100%;
  padding: 8px;
  &.player-win {
    background-color: #eff0ff;
  }
  &.banker-win {
    background-color: #fff0ed;
  }
  &.tie {
    background-color: #f4fbdb;
  }
`;

const BettingTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const BettingTableHeader = styled.th`
  border: 1px solid var(--c-gray-300);
  text-align: center;
  padding: 4px;
  font-size: var(--s-caption);
  font-family: var(--f-tableB);
`;

const BettingTableCell = styled.td`
  border: 1px solid var(--c-gray-300);
  text-align: center;
  padding: 4px;
  font-size: var(--s-caption);
  text-align : right
`;

const BettingList = () => {
  const useStated = useStateData();

  const { setLoading } = useCommonState();

  const { openAlert } = useAlert();
  const { t } = useTranslation();
  const { openPopup } = usePopup();

  useEffect(() => {
    useStated.errors.forEach((item) => {
      if (item.path === "useAlert") {
        showUpdateStateConditionFail(item.msg.code);
      }
    });
  }, [useStated.errors]);

  const showUpdateStateConditionFail = (code) => {
    openAlert({
      title: t(`error.${code}`),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const getTableItem = () => {
    setLoading(true);

    const apiUrl = `/v1/user/all/bettings`;

    api
      .get(apiUrl, {
        params: {
          ...useSearch.searchValue,
          ...useTG.extendData.pagingData,
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        useTG.setError("");
        useStated.setErrorData([], true);
      })
      .catch((err) => {
        useTG.setError(true);
        useTG.setData([]);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showAgentInfoPopup = (transaction_id) => {
    openPopup({
      title: t("betting.detail_history"),
      content: UserBettingTab,
      props: {
        transactionId: transaction_id,
      },
    });
  };
  const useTG = useTableData(true, "5");
  const useSearch = useFormData(
    {
      search_transaction_id: "",

      search_sub_agent_yn: "Y",
      search_agent_category: "",
      search_agent_val: "",
      search_agent_name: "",
      search_user_nickname: "",

      start_date: "",
      end_date: "",
    },
    getTableItem,
    useTG
  );

  return (
    <>
      <AreaHeader $jus="space-between">
        <AreaTitle>{t("menu.betting_list")}</AreaTitle>
        <AreaTitle>{t("agent.bet_history_agent")}</AreaTitle>
      </AreaHeader>
      <Column>
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="center">
            <AgentSearchFilter useSearch={useSearch} useStated={useStated} $mar="0 24px 0 0 " />
            <Column $gap="4px">
              <FilterTitle>{t("user.user")}</FilterTitle>
              <InputForm type="text" name="search_user_nickname" placeholder={t("common.input")} {...useSearch} />
            </Column>
            <Column $gap="4px">
              <FilterTitle>{t("betting.no")}</FilterTitle>
              <Row $gap="4px">
                <InputForm
                  type="text"
                  name="search_transaction_id"
                  placeholder={t("common.input")}
                  {...useSearch}
                  {...useStated}
                />
              </Row>
            </Column>
            <Column $gap="4px">
              <FilterTitle>{t("betting.bet_time")}</FilterTitle>
              <Row $gap="4px">
                <CalendarFilter endName="end_date" startName="start_date" {...useSearch} end />
              </Row>
            </Column>
          </Row>
          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
              {t("filter.search")}
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
              {t("filter.refresh")}
            </WhiteBtn>
          </Row>
        </FilterBox>

        <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
          <Row $gap="10px" $align="center">
            <Row className="total-count" $gap="4px">
              <TextBox $font="var(--f-subB)">{t("common.total")}</TextBox>
              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                {useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords : 0}
              </TextBox>
              <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
            </Row>
          </Row>

          <Row $gap="8px">
            <SelectForm
              name="countPerPage"
              placeholder={useFiveCountPerPage[0].label}
              {...useTG}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useFiveCountPerPage}
              formValue={useFiveCountPerPage.value}
            />
          </Row>
        </Row>
        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  <TableHeader $width="100px">{t("betting.no")}</TableHeader>
                  <TableHeader $width="100px">{t("agent.code")}</TableHeader>
                  <TableHeader $width="50px">{t("agent.agent")}</TableHeader>
                  <TableHeader $width="50px">{t("user.user")}</TableHeader>
                  <TableHeader $width="300px">{t("betting.game")}</TableHeader>
                  <TableHeader $width="100px">{t("betting.bet_time")}</TableHeader>
                  <TableHeader $width="100px">{t("betting.win_time")}</TableHeader>
                  <TableHeader>{t("betting.user_betting")}</TableHeader>
                  <TableHeader>{t("betting.game_result")}</TableHeader>
                  <TableHeader $width="170px">{t("betting.price")}</TableHeader>
                  <TableHeader $width="120px">{t("common.manage")}</TableHeader>
                </tr>
              </thead>
              <tbody>
                {useTG.data &&
                  useTG.data.map((item, index) => {
                    const data = JSON.parse(item?.result_json_data);

                    const player = data?.external?.detail?.data?.result?.player;
                    const playerScore = player?.score;
                    const playerCard = player?.cards;
                    if (playerCard && playerCard.length > 2) {
                      const lastIndex = playerCard.length - 1;
                      const lastItem = playerCard[lastIndex];

                      // 맨 앞으로 이동시키기
                      playerCard.splice(lastIndex, 1); // 마지막 요소 제거
                      playerCard.unshift(lastItem);
                    }

                    const banker = data?.external?.detail?.data?.result?.banker;
                    const bankerScore = banker?.score;
                    const bankerCard = banker?.cards;

                    return data ? (
                      <TableRow key={index} className="table-content">
                        <TableDeps $tAlign="left">{item.refer_id}</TableDeps>
                        <TableDeps $tAlign="left">{item.code}</TableDeps>
                        <TableDeps $tAlign="left">
                          <AgentNameTab no={item.agent_no} code={item.code} name={item.name} type_name={item.type_name} />
                        </TableDeps>

                        <TableDeps $tAlign="left">
                          <Row $gap="6px" $align="center">
                            <UserState2Hover state2List={item.state2_arr} />
                            <UserNameTab no={item.no} nickname={item.nickname} refresh={getTableItem} />
                          </Row>
                        </TableDeps>

                        <TableDeps $line="22px" $tAlign="left" $width="100px" $white="wrap" $pad="10px">
                          <Row $gap="4px">
                            <NormalStatus name={item.vendor} />
                            <WarnStatus name={item.game_type} />
                            <SuccessStatus name={betEnum.bet_status[item.bet_status]?.label || ""} />
                          </Row>
                          <PadBox>
                            <TextBox $line="var(--l-caption)" $font="var(--f-captionB)" $size="var(--s-caption)">
                              {item.title}
                            </TextBox>
                          </PadBox>

                          <TextBox $line="var(--l-caption)" $size="var(--s-caption)">
                            {item.round}
                          </TextBox>
                        </TableDeps>
                        <TableDeps>
                          {item?.bet_time ? (
                            <Column>
                              <TextBox $size="var(--s-caption)">{dateFormat(item?.bet_time)}</TextBox>
                              <TextBox $size="var(--s-caption)">{timeFormat(item?.bet_time)}</TextBox>
                            </Column>
                          ) : (
                            "-"
                          )}
                        </TableDeps>
                        <TableDeps>
                          {item?.bet_time ? (
                            <Column>
                              <TextBox $size="var(--s-caption)">{dateFormat(item?.win_time)}</TextBox>
                              <TextBox $size="var(--s-caption)">{timeFormat(item?.win_time)}</TextBox>
                            </Column>
                          ) : (
                            "-"
                          )}
                        </TableDeps>

                        <TableDeps $pad="0">
                          {data?.external?.detail?.data?.participants?.[0]?.bets?.length > 0 ? (
                            <BettingTable>
                              <thead>
                                <tr>
                                  <BettingTableHeader>{t("betting.pick")}</BettingTableHeader>
                                  <BettingTableHeader>{t("betting.bet_amount")}</BettingTableHeader>
                                  <BettingTableHeader>{t("betting.win_amount")}</BettingTableHeader>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.external?.detail?.data?.participants?.[0]?.bets.map((item, index) => (
                                  <tr key={index}>
                                    <BettingTableCell>{item.code}</BettingTableCell>
                                    <BettingTableCell>{(item.stake || 0).toLocaleString()}</BettingTableCell>
                                    <BettingTableCell>{(item.payout || 0).toLocaleString()}</BettingTableCell>
                                  </tr>
                                ))}
                              </tbody>
                            </BettingTable>
                          ) : (
                            "-"
                          )}
                        </TableDeps>
                        <TableDeps>
                          {item.game_type === "baccarat" && playerCard && bankerCard ? (
                            <Row>
                              <BettingCon
                                className={bankerScore === playerScore ? "tie" : bankerScore < playerScore ? "player-win" : ""}
                              >
                                <Between $pad="0 0 8px">
                                  <TextBox>Player</TextBox>
                                  <Row $gap="4px">
                                    <TextBox>Score</TextBox>
                                    <TextBox $font="var(--f-subB)">{player?.score || 0}</TextBox>
                                  </Row>
                                </Between>
                                <PokerCon>
                                  {playerCard.map((item, index) => {
                                    const shape = item.charAt(item.length - 1);
                                    return (
                                      <Poker
                                        key={index}
                                        className={index === 0 && playerCard.length === 3 ? `ho ${shape}` : `ve ${shape}`}
                                      >
                                        <TextBox as="p" $size="var(--s-caption)" $font="var(--f-subB)" $line="14px">
                                          {item.slice(0, -1)}
                                        </TextBox>
                                        <PokerImg src={`/assets/svg/trump/${shape}.svg`} />
                                      </Poker>
                                    );
                                  })}
                                </PokerCon>
                              </BettingCon>

                              <BettingCon
                                className={bankerScore === playerScore ? "tie" : bankerScore > playerScore ? "banker-win" : ""}
                              >
                                <Between $pad="0 0 8px">
                                  <TextBox>Banker</TextBox>
                                  <Row $gap="4px">
                                    <TextBox>Score</TextBox>
                                    <TextBox $font="var(--f-subB)">{banker?.score || 0}</TextBox>
                                  </Row>
                                </Between>
                                <PokerCon>
                                  {bankerCard.map((item, index) => {
                                    const shape = item.charAt(item.length - 1);
                                    return (
                                      <Poker key={index} className={index !== 2 ? `ve ${shape}` : `ho ${shape}`}>
                                        <TextBox as="p" $size="var(--s-caption)" $font="var(--f-subB)" $line="14px">
                                          {item.slice(0, -1)}
                                        </TextBox>
                                        <PokerImg src={`/assets/svg/trump/${shape}.svg`} />
                                      </Poker>
                                    );
                                  })}
                                </PokerCon>
                              </BettingCon>
                            </Row>
                          ) : (
                            "-"
                          )}
                        </TableDeps>
                        <TableDeps $pad="0">
                          <div>
                            <BettingTable>
                              <tbody>
                                <tr>
                                  <BettingTableHeader>{t("betting.bet_amount")}</BettingTableHeader>
                                  <BettingTableCell>{`₩ ${(item?.bet_amount || 0).toLocaleString()}`}</BettingTableCell>
                                </tr>
                                <tr>
                                  <BettingTableHeader>{t("betting.win_amount")}</BettingTableHeader>
                                  <BettingTableCell>{`₩ ${(item?.win_amount || 0).toLocaleString()}`}</BettingTableCell>
                                </tr>
                                <tr>
                                  <BettingTableHeader>{t("betting.user_bet_win_amount")}</BettingTableHeader>
                                  <BettingTableCell>{`₩ ${(
                                    (item?.bet_amount || 0) + (item?.win_amount || 0)
                                  ).toLocaleString()}`}</BettingTableCell>
                                </tr>
                              </tbody>
                            </BettingTable>
                          </div>
                        </TableDeps>
                        <TableDeps>
                          <Row $width="100%" $jus="center" $gap="6px">
                            <ModifyBtn name={t("betting.detail")} eventProps={() => showAgentInfoPopup(item.transaction_id)} />
                            <BlockBtn name={t("common.qna")} />
                          </Row>
                        </TableDeps>
                      </TableRow>
                    ) : null;
                  })}
              </tbody>
            </Table>
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}

        <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%">
          <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
            {useTG && useTG.data?.length !== 0 && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
          </PositionBox>

          <Row $gap="8px">
            <SelectForm
              name="countPerPage"
              placeholder={useFiveCountPerPage[0].label}
              {...useTG}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useFiveCountPerPage}
              formValue={useFiveCountPerPage.value}
              top="top"
            />
          </Row>
        </Row>
      </Column>
    </>
  );
};

export default BettingList;
