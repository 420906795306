import React from "react";
import { GoogleIcon, KakaoIcon, LineIcon, SteamIcon, TelegramIcon, WhatsAppIcon } from "assets/components/IconComponent";
import styled from "styled-components";
import { t } from "i18next";
import { useAlert } from "contexts/useWindow";

const Oauth = styled.div`
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--c-white);
  border: 1px solid var(--c-gray-300);
  &.none_event {
    opacity: 0.4;
    cursor: default;
  }
`;

function OauthLogin() {
  localStorage.removeItem("auth-admin");

  const serverUrl = `${process.env.REACT_APP_BASE_URL}`;

  const currentUrl = new URL(window.location.href);
  const webUrl = `${currentUrl.protocol}//${currentUrl.host}`;

  const { openAlert } = useAlert();

  const preparingAlert = (code) => {
    openAlert({
      title: t(`common.preparing`),
      message: t('common.preparing_operation'),
      iconURL: false,
      mainBtn: t("common.check"),
    });
  };

  const preparing = () => {
    preparingAlert();
  }

  return (
    <>
      <Oauth as="a" href={`${serverUrl}/v1/auth/login/google?redirect_uri=${webUrl}/oauth/redirect`}>
        <GoogleIcon width="22" height="22" />
      </Oauth>
      <Oauth className="none_event" onClick={preparing}>
        <KakaoIcon width="22" height="22" />
      </Oauth>
      <Oauth className="none_event" onClick={preparing}>
        <TelegramIcon width="22" height="22" />
      </Oauth>
      <Oauth className="none_event" onClick={preparing}>
        <WhatsAppIcon width="22" height="22" />
      </Oauth>
      <Oauth className="none_event" onClick={preparing}>
        <SteamIcon width="22" height="22" />
      </Oauth>
      <Oauth className="none_event" onClick={preparing}>
        <LineIcon width="22" height="22" />
      </Oauth>
    </>
  );
}

export default OauthLogin;
