import { useState } from "react";
import { TooltipContainer, TooltipText } from "style/useLayoutStyles";

const Tooltip = ({ text, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <TooltipContainer
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      <TooltipText isVisible={isVisible}>{text}</TooltipText>
    </TooltipContainer>
  );
};

export default Tooltip;
