import api from "interceptor/api";
import Pagination from "utils/Pagination";

import { t } from "i18next";
import { usePopup } from "contexts/useWindow";
import useFormData from "common/useFormData";
import { useCountPerPage } from "constants/select/useValue";
import { useCommonState } from "contexts/useCommonState";
import useStateData from "common/useStateData";
import useTableData from "common/useTableData";
import ReferCompSetting from "components/gamemanagement/comp/ReferCompSetting";
import { InputForm, SelectForm } from "components/common/CommonForm";
import NoDataTable from "components/common/NoDataTable";
import { isEmpty } from "lodash";
import { Column, FilterBox, Row } from "style/custom/useCustomLayout";
import { FilterTitle } from "style/custom/useCustomText";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { PositionBox } from "style/useLayoutStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { TextBox } from "style/useTextStyles";
import { ModifyBtn } from "components/common/TableStatus";
import { getFormatDatetime } from "utils/date/DateUtil";

export default function UserCompAdd({ userNo }) {
  const useStated = useStateData();
  const { setLoading } = useCommonState();
  const { openPopup } = usePopup();

  const compSetting = (gameNo) => {
    openPopup({
      title: t("gameManagement.add_user_comp"),
      content: <ReferCompSetting gameNo={gameNo} userNo={userNo} type="A"/>,
      onClose: getTableItem,
    });
  };

  const getTableItem = () => {
    setLoading(true);

    const apiUrl = `/v1/game-management/game-comp-refer/${userNo}`;
    api.get(apiUrl, {
      params: {
        ...useSearch.searchValue,
        ...useTG.extendData.pagingData,
      },
    })
    .then((res) => {
      useTG.setData(res.data.content);
      if (res.data.pageHelperDto) {
        useTG.setPagingInfo(res.data.pageHelperDto);
      }
      setLoading(false);
      useTG.setError("");
    })
    .catch((err) => {
      console.error(err);
      useTG.setError(true);
      setLoading(false);
      useTG.setData([]);
      if (err.response?.status === 400) {
        useStated.setErrorData(err.response.data.errors, true);
      }
    });
  };

  const useTG = useTableData(true);

    const searchStateEnum = {
    title: {
      label: t("gameManagement.game_name"),
      value: "title",
      key: 0,
    },
    vendor: {
      label: t("gameManagement.game_vendor"),
      value: "vendor",
      key: 1,
    },
  };

  const activeStateEnum = {
    all: {
      label: t("common.all"),
      value: "",
      key: 0,
    },
    active: {
      label: t("common.use_y"),
      value: "Y",
      key: 1,
    },
    inactive: {
      label: t("common.use_n"),
      value: "N",
      key: 2,
    },
  };
  
  const useSearch = useFormData(
    {
      keyword: "",
      search_state: searchStateEnum.title.value,
      search_use_yn: activeStateEnum.all.value,
    },
    getTableItem,
    useTG
  );

  return (
    <Column $width="1200px">
      <FilterBox $pad="12px">
        <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
          <Column $gap="4px">
            <FilterTitle>{t("gameManagement.search_filter")}</FilterTitle>
            <Row $gap="4px">
              <SelectForm
                name="search_state"
                placeholder={t("common.all_state")}
                options={searchStateEnum}
                {...useSearch}
                {...useStated}
              />
              <InputForm maxWidth="200px" name="keyword" placeholder={t("common.enter_value")} {...useSearch} {...useStated} />
            </Row>
          </Column>
          <Column $gap="4px">
            <FilterTitle>{t("common.state")}</FilterTitle>
            <Row $gap="4px">
              <SelectForm
                width="80px"
                name="search_use_yn"
                placeholder={t("common.all_state")}
                options={activeStateEnum}
                {...useSearch}
                {...useStated}
              />
            </Row>
          </Column>
        </Row>
        <Row $gap="8px">
          <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
            {t("filter.search")}
          </MainBtn>
          <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
            {t("filter.refresh")}
          </WhiteBtn>
        </Row>
      </FilterBox>
      <Row className="total-count" $gap="10px" $pad="10px 0" $jus="space-between">
        <Row $gap="10px">
          <TextBox $font="var(--f-subB)">{t("menuCategory.total_game_count")}</TextBox>
          <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
            {useTG.error ? (
              <span>error</span>
            ) : (
              <>{useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords?.toLocaleString() : 0}</>
            )}
          </TextBox>
          <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
        </Row>
        <SelectForm
          {...useTG}
          name="countPerPage"
          placeholder={useCountPerPage[0].label}
          size={"var(--s-table)"}
          line={"var(--l-table)"}
          options={useCountPerPage}
          formValue={useTG.extendData}
        />
      </Row>
      {useTG.error ? (
        <span>error</span>
      ) : (
        <>
          <Table className="table-box">
            <thead>
              <tr className="table-title">
                <TableHeader $width="60px"><TextBox>{t("common.no")}</TextBox></TableHeader>
                <TableHeader>{t("gameManagement.game_name")}</TableHeader>
                <TableHeader>{t("gameManagement.game_vendor")}</TableHeader>
                <TableHeader>{t("gameManagement.rolling")}</TableHeader>
                <TableHeader>{t("gameManagement.losing")}</TableHeader>
                <TableHeader $width="130px">{t("gameManagement.registered_date")}</TableHeader>
                <TableHeader $width="100px">{t("common.state")}</TableHeader>
                <TableHeader $width="150px">{t("gameManagement.total_bet_amount")}</TableHeader>
                <TableHeader $width="130px">{t("gameManagement.total_win_amount")}</TableHeader>
                <TableHeader $width="130px">{t("gameManagement.total_bet_win_amount")}</TableHeader>
                <TableHeader $width="130px">{t("common.manage")}</TableHeader>
              </tr>
            </thead>
            {useTG && useTG.data?.length > 0 && (
              <tbody>
                {useTG.data &&
                  useTG.data.map((item, index) => {
                    const number = useTG.pagingInfo.offset - index;
                    return (
                      <TableRow key={number} className="table-content">
                        <TableDeps $width="60px">{number || "-"}</TableDeps>
                        <TableDeps $tAlign="left">{item.title ?? "-"}</TableDeps>
                        <TableDeps $width="230px" $tAlign="left">
                          {item.vendor ?? "-"}
                        </TableDeps>
                        <TableDeps $width="90px" $tAlign="right">
                          {item.rolling_rate ?? "-"}
                        </TableDeps>
                        <TableDeps $width="90px" $tAlign="right">
                          {item.losing_rate ?? "-"}
                        </TableDeps>
                        <TableDeps $width="150px">{getFormatDatetime(item.create_at)}</TableDeps>
                        <TableDeps $width="100px">
                          <TextBox className={item.use_yn === "Y" ? "complete" : "warn"}>
                            {item.use_yn === "Y" ? t("gameManagement.active_games") : t("gameManagement.inactive_games")}
                          </TextBox>
                        </TableDeps>
                        <TableDeps $width="130px" $tAlign="right">
                          {item.bet_money?.toLocaleString() ?? "0"}
                        </TableDeps>
                        <TableDeps $width="130px" $tAlign="right">
                          {item.win_money?.toLocaleString() ?? "0"}
                        </TableDeps>
                        <TableDeps $width="130px" $tAlign="right">
                          {((item.bet_money ?? 0) - (item.win_money ?? 0)).toLocaleString()}
                        </TableDeps>
                        <TableDeps $width="90px">
                          <Row $align="center">
                            <ModifyBtn
                              $size="var(--s-table)"
                              $line="var(--l-table)"
                              name={t("gameManagement.add_user_comp")}
                              eventProps={() => compSetting(item?.no)}
                            ></ModifyBtn>
                          </Row>
                        </TableDeps>
                      </TableRow>
                    );
                  })}
              </tbody>
            )}
          </Table>
          {useTG && useTG.data?.length === 0 && <NoDataTable />}
        </>
      )}
      <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="space-between" $width="100%">
        <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
          {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
        </PositionBox>
        <Row />
        <Row $align="center" $gap="4px">
          <SelectForm
            {...useTG}
            name="countPerPage"
            placeholder={useCountPerPage[0].label}
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useCountPerPage}
            formValue={useTG.extendData}
          />
        </Row>
      </Row>
    </Column>
  );
}