import styled from "styled-components";
import { FlexBox } from "style/useLayoutStyles";

const Column = styled(FlexBox)`
  flex-direction: column;
  overflow: ${(props) => props.$overflow || null};
`;
const Row = styled(FlexBox)``;
const Between = styled(FlexBox)`
  justify-content: space-between;
  align-items: center;
`;
const AreaHeader = styled(FlexBox)`
  gap: 6px;
  padding: 0 0 16px 0px;
  align-items: center;
`;

const FilterBox = styled(FlexBox).attrs({ className: "filter-box" })`
  gap: 16px;
  padding: 14px 24px;
  background-color: var(--c-gray-100);
  align-items: center;
  justify-content: space-between;
`;

const SubTitleBox = styled(FlexBox).attrs({ className: "subtitle-box" })`
  padding: 10px 24px;
  background-color: var(--c-tableHead);
  align-items: center;
  justify-content: space-between;
  height: ${(props) => props.height || "44px"};
`;

const OptionBox = styled(FlexBox)`
  gap: 16px;
  padding: 10px 20px;
  min-height: ${(props) => props.height || "30px"};
  border-bottom: 1px solid var(--c-gray-300);
`;

const SpaceBox = styled(FlexBox)`
  gap: 16px;
  padding: 4px 20px;
  min-height: ${(props) => props.height || "10px"};
  border-bottom: 1px solid var(--c-gray-300);
`;

const NoSearchTable = styled(FlexBox)`
  width: 100%;
  padding: 23px 0;

  border-bottom: 1px solid var(--c-gray-300);
  justify-content: center;
`;

const FormBox = styled(FlexBox)`
  gap: 8px;
`;

const RowLankAgent = styled(FlexBox)`
  align-items: center;
  gap: 6px;
`;

export { Row, Column, Between, FilterBox, AreaHeader, OptionBox, SpaceBox, SubTitleBox, NoSearchTable, FormBox, RowLankAgent };
